import React from 'react';
import { useSelector } from 'react-redux';

export const ProfileSetting = () => {
  const { email, firstName, lastName } = useSelector(state => state.authentication.userData);

  return (
    <div className="settingContent material">
      <h5>Dein Profil</h5>
      <div className="separator" />

      <div className="parallelBox">
        <div className="settingName">
          <span>Vorname:</span>
        </div>
        <div className="settingValue">
          <span>{firstName}</span>
        </div>
      </div>

      <div className="parallelBox">
        <div className="settingName">
          <span>Nachname:</span>
        </div>
        <div className="settingValue">
          <span>{lastName}</span>
        </div>
      </div>

      <div className="parallelBox">
        <div className="settingName">
          <span>E-Mail:</span>
        </div>
        <div className="settingValue">
          <span>{email}</span>
        </div>
      </div>
    </div>
  );
};
