import React from "react";
import { useDrop } from "react-dnd";
import { ItemTypes } from "../../utils/types";
import { parseFilterFromString } from "../../utils/parsing";

export function DroppableList({ listId, children, queryString }) {
  let targetTags = queryString
    ? parseFilterFromString(queryString).tagsIncluded
    : null;

  const [{ isOver, item }, drop] = useDrop({
    accept: ItemTypes.ELEMENT,
    drop: item => ({ elemId: item.elemData.id, targetTags, targetListId: listId }),
    collect: monitor => ({
      isOver: !!monitor.isOver(),
      item: monitor.getItem()
    })
  });

  const childrenWithProps = React.Children.map(children, child =>
    React.cloneElement(child, {
      isOver: isOver,
      dragItemCreatedAt: item ? item.dragItemCreatedAt : null,
      dragHeightRef: item ? item.dragHeightRef : null,
      dragElemListId: item ? item.dragElemListId : null,
      originTags: item ? item.originTags : null
    })
  );

  return <div ref={drop}>{childrenWithProps}</div>;
}
