import React, { Component } from "react";
import "./HelpDialogs.scss";

export class SearchHelp extends Component {
  render() {
    return (
      <div
        className={
          this.props.helpShown === true ? "helpDialog shown" : "helpDialog"
        }
      >
        <h4>Hilfestellung für Suchanfragen</h4>
        <p>
          <b>Titel: </b>Um Elemente zu suchen, welche eine bestimmte
          Zeichenfolge im Titel enthalten, kann diese einfach im Suchfeld
          eingegeben werden.
        </p>
        <p>
          <b>Tags: </b>Um nach Elementen zu filtern, die einen bestimmten Tag
          besitzen kann dieser mit einem bevorstehenden <span>#</span> im
          Suchfeld angegeben werden. Bsp.: <span>#ideen</span>{" "}
          <span>#todo</span>
        </p>
        <p>
          <b>Ausgenommene Tags: </b>Willst du nach Elementen filtern, die einen
          bestimmten Tag <u>nicht</u> enthalten, kannst du dies mit einem 
          vorangestellten Ausrufezeichen <span>!</span> tun. Bsp.: <span>!todo</span>{" "}<span>!einkaufen</span>
        </p>
        {/* <p>
          <b>Datum: </b>Um nach Elementen zu filtern, die zu einem gewissen
          Datum oder innerhalb einer Zeitspanne erstellt wurden, kann folgendes
          Format verwendet werden: <span>@21.12.2012</span>{" "}
          <span>@01.05.2018-01.06.2018</span>
        </p> */}
      </div>
    );
  }
}
