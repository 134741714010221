const initialState = {
  queryString: null,
  dateStart: null,
  dateEnd: null,
  tagsIncluded: [],
  tagsExcluded: [],
  triggerRequest: true
};

export const filter = (state = initialState, action) => {
  switch (action.type) {
    case "RESET_ALL_FILTERS": {
      return Object.assign({}, { ...initialState, triggerRequest: action.triggerRequest });
    }
    case "UPDATE_FILTER": {
      let { queryString, tagsIncluded, tagsExcluded } = action.filter;
      return {
        ...state,
        queryString: queryString,
        tagsIncluded: tagsIncluded ? tagsIncluded : state.tagsIncluded,
        tagsExcluded: tagsExcluded ? tagsExcluded : state.tagsExcluded,
        triggerRequest: true
      };
    }
    default:
      return state;
  }
};
