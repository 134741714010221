import { convertDataFormat } from "../utils/conversions";

const initialState = {
  activeBoardId: null,
  boards: null,
  addBoardError: null,
  updateBoardError: null,
  addListError: null,
  updateListError: null,
  lists: null,
  elements: {}
};

export const board = (state = initialState, action) => {
  switch (action.type) {
    case "LOCAL_MOVE_BOARD_ELEMENT": {
      const { [action.elementId]: movedElement, ...originListItems } = state.elements[action.originListId];
      const newElem = Object.assign({}, movedElement);
      newElem.tags.data = action.tempNewTags;
      return {
        ...state,
        elements: {
          ...state.elements,
          [action.originListId]: originListItems,
          [action.targetListId]: {
            ...state.elements[action.targetListId],
            [action.elementId]: newElem
          }
        }
      }
    }
    case "RESET_BOARD_LIST_ELEMENTS": {
      let { [action.listId]: _, ...rest } = state.elements;
      return {
        ...state,
        elements: rest
      }
    }
    case "UPDATE_BOARD_LIST_SUCCEEDED": {
      return {
        ...state,
        lists: {
          ...state.lists,
          [action.list.listId]: {
            id: action.list.listId,
            ...action.list
          }
        }
      }
    }
    case "DELETE_BOARD_LIST_SUCCEEDED": {
      const { [action.listId]: _, ...listRest } = state.lists;
      const { [action.listId]: __, ...elemRest } = state.elements;
      return {
        ...state,
        lists: listRest,
        elements: elemRest
      }
    }
    case "UPDATE_BOARD_SUCCEEDED": {
      return {
        ...state,
        boards: {
          ...state.boards,
          [action.boardData.boardId]: {
            id: action.boardData.boardId,
            ...action.boardData
          }
        }
      }
    }
    case "DELETE_BOARD_SUCCEEDED": {
      const { [action.boardId]: _, ...rest } = state.boards;
      return {
        ...state,
        boards: rest,
        lists: null
      }
    }
    case "SOCKET_INSERT_BOARD_LINK_SUCCEEDED": {
      let newElements = Object.assign({}, state.elements);
      Object.keys(newElements).forEach(l => {
        if (newElements[l][action.link.elementId]) {
          newElements[l][action.link.elementId] = {
            ...newElements[l][action.link.elementId],
            links: {
              data: {
                ...newElements[l][action.link.elementId].links.data,
                [action.link.linkId]: {
                  id: action.link.linkId,
                  ...action.link
                }
              },
              indexArray: newElements[l][action.link.elementId].links.indexArray
            }
          }
        }
      });

      return {
        ...state,
        elements: newElements
      }
    }
    case "ADD_BOARD_ELEMENT_SUCCEEDED": {
      let newElements = Object.assign({}, state.elements);
      action.listIds.forEach(l => {
        newElements[l][action.element.id] = {
          ...action.element
        }
      });

      return {
        ...state,
        elements: newElements
      }
    }
    case "UPDATE_BOARD_ELEMENT_SUCCEEDED": {
      let newElements = Object.assign({}, state.elements);
      action.listIds.forEach(l => {
        newElements[l][action.element.id] = {
          ...newElements[l][action.element.id],
          ...action.element
        }
      });

      return {
        ...state,
        elements: newElements
      }
    }
    case "DELETE_BOARD_ELEMENT_SUCCEEDED": {
      let newElements = Object.assign({}, state.elements);
      Object.keys(state.elements).forEach(l => {
        // Only delete from certain lists
        if (action.listIds && !action.listIds.includes(Number.parseInt(l))) return;

        if (state.elements[l][action.elementId]) {
          const { [action.elementId]: _, ...rest } = state.elements[l];
          newElements[l] = rest;
        }
      });

      return {
        ...state,
        elements: newElements
      }
    }
    case "DELETE_MULTIPLE_BOARD_ELEMENTS_SUCCEEDED": {
      let newElements = Object.assign({}, state.elements);
      action.elementIds.forEach(id => {
        Object.keys(state.elements).forEach(l => {
          // Only delete from certain lists
          if (action.listIds && !action.listIds.includes(Number.parseInt(l))) return;
  
          if (state.elements[l][id]) {
            const { [id]: _, ...rest } = state.elements[l];
            newElements[l] = rest;
          }
        });
      });

      return {
        ...state,
        elements: newElements
      }
    }
    case "RETRIEVE_LIST_ELEMENTS_SUCCEEDED": {
      let convElemData = convertDataFormat(action.elements);
      return {
        ...state,
        elements: {
          ...state.elements,
          [action.listId]: {
            ...state.elements[action.listId],
            ...convElemData.data
          }
        }
      }
    }
    case "ADD_BOARD_LIST_DIRECTLY": {
      let { list } = action;
      if (list.boardId === state.activeBoardId) {
        return {
          ...state,
          lists: {
            ...state.lists,
            [list.id]: {
              ...list
            }
          }
        }
      } else {
        return { ...state }
      }
    }
    case "RETRIEVE_BOARD_DETAILS_SUCCEEDED": {
      let convList = convertDataFormat(action.lists);
      return {
        ...state,
        lists: convList.data
      }
    }
    case "RETRIEVE_BOARD_DETAILS_FAILED": {
      return {
        ...state
      }
    }
    case "SET_ACTIVE_BOARD": {
      return {
        ...state,
        activeBoardId: action.boardId,
        lists: null,
        elements: {}
      };
    }
    case "ADD_BOARD_DIRECTLY": {
      let boardKey = Object.keys(action.boardData)[0];
      let firstBoardId = action.boardData[boardKey].id;
      return {
        ...state,
        boards: {
          ...state.boards,
          ...action.boardData
        },
        activeBoardId: firstBoardId
      };
    }
    case "RESET_ADD_BOARD_ERROR": {
      return {
        ...state,
        addBoardError: null
      };
    }
    case "ADD_BOARD_FAILED": {
      return {
        ...state,
        addBoardError: action.errorMsg
      };
    }
    case "RESET_UPDATE_BOARD_ERROR": {
      return {
        ...state,
        updateBoardError: null
      }
    }
    case "UPDATE_BOARD_FAILED": {
      return {
        ...state,
        updateBoardError: action.errorMsg
      }
    }
    case "RESET_ADD_BOARD_LIST_ERROR": {
      return {
        ...state,
        addListError: null
      }
    }
    case "ADD_BOARD_LIST_FAILED": {
      return {
        ...state,
        addListError: action.errorMsg
      }
    }
    case "RESET_UPDATE_BOARD_LIST_ERROR": {
      return {
        ...state,
        updateListError: null
      }
    }
    case "UPDATE_BOARD_LIST_FAILED": {
      return {
        ...state,
        updateListError: action.errorMsg
      }
    }
    case "RETRIEVE_BOARDS_SUCCEEDED": {
      let convBoardData = convertDataFormat(action.boards);
      return {
        ...state,
        boards: convBoardData.data
      };
    }
    case "RETRIEVE_BOARDS_FAILED": {
      return {
        ...state
      };
    }
    default:
      return state;
  }
};
