import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { closeModal } from './../../actions/navigation';
import { modalCodes } from '../../utils/enums';
import { useEffect } from 'react';
import { useState } from 'react';

export const ElemContextMenuModal = () => {
  const openModal = useSelector(state => state.navigation.openModal);
  const modalData = useSelector(state => state.navigation.modalData);
  const dispatch = useDispatch();

  const [ dropdown, setDropdown ] = useState({ open: false, x: 0, y: 0, fromTop: true, options: [] });
  const shown = openModal === modalCodes.ELEM_CONTEXT_MENU_MODAL;
  useEffect(() => {
    if (shown && modalData) setDropdown(modalData);
    if (!shown) setDropdown({ ...dropdown, open: false });
  }, [ shown ]);

  const opts = dropdown.options.map(o => {
    return (
      <li key={o.name}>
        <span onClick={() => { 
          o.action();
          if (!o.disableClose) dispatch(closeModal());
        }}>{o.name}</span>
      </li>
    )
  })

  return (
    <div
      className={shown ? 'context-wrapper open' : 'context-wrapper'}
      onClick={e => {
        if (e.target === e.currentTarget) {
          dispatch(closeModal());
        }
      }}
      onContextMenu={e => {
        e.preventDefault();
        dispatch(closeModal());
      }}
    >
      <div
        className={dropdown.open ? 'dropdown open' : 'dropdown'}
      >
        <ul className={dropdown.fromTop ? '' : 'fromBottomRight'} style={{ top: dropdown.y, left: dropdown.x }}>
          {opts}
        </ul>
      </div>
    </div>
  );
};
