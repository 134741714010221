import React, { Component } from "react";
import { ProfileSetting } from "./Content/ProfileSetting";
import { WorkspaceSetting } from "./Content/WorkspaceSetting";
import PersonalizationSetting from "./Content/PersonalizationSetting";
import { SubscriptionSetting } from "./Content/SubscriptionSetting";
import { APISetting } from "./Content/APISetting";
import { connect } from "react-redux";

class SettingContainer extends Component {
  render() {
    switch (this.props.activeSettingMenu) {
      case 0: {
        return <ProfileSetting />;
      }
      case 1: {
          return <WorkspaceSetting />
      }
      case 2: {
          return <PersonalizationSetting />
      }
      case 3: {
          return <SubscriptionSetting />
      }
      case 4: {
          return <APISetting />
      }
      default: {
          return <div />
      }
    }
  }
}

const mapStateToProps = state => ({
  activeSettingMenu: state.navigation.activeSettingMenu
});

export default connect(mapStateToProps)(SettingContainer);
