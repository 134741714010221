import { put, takeEvery, call, select } from "redux-saga/effects";
import { genericHandleResult } from "./rootSaga";
import { retrieveQueryListRequest, addQueryRequest, editQueryRequest, removeQueryRequest } from "../api/query";
import { removeQuerySucceeded } from "../actions/query";
import { maxFreeQueries } from "../utils/limitations";

export function* watchAllQueryActions() {
  yield takeEvery("RETRIEVE_QUERIES_REQUESTED", beginRetrieveQueries);
  yield takeEvery("ADD_QUERY_REQUESTED", beginAddQuery);
  yield takeEvery("EDIT_QUERY_REQUESTED", beginEditQuery);
  yield takeEvery("REMOVE_QUERY_REQUESTED", beginRemoveQuery);
  yield takeEvery("REMOVE_QUERY_DIRECTLY", beginRemoveQueryDirectly);
}

export function* beginRetrieveQueries(action) {
  try {
    const result = yield call(retrieveQueryListRequest, action.metadata);

    yield genericHandleResult(
      result,
      {
        type: "RETRIEVE_QUERIES_SUCCEEDED",
        queryResult: result.queryResult,
        append: action.append
      },
      {
        type: "RETRIEVE_QUERIES_FAILED",
        errorMsg: result.errorMsg
      }
    );
  } catch (e) {
    yield put({
      type: "RETRIEVE_QUERIES_FAILED",
      errorMsg: e.message
    });
  }
}

export function* beginAddQuery(action) {
  try {
    const queries = yield select(state => state.query.queries);
    const isPaidUser = yield select(state => state.authentication.userData.isPaidUser);
    const limitReached = queries && !isPaidUser && queries.length >= maxFreeQueries;

    if (limitReached) {
      yield put({
        type: "ADD_QUERY_FAILED",
        errorMsg: "Du hast die maximale Anzahl an Suchabfragen für Tagstack Basic erreicht."
      });
    } else {
      const result = yield call(
        addQueryRequest,
        action.metadata,
        action.queryData
      );
  
      yield genericHandleResult(
        result,
        undefined,
        {
          type: "ADD_QUERY_FAILED",
          errorMsg: result.errorMsg
        },
        [{ type: "CLOSE_MODAL" }]
      );
    }
  } catch (e) {
    yield put({
      type: "ADD_QUERY_FAILED",
      errorMsg: e.message
    });
  }
}

export function* beginEditQuery(action) {
  try {
    const result = yield call(
      editQueryRequest,
      action.metadata,
      action.queryData
    );

    yield genericHandleResult(
      result,
      undefined,
      {
        type: "EDIT_QUERY_FAILED",
        errorMsg: result.errorMsg
      },
      [{ type: "CLOSE_MODAL" }]
    );
  } catch (e) {
    yield put({
      type: "EDIT_QUERY_FAILED",
      errorMsg: e.message
    });
  }
}

export function* beginRemoveQuery(action) {
  try {
    // Sometimes socketio is faster than the response
    // -> already navigate away from query in case it is selected
    action.history.push('/timeline');

    const result = yield call(
      removeQueryRequest,
      action.metadata,
      action.queryId
    );

    yield genericHandleResult(
      result,
      undefined,
      {
        type: "EDIT_QUERY_FAILED",
        errorMsg: result.errorMsg
      },
      [{ type: "CLOSE_MODAL" }]
    );
  } catch (e) {
    yield put({
      type: "EDIT_QUERY_FAILED",
      errorMsg: e.message
    });
  }
}

export function* beginRemoveQueryDirectly(action) {
  // Since there is no reference to the searchbar here
  // it's content cannot be reset, keep filter for now

  yield put(removeQuerySucceeded(action.queryId));
}