import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { MaterialIcons } from "react-web-vector-icons";
import { removeUploadedFile } from "../../actions/element";

export const UploadedFile = ({ id }) => {

  const uploadedFile = useSelector(state => state.element.uploadedFiles[id]);
  const dispatch = useDispatch();

  if (uploadedFile && uploadedFile.uploadFinished) {
    return (
      <div className="uploadedFile">
        <div className="fileIcon">
          <MaterialIcons name="insert-drive-file" size={24} />
        </div>
        <span>{uploadedFile.fileName}</span>
        <div className="removeFile" onClick={() => dispatch(removeUploadedFile(id))}>
          <MaterialIcons name="close" size={24} />
        </div>
      </div>
    );
  } else {
    // If file just was removed but is re-rendered since it is listening as well
    if (!uploadedFile) {
      return null;
    } else {
      return (
        <div className="uploadedFile">
          <div className="fileIcon">
            <div className="loadingSpinner" />
          </div>
          <span>Wird hochgeladen...</span>
          <div className="removeFile">
            <MaterialIcons name="close" size={24} />
          </div>
        </div>
      );
    }
  }
}