import { combineReducers } from 'redux';
import { navigation } from "./navigation";
import { filter } from "./filter";
import { element } from "./element";
import { authentication } from "./authentication";
import { query } from "./query";
import { preferences } from "./preferences"; 
import { connection } from "./connection";
import { notification } from "./notification";
import { board } from "./board";
// import storage from "redux-persist/lib/storage";

const appReducer = combineReducers({
    navigation: navigation,
    filter: filter,
    element: element,
    authentication: authentication,
    query: query,
    preferences: preferences,
    connection: connection,
    notification: notification,
    board: board
  })
  
export const rootReducer = (state, action) => {
    if (action.type === 'LOGOUT') {
        document.documentElement.setAttribute('theme', "light");
        // storage.removeItem('persist:authentication');
        state = undefined;
    }
    return appReducer(state, action)
}