import React, { useState, useEffect } from 'react';
import { MaterialIcons } from 'react-web-vector-icons';
import { useDispatch, useSelector } from 'react-redux';
import { addElementFailed, addFilesForUpload, uploadFileRequested } from '../../actions/element';
import { setFileDragOver } from '../../actions/navigation';
import { maxFreeTotalUploaded, maxFreeTotalUploadedString, maxFreeUploadSize, maxFreeUploadSizeString, maxPaidTotalUploaded, maxPaidTotalUploadedString, maxPaidUploadSize, maxPaidUploadSizeString, upgradeLink } from '../../utils/limitations';

export const DropZone = () => {

  const [dragCounter, setDragCounter] = useState(0); // Drag leave is called on every hover over child elements and this is the preferred solution to prevent flickering
  const fileDragOver = useSelector(state => state.navigation.fileDragOver);
  const token = useSelector(state => state.authentication.userData.token);
  const isPaidUser = useSelector(state => state.authentication.userData.isPaidUser);
  const storageUsed = useSelector(state => state.authentication.userData.storageUsed);
  const dispatch = useDispatch();

  useEffect(() => {
    if (dragCounter === 0) dispatch(setFileDragOver(false));
  }, [dragCounter]);

  if (!fileDragOver) return null;

  const handleFileUpload = e => {
    const files = Array.from(e.dataTransfer.files);
    const convFiles = {};

    // Reformat files
    files.forEach((file, i) => {
      // Check if storage limit reached before upload
      const uploadLimit = isPaidUser ? maxPaidTotalUploaded : maxFreeTotalUploaded;
      if (storageUsed >= uploadLimit) {
        if (isPaidUser) {
          dispatch(addElementFailed({ message: `Die maximale Auslastung deines verfügbaren Speicherplatzes von ${maxPaidTotalUploadedString} wurde erreicht.`}));
          return;
        } else {
          dispatch(addElementFailed({ 
            message: `Die maximale Auslastung deines verfügbaren Speicherplatzes von ${maxFreeTotalUploadedString} wurde erreicht.`,
            actionMessage: `Upgrade auf Tagstack Plus um deinen verfügbaren Speicherplatz auf ${maxPaidTotalUploadedString} zu erweitern.`,
            actionLink: upgradeLink
          }));
          return;
        }
      }

      // Max filesize check
      if (isPaidUser) {
        if (file.size > maxPaidUploadSize) {
          dispatch(addElementFailed({ message: `Die ausgewählte Datei überschreitet die maximale Dateigröße von ${maxPaidUploadSizeString}.`}));
          return;
        }
      } else {
        if (file.size > maxFreeUploadSize) {
          dispatch(addElementFailed({ 
            message: `Die ausgewählte Datei überschreitet die maximale Dateigröße von ${maxFreeUploadSizeString}.`,
            actionMessage: `Upgrade auf Tagstack Plus um Dateien bis zu ${maxPaidUploadSizeString} hochladen zu können.`,
            actionLink: upgradeLink
          }));
          return;
        }
      }
      
      // Max filesize check
      if (file.size > 10485760) {
        // 10 MiB
        dispatch(addElementFailed({ message: 'Die ausgewählte Datei überschreitet die maximale Dateigröße von 10MB.'}));
        return;
      }

      convFiles[i] = {
        fileName: file.name,
        fileType: file.type,
        fileSize: file.size,
        lastModified: file.lastModified,
        originalFile: file,
        assignedToElement: false
      };
    });

    // Add to state
    dispatch(addFilesForUpload(convFiles));

    // Begin uploading each
    Object.keys(convFiles).forEach(f => {
      dispatch(
        uploadFileRequested(
          {
            token,
            id: f
          },
          convFiles[f]
        )
      );
    });
  }

  return (
    <div
      className="dropZone"
      onDragOver={e => e.preventDefault()}
      onDrop={e => {
        e.preventDefault();
        dispatch(setFileDragOver(false));
      }}
      onDragEnter={() => setDragCounter(dragCounter + 1)}
      onDragLeave={() => setDragCounter(dragCounter - 1)}
    >
      <div className="container" onDrop={handleFileUpload}>
        <div className="inner">
          <MaterialIcons name="cloud-upload" size={80} />
          <h2>Dateien hier ablegen</h2>
          <p>Lege deine Dateien hier ab, um diese hochzuladen.</p>
        </div>
      </div>
    </div>
  );
};
