export const addQuery = (name, value) => ({
    type: "ADD_QUERY",
    name: name,
    value: value
});

export const scQueryUp = () => ({
    type: "SC_QUERY_UP"
});

export const scQueryDown = () => ({
    type: "SC_QUERY_DOWN"
});

export const retrieveQueriesRequested = metadata => ({
    type: "RETRIEVE_QUERIES_REQUESTED",
    metadata: metadata
});

export const addQueryRequested = (metadata, queryData) => ({
    type: "ADD_QUERY_REQUESTED",
    metadata: metadata,
    queryData: queryData
});

export const editQueryRequested = (metadata, queryData) => ({
    type: "EDIT_QUERY_REQUESTED",
    metadata: metadata,
    queryData: queryData
});

export const removeQueryRequested = (metadata, queryId, history) => ({
    type: "REMOVE_QUERY_REQUESTED",
    metadata: metadata,
    queryId: queryId,
    history
});

export const removeQueryDirectly = queryId => ({
    type: "REMOVE_QUERY_DIRECTLY",
    queryId: queryId
});

export const removeQuerySucceeded = queryId => ({
    type: "REMOVE_QUERY_SUCCEEDED",
    queryId: queryId
});

export const addQuerySucceeded = query => ({
    type: "ADD_QUERY_SUCCEEDED",
    query: query
});

export const editQuerySucceeded = query => ({
    type: "EDIT_QUERY_SUCCEEDED",
    query: query
});

export const resetAddQueryError = () => ({
    type: "RESET_ADD_QUERY_ERROR"
});

export const resetEditQueryError = () => ({
    type: "RESET_EDIT_QUERY_ERROR"
});


export const setQueryTree = queryTree => ({
    type: "SET_QUERY_TREE",
    queryTree
});