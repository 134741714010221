import { put, call, takeEvery } from "redux-saga/effects";
import { setInitialUserSettings } from "../actions/preferences";
import { setInitialOneTimeEvents, finishTour } from "../actions/navigation";
import { getUserInfoFailed } from "../actions/navigation";
import { getUserInfo } from "../api/authentication";
import { updateOneTimeEventRequest } from "../api/navigation";
import { showNotification } from "../actions/notification";
import { updateUserData } from "../actions/authentication";

export function* watchAllNavigationActions() {
  yield takeEvery("USER_INFO_REQUESTED", beginGetUserInfo);
  yield takeEvery("UPDATE_ONE_TIME_EVENT_REQUESTED", beginUpdateOneTimeEvent);
}

export function* beginGetUserInfo(action) {
  try {
    const result = yield call(getUserInfo, action.metadata);

    if (result.statusCode === 0) {
      // Update user-specific information
      yield put(updateUserData(result.userInfo.details));

      // Update settings
      yield put(setInitialUserSettings(result.userInfo.settings));
      
      // Update one time events and hide loading page
      yield put(setInitialOneTimeEvents(result.userInfo.oneTimeEvents));
    } else {
      yield put(getUserInfoFailed(result.errorMsg));
    }
  } catch (e) {
    yield put(getUserInfoFailed(e.message));
  }
}

export function* beginUpdateOneTimeEvent(action) {
  try {
    if (action.eventName === "finishedTutorial") {
      yield put(finishTour());
    }

    const result = yield call(updateOneTimeEventRequest, action.metadata, action.eventName);
    if (result.statusCode !== 0) {
      yield put(showNotification({ type: "error", title: "One-time-event update failed", desc: result.errorMsg }));
    }
  } catch (e) { 
    yield put(showNotification({ type: "error", title: "One-time-event update failed", desc: e.message }));
  }
}
