export const showNotification = notification => ({
    type: "SHOW_NOTIFICATION_REQUESTED",
    notification: notification
});

export const removeNotification = id => ({
    type: "REMOVE_NOTIFICATION",
    id: id
});

export const clearAllNotifications = () => ({
    type: "CLEAR_ALL_NOTIFICATIONS"
});