export const openModal = (modalId, modalData) => ({
    type: "OPEN_MODAL",
    modalId: modalId,
    modalData: modalData
});

export const closeModal = () => ({
    type: "CLOSE_MODAL"
});

export const toggleMobileMenu = () => ({
    type: "TOGGLE_MOBILE_MENU"
});

export const switchSettingMenu = id => ({
    type: "SWITCH_SETTING_MENU",
    id: id
});

export const updateOneTimeEventRequested = (metadata, eventName) => ({
    type: "UPDATE_ONE_TIME_EVENT_REQUESTED",
    metadata: metadata,
    eventName: eventName
});

export const finishTour = () => ({
    type: "FINISH_TOUR"
});

export const setInitialOneTimeEvents = data => ({
    type: "SET_INITIAL_ONE_TIME_EVENTS",
    data: data
});

export const requestUserInfo = metadata => ({
    type: "USER_INFO_REQUESTED",
    metadata: metadata
});

export const getUserInfoFailed = errorMsg => ({
    type: "GET_USER_INFO_FAILED",
    errorMsg: errorMsg
});

export const updateUrlPath = pathname => ({
    type: "UPDATE_URL_PATH",
    pathname: pathname
});

export const setActiveTimelineQuery = queryPath => ({
    type: "SET_ACTIVE_TIMELINE_QUERY",
    queryPath
});

export const setScrollPositionBottom = isBottom => ({
    type: "SET_SCROLL_POSITION_BOTTOM",
    isBottom
});

export const setFileDragOver = isOver => ({
    type: "SET_FILE_DRAG_OVER",
    isOver
});