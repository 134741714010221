import moment from "moment";
import 'moment/locale/de';
moment.locale('de');

export const parseFilterFromString = input => {
  const tagRegex = /(^|\s)(#[^ \n#]+)/gi;
  const tagExcludedRegex = /(^|\s)(![^ \n!]+)/gi;

  let title = input.replace(tagRegex, ""); // Remove tags
  title = title.replace(tagExcludedRegex, ""); // Remove excluded tags
  title = title.replace("#", ""); // Remove single #
  title = title.replace(/^\s+|\s+$/g, ""); // Remove leading and trailing whitespace
  title = title.replace(/ +(?= )/g, ""); // Remove double spaces

  let tagsIncluded = [];
  let tagMatches = tagRegex.exec(input);
  while (tagMatches != null) {
    let tag = tagMatches[2]; // Use second capturing group
    tagsIncluded.push(tag.substring(1, tag.length)); // Remove hashtag
    tagMatches = tagRegex.exec(input);
  }

  let tagsExcluded = [];
  let tagExMatches = tagExcludedRegex.exec(input);
  while (tagExMatches != null) {
    let tag = tagExMatches[2]; // Use second capturing group
    tagsExcluded.push(tag.substring(1, tag.length)); // Remove hashtag
    tagExMatches = tagExcludedRegex.exec(input);
  }

  return {
    dateEnd: null,
    dateStart: null,
    queryString: title ? title : null,
    tagsExcluded,
    tagsIncluded
  };
};

export const parseTagsFromString = input => {
  let tags = [];
  let tagRegex = /(^|\s)(#[^ \n#]+)/gi;
  let tagMatches = tagRegex.exec(input);
  while (tagMatches != null) {
    let tag = tagMatches[2]; // Use second capturing group
    tags.push(tag.substring(1, tag.length)); // Remove hashtag
    tagMatches = tagRegex.exec(input);
  }
  return tags;
};

export const parseDateTimeFromInputString = inputString => {
  const dateTime = moment(inputString.substring(1), 'DD.MM.YYYY/hh:mm');
  return dateTime.toDate().toISOString();
}
const todayStrings = ["heute", "today"];
const tomorrowStrings = ["morgen", "tomorrow"];
export const parseDateTimeFromShortInputString = inputString => {
  const inputParts = inputString.substring(1).split('/');
  let finalDateTime = null;
  if (todayStrings.indexOf(inputParts[0]) !== -1) {
    finalDateTime = moment();
  } else {
    if (tomorrowStrings.indexOf(inputParts[0]) !== -1) {
      finalDateTime = moment().add(1, 'day');
    }
  }

  if (finalDateTime) {
    const timeParts = inputParts[1].split(':');
    finalDateTime.hours(timeParts[0]).minutes(timeParts[1]).seconds(0).milliseconds(0);
  }

  return finalDateTime ? finalDateTime.toDate().toISOString() : false;
}
export const parseDateTimeFromVeryShortInputString = inputString => {
  const input = inputString.substring(1);
  let finalDateTime = null;
  if (todayStrings.indexOf(input) !== -1) {
    finalDateTime = moment();
  } else {
    if (tomorrowStrings.indexOf(input) !== -1) {
      finalDateTime = moment().add(1, 'day');
    }
  }

  if (finalDateTime) finalDateTime.hours(9).minutes(0).seconds(0).milliseconds(0);
  return finalDateTime ? finalDateTime.toDate().toISOString() : false;
}

export const parseReadableDateTimeFromString = inputString => {
  const datetime = moment(inputString);
  const datetimeZero = moment(inputString).hours(0).minutes(0).seconds(0).milliseconds(0);

  const today = moment().hours(0).minutes(0).seconds(0).milliseconds(0);
  if (today.valueOf() === datetimeZero.valueOf()) {
    return "Heute " + datetime.format("HH:mm") + " Uhr";
  } else {
    const tomorrow = today.add(1, 'day');
    if (tomorrow.valueOf() === datetimeZero.valueOf()) {
      return "Morgen " + datetime.format("HH:mm") + " Uhr";
    } else {
      return datetime.format("ddd, DD.MM.YYYY HH:mm [Uhr]");
    }
  }
}

export const isDateTimeInPast = (datetime) => {
  return moment(datetime).utc(0).valueOf() < moment().utc(0).valueOf();
}