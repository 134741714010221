let nextId = 2;

const initialState = {
  queries: null,
  retrieveError: null,
  addError: null,
  editError: null,

  queryTree: [],
};

export const query = (state = initialState, action) => {
  switch (action.type) {
    case "SET_QUERY_TREE": {
      return {
        ...state,
        queryTree: action.queryTree
      }
    }
    case "RETRIEVE_QUERIES_SUCCEEDED": {
      // Order queries by position
      let queries = action.queryResult;
      queries = queries.sort((a, b) => {
        let x = a.position,
          y = b.position;
        return x < y ? -1 : x > y ? 1 : 0;
      });

      return {
        ...state,
        queries: queries
      };
    }
    case "RETRIEVE_QUERIES_FAILED": {
      return {
        ...state,
        retrieveError: action.errorMsg
      };
    }
    case "ADD_QUERY_SUCCEEDED": {
      return {
        ...state,
        queries: [
          ...state.queries,
          action.query.query
        ]
      };
    }
    case "ADD_QUERY_FAILED": {
      return {
        ...state,
        addError: action.errorMsg
      };
    }
    case "EDIT_QUERY_SUCCEEDED": {
      let queries = Array.from(state.queries);
      return {
        ...state,
        queries: queries.map(q => {
          if (q.id === action.query.queryId) {
            return {
              ...q,
              name: action.query.name,
              emoji: action.query.emoji,
              query: action.query.query
            }
          }
          return q;
        })
      }
    }
    case "REMOVE_QUERY_SUCCEEDED": {
      return {
        ...state,
        queries: Array.from(state.queries).filter(q => q.id !== action.queryId)
      }
    }
    case "EDIT_QUERY_FAILED": {
      return {
        ...state,
        editError: action.errorMsg
      }
    }
    case "RESET_EDIT_QUERY_ERROR": {
      return {
        ...state,
        editError: null
      };
    }
    case "RESET_ADD_QUERY_ERROR": {
      return {
        ...state,
        addError: null
      };
    }
    case "DRAG_QUERY_SET_POS": {
      let queries = Array.from(state.queries);
      let to = 1;
      let from = 0;

      queries.forEach((q, i) => {
        if (q.id === action.draggedId) from = i;
        if (q.id === action.destinationId) to = i;
      });

      queries.splice(to, 0, queries.splice(from, 1)[0]);

      queries.forEach((q, i) => {
        q.position = i;
      });
      
      return { 
        ...state,
        queries: queries
      }
    }
    // case "SC_QUERY_UP": {
    //   let nInd = state.allQueries.indexOf(state.activeQuery) - 1;
    //   let nextEligable = nInd < 0 ? state.activeQuery : state.allQueries[nInd];
    //   let i = 1;
    //   while (
    //     !(state.queries.hasOwnProperty(nextEligable) || nextEligable < 0) &&
    //     state.allQueries.indexOf(nextEligable) !== -1
    //   ) {
    //     let newIndex = state.allQueries.indexOf(state.activeQuery) - ++i;
    //     nextEligable =
    //       newIndex < 0 ? state.activeQuery : state.allQueries[newIndex];
    //     if (i > 500) break; // Safety
    //   }

    //   return {
    //     ...state,
    //     activeQuery: nextEligable
    //   };
    // }
    // case "SC_QUERY_DOWN": {
    //   let nInd = state.allQueries.indexOf(state.activeQuery) + 1;
    //   let nextEligable =
    //     nInd >= state.allQueries.length
    //       ? state.activeQuery
    //       : state.allQueries[nInd];
    //   let i = 1;
    //   while (
    //     !(state.queries.hasOwnProperty(nextEligable) || nextEligable < 0) &&
    //     state.allQueries.indexOf(nextEligable) !== -1
    //   ) {
    //     let newIndex = state.allQueries.indexOf(state.activeQuery) + ++i;
    //     nextEligable =
    //       newIndex >= state.allQueries.length
    //         ? state.activeQuery
    //         : state.allQueries[newIndex];
    //     if (i > 500) break; // Safety
    //   }

    //   return {
    //     ...state,
    //     activeQuery: nextEligable
    //   };
    // }
    case "ADD_QUERY": {
      nextId++;
      return {
        ...state,
        queries: {
          ...state.queries,
          [nextId]: {
            id: nextId,
            name: action.name,
            value: action.value,
            children: null
          }
        },
        allQueries: [...state.allQueries, nextId]
      };
    }
    default:
      return state;
  }
};
