import React, { Component } from 'react';
import './Settings.scss';

export class SettingHeader extends Component {
  render() {
    return (
      <div className="settingHeader">
        <h1>Einstellungen und Optionen</h1>
        <span>für diesen Workspace und Benutzer</span>
      </div>
    );
  }
}
