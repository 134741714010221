export const convertDataFormat = data => {
  let newData = {};
  let allData = [];
  data.forEach(elem => {
    newData[elem.id] = { ...elem };
    if (elem.tags) newData[elem.id].tags = convertDataFormat(elem.tags);
    if (elem.links) newData[elem.id].links = convertDataFormat(elem.links);
    if (elem.files) newData[elem.id].files = convertDataFormat(elem.files);
    if (elem.reminders) newData[elem.id].reminders = convertDataFormat(elem.reminders);
    allData.push(elem.id);
  });
  return { data: newData, indexArray: allData } ;
};

export const convertFileSize = (bytes, decimals = 2) => {
  if (bytes === 0) return '0 Bytes';

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));
  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
}

export const convertDateTime = date => {
  let year = date.getFullYear(),
  month = date.getMonth() + 1, // months are zero indexed
  day = date.getDate(),
  hour = date.getHours(),
  minute = date.getMinutes(),
  second = date.getSeconds(),
  minuteFormatted = minute < 10 ? "0" + minute : minute,
  secondFormatted = second < 10 ? "0" + second : second

  return day + "." + month + "." + year + " " + hour + ":" + minuteFormatted + ":" + secondFormatted;
}

export const convertQueriesToTree = queries => {
  // TODO: Implement conversion:
  
  // return queries;

  return [
    { id: 0,  name:"Tools", query:"#tools", emoji:"🔨", collapsed: false },
    { id: 1, name:"Todo", query:"#todo", emoji:"📃", collapsed: false, children: [
      { id: 4, name:"DHBW", query:"#dhbw", emoji:"👨‍🎓", collapsed: false },
    //   { id: 5, name:"Noch ebes", query:"#dhbw2", emoji:"🗽", collapsed: false, children: [
    //     { id: 6, name:"Unterding", query:"#dhbw3", emoji:"👨‍🎓", collapsed: false },
    //     { id: 7, name:"Hallo", query:"#dhbw4", emoji:"👨‍🎓", collapsed: false }
    //   ]}
    ]},
    { id: 2, name:"Webdesign", query:"#webdesign", emoji:"💻", collapsed: false },
    { id: 3, name:"Marketing", query:"#marketing", emoji:"🙋", collapsed: false },
    // { id: 8, name:"Bewerben", query:"#bewerben", emoji:"💲", collapsed: false },
    // { id: 9, name:"Appartments", query:"#appa", emoji:"❓", collapsed: false },

    // { id: 22, name:"Webdesign", query:"#webdesign", emoji:"💻", collapsed: false },
    // { id: 32, name:"Marketing", query:"#marketing", emoji:"🙋", collapsed: false },
    // { id: 82, name:"Bewerben", query:"#bewerben", emoji:"💲", collapsed: false },
    // { id: 92, name:"Appartments", query:"#appa", emoji:"❓", collapsed: false }
  ]
}