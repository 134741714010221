import { baseUrl } from "./config";

export const updateOneTimeEventRequest = (metadata, eventName) => {
  return new Promise((resolve, reject) => {
    fetch(`${baseUrl}/update-one-time-event`, {
      method: "post",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${metadata.token}`
      },
      body: JSON.stringify({
        eventName: eventName
      })
    })
      .then(res => {
        res.json().then(rj => {
          // console.log(rj);
          resolve(rj);
        });
      })
      .catch(err => {
        reject(err);
      });
  });
};
