import { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { updateUrlPath } from "../../actions/navigation";

class RouterUpdater extends Component {

  componentDidMount() {
    this.updatePathInState();
  }

  componentDidUpdate(prevProps) {
    let { location: loc1 } = this.props;
    let { location: loc2 } = prevProps;
    if (loc1.pathname !== loc2.pathname) this.updatePathInState();
  }

  updatePathInState = () => {
    this.props.updateUrlPath(this.props.location.pathname);
  }

  render() {
    return null;
  }
}

const mapDispatchToProps = dispatch => ({
  updateUrlPath: pathname => dispatch(updateUrlPath(pathname))
});

export default withRouter(connect(null, mapDispatchToProps)(RouterUpdater));