const initialState = {
  theme: "light",
  language: "DE"
};

export const preferences = (state = initialState, action) => {
  switch (action.type) {
    case "SET_INITIAL_USER_SETTINGS": {
      return {
        ...state,
        theme: action.settings.theme,
        language: action.settings.language
      }
    }
    case "SWITCH_THEME": {
      return {
        ...state,
        theme: action.themeName
      };
    }
    default:
      return state;
  }
};
