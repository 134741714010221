export const modalCodes = {
    ADD_ELEMENT_MODAL: 0,
    ADD_QUERY_MODAL: 1,
    EDIT_QUERY_MODAL: 3,
    LOGOUT_CONFIRM_MODAL: 4,
    IMAGE_VIEWER: 5,
    ADD_BOARD_MODAL: 6,
    ADD_BOARD_LIST_MODAL: 7,
    EDIT_BOARD_MODAL: 8,
    EDIT_BOARD_LIST_MODAL: 9,
    DELETE_CONFIRM_MODAL: 10,
    ELEM_CONTEXT_MENU_MODAL: 11,
    DELETE_BOARD_CONFIRM_MODAL: 12,
    CMD_SEARCH_MODAL: 13,
}