import React from "react";
import "./LoadingScreen.scss";
import { useSelector } from "react-redux";
import { UpdateHandler } from "./UpdateHandler";

export const LoadingScreen = () => {
  const userInfoError = useSelector(state => state.navigation.userInfoError);

  return (
    <div className="loadingScreen">
      <UpdateHandler />
      {userInfoError ? (
        <div className="errorContainer material">
          <span className="error">{userInfoError}</span>
          <button className="primary" onClick={() => document.location.reload()}>Neu laden</button>
        </div>
      ) : (
        <div className="loadingDots">
          <div className="bounce1" />
          <div className="bounce2" />
          <div className="bounce3" />
        </div>
      )}
    </div>
  );
}