import React, { Component } from "react";
import "./QueryArea.scss";
import QueryItem from "./QueryItem";
import { withRouter } from "react-router-dom";

class StaticQueryArea extends Component {

  render() {
    return (
      <div className="queryArea">
        <QueryItem
          id={-2}
          name="Ungetaggt"
          icon="flip-to-back"
          history={this.props.history}
          location={this.props.location}
          mobile={this.props.mobile ? true : false}
        />
        <QueryItem
          id={-1}
          name="Zeitachse"
          icon="trending-up"
          history={this.props.history}
          location={this.props.location}
          mobile={this.props.mobile ? true : false}
        />
      </div>
    );
  }
}

export default withRouter(StaticQueryArea);
