import React, { Component } from "react";
import "./QueryArea.scss";
import { MaterialIcons } from "react-web-vector-icons";
import { connect } from "react-redux";
import { openModal, toggleMobileMenu } from "../../actions/navigation";

class QueryItem extends Component {
  constructor(props) {
    super(props);

    this.me = React.createRef();
    
    let { id } = props;
    this.myUrl = null;
    if (id < 0) {
      switch(id) {
        case -1: {
          this.myUrl = "/timeline";
          break;
        }
        case -2: {
          this.myUrl = "/untagged";
          break;
        }
        default: {}
      }
    } else {
      this.myUrl = "/query/" + id;
    }
  }

  _switchQuery = () => {
    this.props.history.push(this.myUrl);
    if (this.props.mobile) this.props.toggleMobileMenu();
  };

  _showEditModal = e => {
    e.stopPropagation();
    let { id, emoji, name, query } = this.props;
    this.props.openModal(3, { queryId: id, emoji: emoji, name: name, query: query });
  }

  render() {
    let cn = this.props.location.pathname === this.myUrl
        ? "queryItem active"
        : "queryItem";

    return (
      <div>
        <div
          id={this.props.id}
          className={cn}
          onClick={() => this._switchQuery()}
          ref={this.me}
        >
          <div className="infoContainer">
            <div className="icon">
              {this.props.icon ? (
              <MaterialIcons
                name={this.props.icon}
                size={23}
              />) : <span role="img" aria-label="emoji">{this.props.emoji}</span> }
            </div>
            <div className="name">
              <p>{this.props.name}</p>
            </div>
          </div>
          {this.props.editable ? (
            <div className="actionContainer">
              <div className="editAction" onClick={this._showEditModal}>
                <MaterialIcons name="edit" size={20} />
              </div>
            </div>
          ) : null}
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  queryDisplay: state.query.queryDisplay
});

const mapDispatchToProps = dispatch => ({
  toggleMobileMenu: () => dispatch(toggleMobileMenu()),
  openModal: (modalId, modalData) => dispatch(openModal(modalId, modalData))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(QueryItem);
