import React, { Component } from 'react';
import './Notifications.scss';
import { connect } from 'react-redux';
import Notification from './Notification';
import { clearAllNotifications } from '../../actions/notification';

class Notifications extends Component {
  constructor(props) {
    super(props);

    this.initialRender = true;
  }

  render() {
    // This is to prevent showing error messages from forced-logouts / database drops
    // that are related to the previous account
    if (this.initialRender) {
      this.props.clearAllNotifications();
      this.initialRender = false;
    }

    const { nlist } = this.props;
    const NotiList = Object.keys(nlist).map((n, i) => {
      return <Notification key={n} index={i} {...nlist[n]} />;
    });

    return <div className="notifications">{NotiList}</div>;
  }
}

const mapStateToProps = state => ({
  nlist: state.notification.list
});

const mapDispatchToProps = dispatch => ({
  clearAllNotifications: () => dispatch(clearAllNotifications())
});

export default connect(mapStateToProps, mapDispatchToProps)(Notifications);
