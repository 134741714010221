import React, { Component } from 'react';
import './SideMenu.scss';
import { SideMenuItem } from './SideMenuItem';
import { SideMenuSlider } from './SideMenuSlider';
import { MaterialIcons } from 'react-web-vector-icons';
import { connect } from 'react-redux';
import { openModal, closeModal } from './../../actions/navigation';
// import { ContextItem } from "./ContextItem";
import { withRouter } from 'react-router-dom';

class SideMenu extends Component {
  constructor(props) {
    super(props);

    this.state = { activeView: -1 };
  }

  static getDerivedStateFromProps(props, state) {
    const paidMenuChange = [{
      position: 2,
      paths: [ 'insights' ]
    },
    {
      position: 3,
      paths: [ 'settings' ]
    }];
    const basicMenuChange = [{
      position: 2,
      paths: [ 'settings' ]
    }];

    const routingMap = [
      {
        position: 0,
        paths: [ 'timeline', 'query', 'untagged' ]
      },
      {
        position: 1,
        paths: [ 'boards', 'board' ]
      }
    ];
    const toAdd = props.isPaidUser ? paidMenuChange : basicMenuChange;
    routingMap.push(...toAdd);

    const activePath = props.location.pathname.split('/')[1];
    let activeView = null;
    routingMap.forEach(e => {
      if (e.paths.indexOf(activePath) !== -1) activeView = e.position;
    });
    return {
      ...state,
      activeView: activeView
    };
  }

  _switchView = clickedView => {
    this.props.history.push(clickedView);
  };

  _clickOutside = e => {
    if (e.target === e.currentTarget) {
      this.props.closeModal();
    }
  };

  componentDidMount() {
    // Set default or last selected theme
    document.documentElement.setAttribute('theme', this.props.theme);
    document.addEventListener('keydown', this.handleKeyDown);
  }

  componentDidUpdate() {
    let currentTheme = document.documentElement.getAttribute('theme');
    if (currentTheme && currentTheme !== this.props.theme) {
      document.documentElement.classList.add('theme-transition');
      document.documentElement.setAttribute('theme', this.props.theme);
      window.setTimeout(() => {
        document.documentElement.classList.remove('theme-transition');
      }, 400);
    }
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.handleKeyDown);
  }

  handleKeyDown = e => {
    // if (
    //   !e.key ||
    //   ((e.target.tagName.toUpperCase() === 'INPUT' || e.target.tagName.toUpperCase() === 'TEXTAREA') &&
    //     !e.target.hasAttribute('readonly'))
    // )
    //   return;

    const { activeView } = this.state;
    if (e.altKey) {
      switch (e.key) {
        case 'ArrowUp': {
          if (activeView === 0) return false;
          this._switchView(this.getPathForActiveView(activeView - 1));
          return true;
        }
        case 'ArrowDown': {
          if (activeView === (this.props.isPaidUser ? 3 : 2)) return false;
          this._switchView(this.getPathForActiveView(activeView + 1));
          return true;
        }
        default:
          return false;
      }
    }
    return false;
  };

  getPathForActiveView = activeView => {
    const { activeBoardId, activeTimelineQuery, isPaidUser } = this.props;
    switch (activeView) {
      case 0: {
        return activeTimelineQuery;
      }
      case 1: {
        return activeBoardId ? `/board/${activeBoardId}` : '/boards';
      }
      case 2: {
        return isPaidUser ? '/insights' : '/settings';
      }
      case 3: {
        return isPaidUser ? '/settings' : null;
      }
      default:
        return null;
    }
  };

  render() {
    const isOpened = this.props.openedModal === 2;
    const cn = isOpened ? 'contextSwitchWrapper open' : 'contextSwitchWrapper';
    const cnBtn = isOpened ? 'contextBtn open' : 'contextBtn';
    const { activeBoardId, activeTimelineQuery, isPaidUser } = this.props;
    const { activeView } = this.state;

    return (
      <div className="sidemenu">
        <div className="contextBtnWrapper">
          <div className={cnBtn} onClick={() => this.props.openModal(2, null)} />
          <div className={cn} onClick={e => this._clickOutside(e)}>
            <div className="contextSwitch">
              <h3>Workspace wechseln</h3>
              <div className="contextList">
                <p>
                  Zur Zeit ist nur ein privater Workspace verfügbar. Wir arbeiten jedoch daran, geteilte Workspaces für
                  Teams so bald wie möglich zur Verfügung zu stellen.
                </p>
                {/* <ContextItem name="Privat" />
                <ContextItem name="Arbeit" /> */}
              </div>
            </div>
          </div>
        </div>
        <div className="items">
          <SideMenuSlider pos={activeView} />
          <SideMenuItem
            path={activeTimelineQuery}
            icon="layers"
            tooltip="Übersicht"
            switchEvent={this._switchView}
            active={activeView === 0}
          />
          <SideMenuItem
            path={activeBoardId ? `/board/${activeBoardId}` : '/boards'}
            icon="view-compact"
            tooltip="Listenansicht"
            switchEvent={this._switchView}
            active={activeView === 1}
          />
          {isPaidUser && <SideMenuItem
            path="/insights"
            icon="insert-chart"
            tooltip="Nutzungsstatistiken"
            switchEvent={this._switchView}
            active={activeView === 2}
          />}
          <SideMenuItem
            path="/settings"
            icon="settings"
            tooltip="Einstellungen"
            switchEvent={this._switchView}
            active={activeView === (isPaidUser ? 3 : 2)}
          />
        </div>
        <div className="logoutBtnWrapper" onClick={() => this.props.openModal(4, null)}>
          <MaterialIcons name="directions-walk" size={28} />
          <div className="tooltip">
            <span>Abmelden</span>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  activeView: state.navigation.activeView,
  openedModal: state.navigation.openModal,
  theme: state.preferences.theme,
  activeBoardId: state.board.activeBoardId,
  activeTimelineQuery: state.navigation.activeTimelineQuery,
  isPaidUser: state.authentication.userData.isPaidUser
});

const mapDispatchToProps = dispatch => ({
  openModal: (modalId, modalData) => dispatch(openModal(modalId, modalData)),
  closeModal: () => dispatch(closeModal())
});
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SideMenu));
