import React from "react";

export class FadeGradient extends React.Component {

  constructor(props) {
    super(props);

    this.state = { shown: true };
  }

  onScroll = e => {
    const cannotScroll = e.target.scrollHeight === e.target.offsetHeight;
    const { shown } = this.state;
    if (cannotScroll || e.target.scrollTop === 0) {
      if (shown) this.setState({ ...this.state, shown: false });
    } else {
      if (!shown) this.setState({ ...this.state, shown: true });
    }
  }

  render() {
    return <div className={this.state.shown ? "fadeGradient" : "fadeGradient hidden"}  />
  }
}