export const baseUrl = "https://api.tagstack.app/v1"

export const statusCodes = {
    // Success
    SUCCESS: 0,
  
    // Token Error (problem with/missing/invalid token)
    TOKEN_ERROR: 1,
    
    // Auth (no permission for action)
    AUTH_ERROR: 2,
  
    // HTTP
    INVALID_PARAMS: 3,
  
    // Unknown
    UNKNOWN_ERROR: 4,
  
    // General
    NOT_FOUND: 404,
    INTERNAL_SERVER_ERROR: 500,
  };