import React, { Component } from 'react';
import './ElementList.scss';
import Element from './Element';
import { connect } from 'react-redux';
import { retrieveElements, retrieveUntaggedElements, resetNewIndicatorAmount } from './../../actions/element';
import GroupedElements from './GroupedElements';
import { ScrollToBottomButton } from '../ElementList/ScrollToBottomButton';
import { getReference, ReferenceNames, setReference } from '../../utils/reference';
import { isTimelineView } from '../../utils/view';
import { UndoButton } from './UndoButton';
import { setScrollPositionBottom } from '../../actions/navigation';

class ElementList extends Component {
  constructor(props) {
    super(props);

    this.firstUpdate = true;
    this.scrollState = {
      pathChanged: false,
      setInititalPos: false,
      lastScrollHeight: null
    };
    this.reqPending = false;
    this.state = { showToBottomBtn: false };
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.preventDoubleClickSelect, false);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.preventDoubleClickSelect, false);
  }

  preventDoubleClickSelect = e => {
    // Prevent selection on title double click
    if (e.detail > 1 && e.target.tagName === 'H4') {
      e.preventDefault();
    }
  };

  getSnapshotBeforeUpdate() {
    const scrollView = getReference(ReferenceNames.ELEMENT_SCROLL_VIEW);
    if (scrollView) return scrollView.scrollTop;
    return null;
  }

  componentDidUpdate(prevProps, _prevState, snapshot) {
    if (this.firstUpdate) {
      this.firstUpdate = false;
      return;
    }

    // Recover previous scroll position and prevent scroll-bugs from re-rendering
    const scrollView = getReference(ReferenceNames.ELEMENT_SCROLL_VIEW);
    if (scrollView) scrollView.scrollTop = snapshot;

    // If screen is higher than loaded elements, load more
    const elementList = getReference(ReferenceNames.ELEMENT_LIST);
    if (elementList) {
      let height = elementList.scrollHeight;

      // Scroll to bottom
      if (!this.scrollState.pathChanged)
        this.scrollState.pathChanged = prevProps.location.pathname !== this.props.location.pathname;
      if (this.scrollState.pathChanged && prevProps.elements !== this.props.elements) {
        this.scrollState.pathChanged = false;
        this.scrollState.setInititalPos = false;
      }
      
      if (!this.scrollState.setInititalPos && this.props.elements) {
        scrollView.scrollTop = height;
        this.scrollState.setInititalPos = true;
      }

      // Keep scroll position when user scrolled instead of sticking there
      if (scrollView && scrollView.scrollHeight !== this.scrollState.lastScrollHeight) {
        scrollView.scrollTop = scrollView.scrollHeight - this.scrollState.lastScrollHeight + scrollView.scrollTop;
      }

      if (height < window.innerHeight && isTimelineView(this.props.location.pathname)) {
        this.onScrollBottom();
      }

      // Hide/show scroll-down-FAB on element changes
      if (scrollView) this.manageScrollToBottomButton(scrollView);   

      if (this.scrollState.pathChanged) this.reqPending = false;
    }
  }

  // Called from ContentArea
  handleScroll = e => {
    // Preload 200px before top
    const top = e.target.scrollHeight - e.target.scrollTop + 200 >= e.target.scrollHeight;
    if (top && !this.reqPending && !this.props.showLoadingAnim) {
      this.reqPending = true;
      this.onScrollBottom();
    }
    
    this.scrollState = {
      pathChanged: false,
      setInititalPos: true,
      lastScrollHeight: e.target.scrollHeight
    };

    // Hide/show scroll-down-FAB on scroll
    this.manageScrollToBottomButton(e.target);    
  };

  manageScrollToBottomButton = scrollView => {
    // Check if scroll position is at bottom
    const { scrollPositionBottom, setScrollPositionBottom, newIndicatorAmount, resetNewIndicatorAmount } = this.props;
    if (scrollView.scrollTop + scrollView.offsetHeight + 1 >= scrollView.scrollHeight) {
      if (!scrollPositionBottom) {
        setScrollPositionBottom(true);
        if (newIndicatorAmount !== 0) resetNewIndicatorAmount();
      }
    } else {
      if (scrollPositionBottom) setScrollPositionBottom(false);
    }
  }

  onScrollBottom = () => {
    if (!this.props.bottomReached) this.requestElementList();
  };

  setReqPendingDone = () => {
    this.reqPending = false;
  }

  requestElementList = () => {
    // This is required to prevent a bug where the infinite-scroll request cancelled the previous
    // initial request and thereby elements from the previous query were still displayed
    if (this.props.showLoadingAnim) return;

    if (this.props.location.pathname.split('/')[1] === 'untagged') {
      let { triggerRequest, tagsIncluded, tagsExcluded, ...rest } = this.props.filter;
      this.props.retrieveUntaggedElements(
        {
          token: this.props.token,
          workspaceId: this.props.workspaceId,
          language: this.props.language,
          lastId: this.props.lastId,
          filter: rest
        },
        true,
        this.setReqPendingDone
      );
    } else {
      let { triggerRequest, ...rest } = this.props.filter;
      this.props.retrieveElements(
        {
          token: this.props.token,
          workspaceId: this.props.workspaceId,
          language: this.props.language,
          lastId: this.props.lastId,
          filter: rest
        },
        true,
        this.setReqPendingDone
      );
    }
  };

  // groupElementsHiddenByFilter = () => {
  //   let allHiddenElements = Object.keys(this.props.hiddenByFilter).map(elem => {
  //     let { id, title, tags, links, files } = this.props.hiddenByFilter[elem];
  //     return <Element key={id} id={id} title={title} tags={tags} links={links} files={files} />;
  //   });
  //   return allHiddenElements.length === 0 ? null : (
  //     <GroupedElements key="hidden-elements" type="hidden" elements={allHiddenElements} />
  //   );
  // };

  // To force re-render on all elements after switching to timeline view again to re-calculate 
  // the title height for newly added elements to prevent them from being height 0
  doRerender = () => {
    this.refreshElements = true;
    this.setState(this.state, () => {
      setTimeout(() => { this.refreshElements = false }, 30);
    });
  }

  render() {
    // Loading animation when switching between queries
    if (this.props.showLoadingAnim) {
      return (
        <div className="transitionLoader">
          <div className="loadingDots">
            <div className="bounce1" />
            <div className="bounce2" />
            <div className="bounce3" />
          </div>
        </div>
      );
    }

    if (this.props.retrieveError === null) {
      // Figure out which elements' dates match and group them
      let { elements } = this.props;
      let groupList = [];
      if (elements !== null) {
        // For each element
        Object.keys(elements).forEach(elem => {
          let createdAt = new Date(this.props.elements[elem].DATE_CREATED);
          createdAt.setHours(0, 0, 0, 0);

          let lastGroup = groupList[groupList.length - 1];
          // If there is already a group
          if (lastGroup) {
            let lastCreatedAt = new Date(this.props.elements[lastGroup[0]].DATE_CREATED);
            lastCreatedAt.setHours(0, 0, 0, 0);
            // Compare dates without time
            if (createdAt.valueOf() === lastCreatedAt.valueOf()) {
              // Add to last group if dates match
              lastGroup.push(elem);
            } else {
              // Otherwise create new group
              groupList.push([ elem ]);
            }
          } else {
            // No previous group, add new one
            groupList.push([ elem ]);
          }
        });
      }

      // Iterate over grouping and return Components
      let GroupedList = groupList.map((group, ind) => {
        let grouped = [];
        group.forEach(elem => {
          let { id, title, tags, links, files, reminders, filterNotMatched } = this.props.elements[elem];
          grouped.push(
            <Element
              key={id}
              id={id}
              title={title}
              tags={tags}
              links={links}
              files={files}
              reminders={reminders}
              forcedRefresh={this.refreshElements ? true : false}
              filterNotMatched={filterNotMatched}
            />
          );
        });
        let firstDate = this.props.elements[group[0]].DATE_CREATED;
        // Key needs to be set to this to make sure react knows the correct order
        // Only index/timestamp will result in always being scrolled to the bottom
        return <GroupedElements key={groupList.length - ind} date={firstDate} elements={grouped} />;
      });

      // let hbf = this.groupElementsHiddenByFilter();
      // if (hbf) GroupedList.push(hbf);

      return (
        <div ref={ref => setReference(ReferenceNames.ELEMENT_LIST, ref)} className="elementList">
          <ScrollToBottomButton
            shown={!this.props.scrollPositionBottom}
            newIndicatorAmount={this.props.newIndicatorAmount}
          />
          <UndoButton isOffset={!this.props.scrollPositionBottom} />
          {!this.props.bottomReached && this.props.elements !== null ? (
            <div className="loadingDots">
              <div className="bounce1" />
              <div className="bounce2" />
              <div className="bounce3" />
            </div>
          ) : null}
          {GroupedList.length === 0 ? this.props.elements === null ? null : (
            <div className="empty">
              <img src="/empty.svg" alt="Empty" draggable="false" />
              <p>Keine Einträge vorhanden</p>
            </div>
          ) : (
            GroupedList
          )}
        </div>
      );
    } else {
      return (
        <div className="elementList">
          <p className="errorMsg">{this.props.retrieveError}</p>
        </div>
      );
    }
  }
}

const mapStateToProps = state => ({
  elements: state.element.elements,
  // hiddenByFilter: state.element.hiddenByFilter,
  lastId: state.element.lastId,
  bottomReached: state.element.bottomReached,
  filter: state.filter,
  token: state.authentication.userData.token,
  workspaceId: state.authentication.userData.workspaceId,
  retrieveError: state.element.retrieveError,
  language: state.preferences.language,
  didAppend: state.element.didAppend,
  showLoadingAnim: state.element.showLoadingAnim,
  scrollPositionBottom: state.navigation.scrollPositionBottom,
  newIndicatorAmount: state.element.newIndicatorAmount
});

const mapDispatchToProps = dispatch => ({
  retrieveElements: (metadata, append, setReqPendingDone) => dispatch(retrieveElements(metadata, append, setReqPendingDone)),
  retrieveUntaggedElements: (metadata, append, setReqPendingDone) => dispatch(retrieveUntaggedElements(metadata, append, setReqPendingDone)),
  setScrollPositionBottom: isBottom => dispatch(setScrollPositionBottom(isBottom)),
  resetNewIndicatorAmount: () => dispatch(resetNewIndicatorAmount())
});

export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(ElementList);
