import React, { Component } from "react";
import { connect } from "react-redux";
import { closeModal } from "./../../actions/navigation";
import { MaterialIcons } from "react-web-vector-icons";
import { logout } from "../../actions/authentication";
import { modalCodes } from "../../utils/enums";
import { LoadingBar } from "../Loading/LoadingBar";
import { addBoardRequested, resetAddBoardError } from "../../actions/board";

class AddBoardModal extends Component {
  constructor(props) {
    super(props);

    this.initialFocus = true;
    this.nameInput = React.createRef();
    this.state = { loadingBarShown: false, name: "" };
  }

  static getDerivedStateFromProps(props, state) {
    let modalClosed = props.openModal && props.openModal !== modalCodes.ADD_BOARD_MODAL;

    // Reset error when modal is closed
    // if (modalClosed && props.addBoardError !== null && props.targetDevice === "desktop") props.resetAddElementError();
    if (modalClosed && props.addBoardError !== null) props.resetAddBoardError();

    if (modalClosed) {
      return {
        ...state,
        name: ""
      };
    }
    return null;
  }

  _clickOutside = e => {
    if (e.target === e.currentTarget) {
      this.props.closeModal();
    }
  };

  componentDidUpdate() {
    if (this.props.openModal === modalCodes.ADD_BOARD_MODAL) {
      this.initialFocus = true;
    }
  }

  _handleChange = e => {
    this.setState({ ...this.state, name: e.target.value });
  };

  _keyDown = e => {
    if (e.key.toLowerCase() === "enter") {
      e.preventDefault();
      this._submitCreateBoard();
    }
  }

  _submitCreateBoard = () => {
    let { token, workspaceId, addBoardRequested } = this.props;
    addBoardRequested({
      token: token,
      workspaceId: workspaceId
    }, { name: this.state.name });
  }

  render() {
    if (
      this.props.openModal === modalCodes.ADD_BOARD_MODAL &&
      this.initialFocus
    ) {
      this.initialFocus = false;
      setTimeout(() => {
        if (this.nameInput.current) {
          this.nameInput.current.focus();
        }
      }, 150);
    } else {
      // Remove focus from input after close
      if (this.props.openModal !== modalCodes.ADD_BOARD_MODAL) {
        setTimeout(() => {
          if (this.nameInput.current) this.nameInput.current.blur();
        }, 150);
      }
    }

    return (
      <div
        className={
          this.props.openModal === modalCodes.ADD_BOARD_MODAL
            ? "modalWrapper centered open"
            : "modalWrapper centered"
        }
        onMouseDown={e => this._clickOutside(e)}
      >
        <div className="modalContent addBoard">
          <LoadingBar visible={this.state.loadingBarShown} />
          <h2>Board hinzufügen</h2>
          <div className="close" onClick={() => this.props.closeModal()}>
            <MaterialIcons name="close" size={18} />
          </div>
          <div className="fields">
            <span>Name des Boards: </span>
            <input
              type="text"
              ref={this.nameInput}
              value={this.state.name}
              onChange={this._handleChange}
              onKeyDown={this._keyDown}
              className="nameInput"
              spellCheck={false}
            />
          </div>

          {this.props.addBoardError === null ? null : (
            <p className="errorMsg">{this.props.addBoardError}</p>
          )}

          <div className="actionItems material">
            <button className="primary" onClick={() => this._submitCreateBoard()}>Erstellen</button>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  openModal: state.navigation.openModal,
  token: state.authentication.userData.token,
  workspaceId: state.authentication.userData.workspaceId,
  addBoardError: state.board.addBoardError
});

const mapDispatchToProps = dispatch => ({
  closeModal: () => dispatch(closeModal()),
  logout: () => dispatch(logout()),
  addBoardRequested: (metadata, boardData) => dispatch(addBoardRequested(metadata, boardData)),
  resetAddBoardError: () => dispatch(resetAddBoardError())
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AddBoardModal);
