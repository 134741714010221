import React from "react";
import "./Boards.scss";
import { MaterialIcons } from "react-web-vector-icons";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setActiveBoard } from "../../actions/board";
import { useEffect } from "react";
import { openModal } from "../../actions/navigation";
import { modalCodes } from "../../utils/enums";

export const BoardHeader = ({ activeBoardId, activeBoard }) => {
  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setActiveBoard(activeBoardId ? activeBoardId : null));
  }, [ activeBoardId ]);

  return (
    <div className="boardHeader">
      {activeBoardId ? (
        <>
          <div className="backArrow" onClick={() => history.push("/boards")}>
            <MaterialIcons name="arrow-back" size={24} />
          </div>
          <h2 className="title">{activeBoard.name}</h2>
          <div className="spacing"></div>
          <div className="material">
            <button className="flat" onClick={() => dispatch(openModal(modalCodes.EDIT_BOARD_MODAL, {
              boardId: activeBoardId,
              boardName: activeBoard.name
            }))}>Bearbeiten</button>
          </div>
        </>
      ) : (
        <>
          <div className="backArrow noHover">
            <MaterialIcons name="web" size={24} />
          </div>
          <h2 className="title">Boardauswahl</h2>
        </>
      )}
    </div>
  )
}