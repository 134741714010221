import React, { Component } from "react";
import "./Modals.scss";
// import AddElementModal from "./AddElementModal";
import AddQueryModal from "./AddQueryModal";
import EditQueryModal from "./EditQueryModal";
import LogoutConfirmModal from "./LogoutConfirmModal";
import ImageViewer from "./ImageViewer";
import AddBoardModal from "./AddBoardModal";
import AddBoardListModal from "./AddBoardListModal";
import EditBoardModal from "./EditBoardModal";
import EditBoardListModal from "./EditBoardListModal";
import DeleteConfirmModal from "./DeleteConfirmModal";
import { ElemContextMenuModal } from "./ElemContextMenuModal";
import DeleteBoardConfirmModal from "./DeleteBoardConfirmModal";
import CmdSearchModal from "./CmdSearchModal";

export class Modals extends Component {
  render() {
    return (
      <div className="modals">
        {/* <AddElementModal targetDevice={this.props.targetDevice} /> */}
        <AddQueryModal />
        <EditQueryModal />
        <LogoutConfirmModal />
        <ImageViewer />
        <AddBoardModal />
        <EditBoardModal />
        <AddBoardListModal />
        <EditBoardListModal />
        <DeleteConfirmModal />
        <DeleteBoardConfirmModal />
        <CmdSearchModal />
        
        <ElemContextMenuModal />
      </div>
    );
  }
}