import React from "react";
import { BoardTile } from "./BoardTile";
import { useDispatch } from "react-redux";
import { openModal } from "../../actions/navigation";
import { modalCodes } from "../../utils/enums";
import BoardLists from "./BoardLists";
import { useSelector } from 'react-redux';

export const BoardContent = ({ activeBoardId, boards }) => {
  const dispatch = useDispatch();
  const theme = useSelector(state => state.preferences.theme);

  if (activeBoardId) {
    return (
      <div className="boardContent">
        <div className="colorSpacing"></div>
        <BoardLists activeBoardId={activeBoardId} />
      </div>
    )
  }

  const boardTileList = Object.keys(boards).map(b => <BoardTile theme={theme} key={b} id={boards[b].id} name={boards[b].name} />);
  return (
    <div className="boardContent">
      <div className="boardSelectionWrapper">
        <div className="boardSelection">
          {boardTileList}
          <div className="boardTile addTile" onClick={() => dispatch(openModal(modalCodes.ADD_BOARD_MODAL))}>
            <img src={`/images/add-board-${theme}.png`} alt="Add board" draggable="false" />
            <span>Neues Board anlegen</span>
          </div>
        </div>
      </div>
    </div>
  )
}