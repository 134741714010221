import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateUserData } from "../../actions/authentication";

/*
  The purpose of this component is to set newly added values
  in the persisted local state to prevent errors and not require
  the user to sign out and back in to set the default values for
  the new variables
*/
export const UpdateHandler = () => {

  const { isPaidUser, storageUsed } = useSelector(state => state.authentication.userData);
  const dispatch = useDispatch();

  useEffect(() => {
    if (isPaidUser === undefined || storageUsed === undefined) {
      dispatch(updateUserData({ isPaidUser: false, storageUsed: 0 }))
    }
  }, []);
  return null;
}