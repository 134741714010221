import React, { Component } from "react";
import { connect } from "react-redux";
import { closeModal } from "./../../actions/navigation";
import { MaterialIcons } from "react-web-vector-icons";
import { logout } from "../../actions/authentication";
import { modalCodes } from "../../utils/enums";

class LogoutConfirmModal extends Component {

  componentDidUpdate() {
    if (this.props.openModal === modalCodes.LOGOUT_CONFIRM_MODAL) {
      // Doesn't work properly without delay
      setTimeout(() => {
        this.confirmRef.focus();
      }, 100);
    }
  }

  _clickOutside = e => {
    if (e.target === e.currentTarget) {
      this.props.closeModal();
    }
  };

  _doLogout = () => {
    document.documentElement.setAttribute("theme", "light");
    this.props.logout();
  };

  render() {
    return (
      <div
        className={
          this.props.openModal === modalCodes.LOGOUT_CONFIRM_MODAL ? "modalWrapper open" : "modalWrapper"
        }
        onMouseDown={e => this._clickOutside(e)}
      >
        <div className="modalContent confirmLogoout">
          <h2>Sind Sie sicher, dass Sie sich abmelden möchten?</h2>
          <div className="close" onClick={() => this.props.closeModal()}>
            <MaterialIcons name="close" size={18} />
          </div>
          <div className="actionItems material">
            <button ref={ref => this.confirmRef = ref} className="primary" onClick={this._doLogout}>
              Ja, abmelden
            </button>
            <button className="primary delete" onClick={() => this.props.closeModal()}>
              Nein, abbrechen
            </button>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  openModal: state.navigation.openModal,
  modalData: state.navigation.modalData
});

const mapDispatchToProps = dispatch => ({
  closeModal: () => dispatch(closeModal()),
  logout: () => dispatch(logout())
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LogoutConfirmModal);
