import React from 'react';

export const WorkspaceSetting = () => {
  return (
    <div className="settingContent material">
      <h5>Deine Workspaces</h5>
      <div className="separator" />

      <h6>Workspaces sind derzeit noch nicht verfügbar.</h6>

    </div>
  );
};
