import { put, takeEvery, call } from "redux-saga/effects";
import { loginRequest, registerRequest, resetPasswordRequest, requestResetPasswordRequest } from "./../api/authentication";
import { statusCodes } from "./../api/config";
import { setResetPasswordError } from "../actions/authentication";

export function* watchAllAuthenticationActions() {
  yield takeEvery("LOGIN_FETCH_REQUESTED", beginLogin);
  yield takeEvery("REGISTER_FETCH_REQUESTED", beginRegister);
  yield takeEvery("REQUEST_RESET_PASSWORD_REQUESTED", beginRequestResetPassword);
  yield takeEvery("RESET_PASSWORD_REQUESTED", beginResetPassword);
}

export function* beginLogin(action) {
  try {
    const result = yield call(loginRequest, action.metadata, action.loginData);
    if (result.statusCode === statusCodes.SUCCESS) {
      yield put({
        type: "LOGIN_SUCCEEDED",
        userData: {
          email: action.loginData.email,
          token: result.token,
          workspaceId: result.workspaceId
        }
      });
      action.metadata.history.push('timeline');
    } else {
      yield put({
        type: "LOGIN_FAILED",
        errorMsg: result.errorMsg,
        userData: {
          email: action.loginData.email
        }
      });
    }
  } catch (e) {
    yield put({
      type: "LOGIN_FAILED",
      errorMsg: e.message,
      userData: { email: action.loginData.email }
    });
  }
}

export function* beginRegister(action) {
  try {
    const result = yield call(
      registerRequest,
      action.metadata,
      action.registerData
    );
    if (result.statusCode === statusCodes.SUCCESS) {
      yield put({
        type: "REGISTER_SUCCEEDED",
        errorMsg: result.errorMsg,
        userData: {
          firstName: action.registerData.firstName,
          lastName: action.registerData.lastName,
          email: action.registerData.email,
          token: result.token,
          workspaceId: result.workspaceId
        }
      });
      action.metadata.history.push('timeline');
    } else {
      yield put({
        type: "REGISTER_FAILED",
        errorMsg: result.errorMsg,
        userData: {
          firstName: action.registerData.firstName,
          lastName: action.registerData.lastName,
          email: action.registerData.email
        }
      });
    }
  } catch (e) {
    yield put({
      type: "REGISTER_FAILED",
      errorMsg: e.message,
      userData: {
        firstName: action.registerData.firstName,
        lastName: action.registerData.lastName,
        email: action.registerData.email
      }
    });
  }
}

export function* beginRequestResetPassword(action) {
  try {
    yield put(setResetPasswordError(null));
    const result = yield call(
      requestResetPasswordRequest,
      action.email
    );
    if (result.statusCode === statusCodes.SUCCESS) {
      action.onSuccess(true);
      yield put(setResetPasswordError(null));
    } else {
      yield put(setResetPasswordError(result.errorMsg));
    }
  } catch (e) {
    yield put(setResetPasswordError(e.message));
  }
}

export function* beginResetPassword(action) {
  try {
    yield put(setResetPasswordError(null));
    const result = yield call(
      resetPasswordRequest,
      action.resetToken,
      action.newPassword,
      action.newPasswordConfirmation
    );
    if (result.statusCode === statusCodes.SUCCESS) {
      action.onSuccess(true);
      yield put(setResetPasswordError(null));
    } else {
      yield put(setResetPasswordError(result.errorMsg));
    }
  } catch (e) {
    yield put(setResetPasswordError(e.message));
  }
}