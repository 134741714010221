import "./TagArea.scss";
import React from 'react';
import { useSelector } from 'react-redux';
import { getReference, ReferenceNames } from "../../utils/reference";

const AvailableTagsArea = () => {
    const tagList = useSelector(state => state.element.tagList);

    const nativeInputValueSetter = Object.getOwnPropertyDescriptor(
        window.HTMLInputElement.prototype,
        "value"
    ).set;

    const _filterByTag = (tagName) => {
        // Return if there is no searchbar e.g. in list view
        const searchbar = getReference(ReferenceNames.SEARCHBAR_INPUT);
        if (!searchbar) return;
    
        // Set searchbar value (react overrides regular input value setter)
        nativeInputValueSetter.call(
          searchbar,
          `#${tagName}`
        );
        let ev2 = new Event("input", { bubbles: true });
        searchbar.dispatchEvent(ev2);
      };
    
    const tagListElements = tagList.filter(x => x.tagcount > 0)
    .map(x => (
        <div className={"tagItem"}
            onClick={() => _filterByTag(x.tagname)}
            key={Math.random()
                .toString(36)
                .substr(2, 12)}>
            <span className={"tagName"}>{x.tagname}</span>
            <div className={"tagCountContainer"}>
                <span className={"tagCount"}>{x.tagcount}</span>
            </div>
        </div>
    ))

    return (
        <div className={"tagArea scrollable"}>
            {tagListElements}
        </div>
    )
};

export  default AvailableTagsArea;