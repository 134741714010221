import { baseUrl } from "./config";

export const retrieveQueryListRequest = metadata => {
    return new Promise((resolve, reject) => {
        fetch(`${baseUrl}/get-queries`, {
            method: 'post',
            headers: {
                'Content-Type':'application/json',
                'Accept': 'application/json',
                'Authorization': `Bearer ${metadata.token}`
            }, body: JSON.stringify({
                workspaceId: metadata.workspaceId,
                // language: metadata.language
            })
        }).then(res => {
            res.json().then(rj => {
                // console.log(rj)
                resolve(rj);
            })
        }).catch(err => {
            reject(err);
        });
    });
}

export const addQueryRequest = (metadata, queryData) => {
    return new Promise((resolve, reject) => {
        fetch(`${baseUrl}/add-query`, {
            method: 'post',
            headers: {
                'Content-Type':'application/json',
                'Accept': 'application/json',
                'Authorization': `Bearer ${metadata.token}`
            }, body: JSON.stringify({
                workspaceId: metadata.workspaceId,
                emoji: queryData.emoji,
                name: queryData.name,
                query: queryData.query
                // language: metadata.language
            })
        }).then(res => {
            res.json().then(rj => {
                // console.log(rj)
                resolve(rj);
            })
        }).catch(err => {
            reject(err);
        });
    });
}

export const editQueryRequest = (metadata, queryData) => {
    return new Promise((resolve, reject) => {
        fetch(`${baseUrl}/edit-query`, {
            method: 'post',
            headers: {
                'Content-Type':'application/json',
                'Accept': 'application/json',
                'Authorization': `Bearer ${metadata.token}`
            }, body: JSON.stringify({
                queryId: queryData.queryId,
                emoji: queryData.emoji,
                name: queryData.name,
                query: queryData.query
                // language: metadata.language
            })
        }).then(res => {
            res.json().then(rj => {
                // console.log(rj)
                resolve(rj);
            })
        }).catch(err => {
            reject(err);
        });
    });
}

export const removeQueryRequest = (metadata, queryId) => {
    return new Promise((resolve, reject) => {
        fetch(`${baseUrl}/remove-query`, {
            method: 'post',
            headers: {
                'Content-Type':'application/json',
                'Accept': 'application/json',
                'Authorization': `Bearer ${metadata.token}`
            }, body: JSON.stringify({
                queryId: queryId
                // language: metadata.language
            })
        }).then(res => {
            res.json().then(rj => {
                // console.log(rj)
                resolve(rj);
            })
        }).catch(err => {
            reject(err);
        });
    });
}