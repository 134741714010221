import { useEffect } from "react";
import { getReference, ReferenceNames } from "../../utils/reference";
import { useDispatch, useSelector } from "react-redux";
import { unselectAllElements } from "../../actions/element";
import { openModal, closeModal } from "../../actions/navigation";
import { modalCodes } from "../../utils/enums";
import { useHistory, useLocation } from "react-router-dom";
import { isCtrlKeyActive } from "../../utils/other";

let globalInitialTour = false;
let globalOpenModalId = -1;
let globalEditingElement = null;
let globalSelectedElements = [];
let globalActiveBoardId = null;
let globalLocation = null;

export const ShortcutHandler = ({ initialTour }) => {

  const dispatch = useDispatch();
  const history = useHistory();
  const openModalId = useSelector(state => state.navigation.openModal);
  const editingElement = useSelector(state => state.element.editingElement);
  const selectedElements = useSelector(state => state.element.selectedElements);
  const activeBoardId = useSelector(state => state.board.activeBoardId);
  const location = useLocation();
  const ignoreKeys = ["enter", "escape"];

  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  function handleKeyDown(e) {
    // Check for key because chrome autofill triggers keydown and doesn't provide a key
    if (globalInitialTour || !e.key) return;

    if (
      (e.target.tagName.toUpperCase() === 'INPUT' ||
      e.target.tagName.toUpperCase() === 'TEXTAREA') &&
      !e.target.hasAttribute('readonly')
    ) {
      if (executeInputShortcut(e)) e.preventDefault();
    } else {
      if (executeOutsideShortcut(e)) {
         e.preventDefault();
      } else {
        if (globalOpenModalId !== -1 || // Don't focus when modal is open
          (e.key.length > 1 && (e.ctrlKey || e.shiftKey || e.metaKey)) || // Don't focus on only ctrl/shift/cmd
          (e.key.toLowerCase() === 'c' && (e.ctrlKey || e.metaKey)) || // Don't focus when user copies something
          ignoreKeys.indexOf(e.key.toLowerCase()) !== -1 ) return; // Don't focus on special keys e.g. escape

        const addElementInput = getReference(ReferenceNames.ADD_ELEMENT_INPUT);
        if (addElementInput) {
          addElementInput.focus();
          const charCount = addElementInput.value.length;
          addElementInput.setSelectionRange(charCount, charCount);
          
          if (e.key === '#') addElementInput.dispatchEvent(new KeyboardEvent('keydown', { key: '#', bubbles: true}));
        }
      }
    }
  };

  const executeInputShortcut = e => {
    switch (e.key.toLowerCase()) {
      case "escape": {
        // cmd modal should close immediately
        if (globalOpenModalId === modalCodes.CMD_SEARCH_MODAL) {
          dispatch(closeModal());
          return true;
        }

        document.activeElement.blur();
        return true;
      }
      default: {
        return executeGlobalShortcut(e);
      }
    }
  };
  
  const executeOutsideShortcut = e => {
    switch (e.key.toLowerCase()) {
      case "escape": {
        // 1. Close active modal
        if (globalOpenModalId !== -1) {
          dispatch(closeModal());
          return true;
        }
        // 2. Do nothing here but prevent next handler
        //    Logic for this is in element event handler
        if (globalEditingElement) {
          return true;
        }
        // 3. De-select selected elements
        if (globalSelectedElements.length > 0) {
          dispatch(unselectAllElements());
          return true;
        }
        // 4. Go back to board selection if board active
        if (globalActiveBoardId && globalLocation.pathname.split('/')[1] === 'board') {
          history.push("/boards");
          return true;
        }
        return false;
      }
      case "delete": {
        if (globalSelectedElements.length > 0) {
          dispatch(openModal(modalCodes.DELETE_CONFIRM_MODAL, { selectedElements: globalSelectedElements }));
          return true;
        }
        return false;
      }
      default: {
        return executeGlobalShortcut(e);
      }
    }
  };

  const executeGlobalShortcut = e => {
    switch (e.key.toLowerCase()) {
      case "f": {
        if (isCtrlKeyActive(e)) {
          const searchbar = getReference(ReferenceNames.SEARCHBAR_INPUT);
          if (searchbar) searchbar.focus();
          return true;
        }
        return false;
      }
      case "o": {
        if (isCtrlKeyActive(e)) {
          dispatch(openModal(modalCodes.ADD_QUERY_MODAL, { name: "" }));
          return true;
        }
        return false;
      }
      case "h": { // Handled elsewhere but need to be considered here too in order to prevent default
        if (isCtrlKeyActive(e)) return true;
        return false;
      }
      case "k":
        if (isCtrlKeyActive(e)) {
          dispatch(openModal(modalCodes.CMD_SEARCH_MODAL, {}));
          return true;
        }
        return false;
      default: {
        return false;
      }
    }
  };

  useEffect(() => { globalInitialTour = initialTour }, [ initialTour ]);
  useEffect(() => { globalOpenModalId = openModalId }, [ openModalId ]);
  useEffect(() => { globalEditingElement = editingElement }, [ editingElement ]);
  useEffect(() => { globalSelectedElements = selectedElements }, [ selectedElements ]);
  useEffect(() => { globalActiveBoardId = activeBoardId }, [ activeBoardId ]);
  useEffect(() => { globalLocation = location }, [ location ]);

  return null;
}