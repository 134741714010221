import React, { Component } from "react";
import "./BoardView.scss";
import { connect } from "react-redux";
import { retrieveBoardsRequested } from "../actions/board";
import { openModal } from "../actions/navigation";
import { BoardHeader } from "../components/Boards/BoardHeader";
import { BoardContent } from "../components/Boards/BoardContent";
import { withRouter, Redirect } from "react-router-dom";
import { isBoardView } from './../utils/view';

class BoardView extends Component {

  componentDidMount() {
    const { token, workspaceId, retrieveBoardsRequested } = this.props;
    retrieveBoardsRequested({ token: token, workspaceId: workspaceId });
  }

  render() {
    const { boards, location } = this.props;
    
    if (boards) {
      // Do not use Routes here -> triggers double reload
      const subpaths = location.pathname.split("/");
      const isBV = isBoardView(location.pathname);
      this.lastSubPath = isBV ? subpaths[1] : this.lastSubPath;

      switch (this.lastSubPath) {
        case "boards": {
          return (
            <div className="viewContent">
              <BoardHeader />
              <BoardContent boards={boards} />
            </div>
          )
        }
        case "board": {
          this.boardId = isBV ? Number.parseInt(subpaths[2]) : this.boardId;
          if (boards[this.boardId]) {
            return (
              <div className="viewContent">
                <BoardHeader activeBoardId={this.boardId} activeBoard={boards[this.boardId]} />
                <BoardContent activeBoardId={this.boardId} boards={boards} />
              </div>
            )
          } else {
            return <Redirect to="/404" />
          }
        }
        default: return null;
      }
    } else {
      return (
        <div className="boardView loading">
          <div className="loadingDots">
            <div className="bounce1" />
            <div className="bounce2" />
            <div className="bounce3" />
          </div>
        </div>
      );
    }
  }
}

const mapStateToProps = state => ({
  boards: state.board.boards,
  token: state.authentication.userData.token,
  workspaceId: state.authentication.userData.workspaceId,
  activeBoardId: state.board.activeBoardId
});

const mapDispatchToProps = dispatch => ({
  retrieveBoardsRequested: metadata =>
    dispatch(retrieveBoardsRequested(metadata)),
  openModal: (modalId, modalData) => dispatch(openModal(modalId, modalData))
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(BoardView));
