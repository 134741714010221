import React from 'react';
import { MaterialIcons } from 'react-web-vector-icons';
import { useState } from 'react';
import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { removeRevertableAction } from '../../actions/element';

let globalMouseOver = false;
let globalShown = false;

export const UndoButton = ({ isOffset }) => {
  const [ gracePeriod, setGracePeriod ] = useState(true);
  const [ highlighted, setHighlighted ] = useState(false);
  const [ mouseOver, setMouseOver ] = useState(false);

  const revertableActions = useSelector(state => state.element.revertableActions);
  const shown = globalShown = Object.keys(revertableActions).length > 0;
  const dispatch = useDispatch();

  useEffect(() => {
    if (!shown) return;
    setHighlighted(true);
    setTimeout(() => {
      if (!globalMouseOver) setHighlighted(false);
      setGracePeriod(false);
    }, 3000);

    // Execute intended action after 7s
    setTimeout(() => {
      if (globalShown) {
        Object.keys(revertableActions).forEach(elemId => {
          const action = revertableActions[elemId];
          dispatch({ ...action });
          dispatch(removeRevertableAction(action.elementId));
        });
      }
    }, 7000);
  }, [ shown ]);

  useEffect(() => {
    globalMouseOver = mouseOver;
    if (gracePeriod) return;
    setHighlighted(mouseOver)
  }, [ mouseOver ]);

  return (
    <div
      className={shown ? 'undoFabWrapper shown' : 'undoFabWrapper'}
      style={{ right: isOffset ? 'calc(336px + 32px + 64px)' : 'calc(336px + 32px)' }}
      onMouseEnter={() => setMouseOver(true)}
      onMouseLeave={() => setMouseOver(false)}
      onClick={() => {
        if (!shown) return;
        Object.keys(revertableActions).forEach(elemId => {
          const action = revertableActions[elemId];
          dispatch({ type: action.reverseType, ...action.reverseData });
          dispatch(removeRevertableAction(action.elementId));
        });
      }}
    >
      <div className={highlighted ? 'undoLabel highlighted' : 'undoLabel'}>
        <span>Rückgängig machen</span>
      </div>
      <div className="cover" />
      <div
        className={highlighted ? 'undoFab highlighted' : 'undoFab'}
      >
        <MaterialIcons name="undo" size={24} />
      </div>
    </div>
  );
};
