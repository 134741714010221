// Limitations for free users
export const maxFreeQueries = 50; // JUST TEMPORARY WHILE PLUS NOT FULLY IMPLEMENTED - SET TO 7 AGAIN AFTERWARDS
export const maxFreeUploadSize = 10485760; // 10 MB
export const maxFreeUploadSizeString = "10 MB";
export const maxFreeTotalUploaded = 524288000; // 500 MB
export const maxFreeTotalUploadedString = "500 MB";

// Limitations for paid users
export const maxPaidUploadSize = 52428800; // 50 MB
export const maxPaidUploadSizeString = "50 MB";
export const maxPaidTotalUploaded = 26214400000; // 25 GB
export const maxPaidTotalUploadedString = "25 GB";

export const upgradeLink = "/";