import { put, takeEvery, call, select } from "redux-saga/effects";
import { genericHandleResult } from "./rootSaga";
import {
  retrieveBoardListRequest,
  addBoardRequest,
  retrieveBoardDetailsRequest,
  addBoardListRequest,
  retrieveListElementsRequest,
  deleteBoardRequest,
  deleteBoardListRequest,
  updateBoardListRequest,
  updateBoardRequest
} from "../api/board";
import { parseFilterFromString } from "../utils/parsing";
import { addBoardElementSucceeded, deleteBoardElementSucceeded, updateBoardElementSucceeded } from "../actions/board";
import { tagsIncludedApplies, tagsExcludedApplies } from '../utils/filter';

export function* watchAllBoardActions() {
  yield takeEvery("RETRIEVE_BOARDS_REQUESTED", beginRetrieveBoards);
  yield takeEvery("ADD_BOARD_REQUESTED", beginAddBoard);
  yield takeEvery("ADD_BOARD_LIST_REQUESTED", beginAddBoardList);
  yield takeEvery("BOARD_DETAILS_REQUESTED", beginRetrieveBoardDetails);
  yield takeEvery(
    "RETRIEVE_LIST_ELEMENTS_REQUESTED",
    beginRetrieveListElements
  );
  yield takeEvery("ADD_BOARD_ELEMENT_DIRECTLY", beginAddBoardElementDirectly);
  yield takeEvery("UPDATE_BOARD_ELEMENT_DIRECTLY", beginUpdateBoardElementDirectly);
  yield takeEvery("DELETE_BOARD_REQUESTED", beginDeleteBoard);
  yield takeEvery("UPDATE_BOARD_REQUESTED", beginUpdateBoard);
  yield takeEvery("DELETE_BOARD_LIST_REQUESTED", beginDeleteBoardList);
  yield takeEvery("UPDATE_BOARD_LIST_REQUESTED", beginUpdateBoardList);
}

export function* beginRetrieveBoards(action) {
  try {
    const result = yield call(retrieveBoardListRequest, action.metadata);

    yield genericHandleResult(
      result,
      {
        type: "RETRIEVE_BOARDS_SUCCEEDED",
        boards: result.boards
      },
      {
        type: "RETRIEVE_BOARDS_FAILED",
        errorMsg: result.errorMsg
      }
    );
  } catch (e) {
    yield put({
      type: "RETRIEVE_BOARDS_FAILED",
      errorMsg: e.message
    });
  }
}

export function* beginAddBoard(action) {
  try {
    const result = yield call(
      addBoardRequest,
      action.metadata,
      action.boardData
    );

    yield genericHandleResult(
      result,
      undefined,
      {
        type: "ADD_BOARD_FAILED",
        errorMsg: result.errorMsg
      },
      [{ type: "CLOSE_MODAL" }]
    );
  } catch (e) {
    yield put({
      type: "ADD_BOARD_FAILED",
      errorMsg: e.message
    });
  }
}

export function* beginAddBoardList(action) {
  try {
    const result = yield call(
      addBoardListRequest,
      action.metadata,
      action.listData
    );

    yield genericHandleResult(
      result,
      undefined,
      {
        type: "ADD_BOARD_LIST_FAILED",
        errorMsg: result.errorMsg
      },
      [{ type: "CLOSE_MODAL" }]
    );
  } catch (e) {
    yield put({
      type: "ADD_BOARD_LIST_FAILED",
      errorMsg: e.message
    });
  }
}

export function* beginRetrieveBoardDetails(action) {
  try {
    const result = yield call(
      retrieveBoardDetailsRequest,
      action.metadata,
      action.boardId
    );

    yield genericHandleResult(
      result,
      {
        type: "RETRIEVE_BOARD_DETAILS_SUCCEEDED",
        lists: result.board.lists
      },
      {
        type: "RETRIEVE_BOARD_DETAILS_FAILED",
        errorMsg: result.errorMsg
      }
    );
  } catch (e) {
    yield put({
      type: "RETRIEVE_BOARD_DETAILS_FAILED",
      errorMsg: e.message
    });
  }
}

export function* beginRetrieveListElements(action) {
  try {
    const result = yield call(retrieveListElementsRequest, action.metadata);

    if (result.elements.length > 0) {
      action.setLastId(result.elements[result.elements.length - 1].id);
    } else {
      action.setLastId(-1); // To update state and let component know the request is completed
    }
    if (result.elements.length < 16) action.bottomReachedEvent();

    yield genericHandleResult(
      result,
      {
        type: "RETRIEVE_LIST_ELEMENTS_SUCCEEDED",
        elements: result.elements,
        listId: action.listId
      },
      {
        type: "RETRIEVE_LIST_ELEMENTS_FAILED",
        errorMsg: result.errorMsg
      }
    );
  } catch (e) {
    yield put({
      type: "RETRIEVE_LIST_ELEMENTS_FAILED",
      errorMsg: e.message
    });
  }
}

const getLists = state => state.board.lists;
export function* beginAddBoardElementDirectly(action) {
  let lists = yield select(getLists);
  if (!lists) return;
  let listIds = [];

  Object.keys(lists).forEach(l => {
    const { tagsIncluded, tagsExcluded } = parseFilterFromString(lists[l].queryString);
    const { element } = action;
    const elementTags = Object.keys(element.tags.data).map(tag => {
      return element.tags.data[tag].tagname.toLowerCase();
    });

    // List filter matches element
    if (
      (tagsIncluded.length === 0) ||
      (tagsIncludedApplies(tagsIncluded, elementTags) &&
        tagsExcludedApplies(tagsExcluded, elementTags))
    ) {
      listIds.push(lists[l].id);
    } 
  });

  yield put(addBoardElementSucceeded(action.element, listIds));
}

export function* beginUpdateBoardElementDirectly(action) {
  const lists = yield select(getLists);
  if (!lists) return;
  let matchingListIds = [];
  let toRemoveListIds = [];

  Object.keys(lists).forEach(l => {
    const { tagsIncluded, tagsExcluded } = parseFilterFromString(lists[l].queryString);
    const { element } = action;
    const elementTags = Object.keys(element.tags.data).map(tag => {
      return element.tags.data[tag].tagname.toLowerCase();
    });

    // List filter matches element
    if (
      (tagsIncluded.length === 0) ||
      (tagsIncludedApplies(tagsIncluded, elementTags) &&
        tagsExcludedApplies(tagsExcluded, elementTags))
    ) {
      matchingListIds.push(lists[l].id);
    } else {
      toRemoveListIds.push(lists[l].id)
    }
  });

  // Remove element from lists that no longer match filter
  if (toRemoveListIds.length > 0) yield put(deleteBoardElementSucceeded(action.element.id, toRemoveListIds));

  // Update element in matching lists
  if (matchingListIds.length > 0) yield put(updateBoardElementSucceeded(action.element, matchingListIds));

  // Reset editing element
  // yield put(editElement(null));
}

export function* beginDeleteBoard(action) {
  try {
    // Sometimes socketio is faster than the response
    // -> already navigate away from the board before deletion
    action.history.push('/boards');

    const result = yield call(
      deleteBoardRequest,
      action.metadata,
      action.boardId
    );

    yield genericHandleResult(
      result,
      undefined,
      {
        type: "UPDATE_BOARD_FAILED",
        errorMsg: result.errorMsg
      }, [{ type: "CLOSE_MODAL" }]
    );
  } catch (e) {
    yield put({
      type: "UPDATE_BOARD_FAILED",
      errorMsg: e.message
    });
  }
}

export function* beginUpdateBoard(action) {
  try {
    const result = yield call(
      updateBoardRequest,
      action.metadata,
      action.boardData
    );

    yield genericHandleResult(
      result,
      undefined,
      {
        type: "UPDATE_BOARD_FAILED",
        errorMsg: result.errorMsg
      }, [{ type: "CLOSE_MODAL" }]
    );
  } catch (e) {
    yield put({
      type: "UPDATE_BOARD_FAILED",
      errorMsg: e.message
    });
  }
}

export function* beginDeleteBoardList(action) {
  try {
    const result = yield call(
      deleteBoardListRequest,
      action.metadata,
      action.listId
    );

    yield genericHandleResult(
      result,
      undefined,
      {
        type: "UPDATE_BOARD_LIST_FAILED",
        errorMsg: result.errorMsg
      }, [{ type: "CLOSE_MODAL" }]
    );
  } catch (e) {
    yield put({
      type: "UPDATE_BOARD_LIST_FAILED",
      errorMsg: e.message
    });
  }
}

export function* beginUpdateBoardList(action) {
  try {
    const result = yield call(
      updateBoardListRequest,
      action.metadata,
      action.listData
    );

    yield genericHandleResult(
      result,
      undefined,
      {
        type: "UPDATE_BOARD_LIST_FAILED",
        errorMsg: result.errorMsg
      }, [{ type: "CLOSE_MODAL" }]
    );
  } catch (e) {
    yield put({
      type: "UPDATE_BOARD_LIST_FAILED",
      errorMsg: e.message
    });
  }
}