const initialState = {
  loggedIn: false,
  errorOccured: false,
  errorMsg: "",
  userData: {
    firstName: "",
    lastName: "",
    email: "",
    token: "",
    workspaceId: -1,
    isPaidUser: false,
    storageUsed: 0 // in bytes
  },
  resetPasswordError: null
};

export const authentication = (state = initialState, action) => {
  switch (action.type) {
    case "SET_RESET_PASSWORD_ERROR": {
      return {
        ...state,
        resetPasswordError: action.val
      }
    }
    case "UPDATE_USER_DATA": {
      return {
        ...state,
        userData: {
          ...state.userData,
          ...action.userData
        }
      }
    }
    case "LOGIN_SUCCEEDED": {
      return {
        ...state,
        loggedIn: true,
        errorOccured: false,
        userData: {
          ...state.userData,
          ...action.userData
        }
      }
    }
    case "LOGIN_FAILED": {
      return {
        ...state,
        loggedIn: false,
        errorOccured: true,
        errorMsg: action.errorMsg,
        userData: {
          ...state.userData,
          ...action.userData
        }
      };
    }
    case "REGISTER_SUCCEEDED": {
      return {
        ...state,
        loggedIn: true,
        errorOccured: false,
        userData: {
          ...state.userData,
          ...action.userData
        }
      };
    }
    case "REGISTER_FAILED": {
      return {
        ...state,
        loggedIn: false,
        errorOccured: true,
        errorMsg: action.errorMsg,
        userData: {
          ...state.userData,
          ...action.userData
        }
      };
    }
    case "RESET_ERROR": {
      return {
        ...state,
        errorOccured: false,
        errorMsg: ""
      }
    }
    default:
      return state;
  }
};
