import React, { Component } from 'react';
import './DefaultView.scss';

import TopBar from './../components/TopBar/TopBar';
import { LeftArea } from './../components/LeftArea/LeftArea';
import { ContentArea } from './../components/ContentArea/ContentArea';
import { RightArea } from '../components/RightArea/RightArea';
import StaticQueryArea from '../components/QueryArea/StaticQueryArea';
import DynamicQueryArea from '../components/QueryArea/DynamicQueryArea';
import ElementList from '../components/ElementList/ElementList';
import FilterArea from '../components/FilterArea/FilterArea';
import InactiveFilterArea from '../components/FilterArea/InactiveFilterArea';
import { withRouter } from 'react-router-dom';
import { AddElementBar } from '../components/AddElementBar/AddElementBar';
import { ShortcutHelp } from '../components/RightArea/ShortcutHelp';
import { getReference, ReferenceNames } from '../utils/reference';
import AvailableTagsArea from '../components/TagArea/AvailableTagsArea';

class DefaultView extends Component {
  constructor(props) {
    super(props);

    this.elementList = React.createRef(); // Ref to class
    props.cacheLifecycles.didRecover(this.componentDidRecover)
  }

  componentDidRecover = () => {
    const scrollView = getReference(ReferenceNames.ELEMENT_SCROLL_VIEW);
    const elementList = getReference(ReferenceNames.ELEMENT_LIST);
    scrollView.scrollTop = elementList.scrollHeight;
    setTimeout(() => {
      scrollView.scrollTop = elementList.scrollHeight;
    }, 5);

    this.elementList.current.doRerender();
  }

  render() {
    return (
      <div className="viewContent">
        <TopBar />
        <div className="contentContainer scrollable">
          <LeftArea>
            <h3>Übersicht</h3>
            <StaticQueryArea />
            <h3>Gespeicherte Suchen</h3>
            <DynamicQueryArea />
          </LeftArea>
          <div className="contentAreaWrapper">
            <ContentArea handleScroll={this._handleScroll}>
              <ElementList
                location={this.props.location}
                ref={this.elementList}
              />
            </ContentArea>
            <AddElementBar history={this.props.history} location={this.props.location} />
          </div>
          <RightArea>
            <h3>Aktive Filter</h3>
            <FilterArea />

            <h3>Verwendete Tags</h3>
            <AvailableTagsArea />

            {/* <h3>Weitere Filter</h3>
            <InactiveFilterArea /> */}
            <ShortcutHelp />
          </RightArea>
        </div>
      </div>
    );
  }

  _handleScroll = e => {
    this.elementList.current.handleScroll(e);
  };
}

export default withRouter(DefaultView);
