import { openModal } from "../actions/navigation";
import { modalCodes } from "./enums";
import { ctrlKeyName } from "./other";
import { getReference, ReferenceNames } from "./reference";

const CMD_SEARCH_ACTIONS = (navParams) => {
    const { history, closeModal, genericDispatch } = navParams;
    return [
    {
        name: "Ungetaggt",
        alternative: [],
        keys: [],
        useTextIcon: true,
        icon: "flip-to-back",
        action: () => {
            history.push("/untagged");
            closeModal();
        },
    },
    {
        name: "Zeitachse",
        alternative: ["Timeline"],
        keys: [],
        useTextIcon: true,
        icon: "trending-up",
        action: () => {
            history.push("/timeline");
            closeModal();
        },
    },
    {
        name: "Boardansicht",
        alternative: [],
        keys: [],
        useTextIcon: true,
        icon: "web",
        action: () => {
            history.push("/boards");
            closeModal();
        },
    },
    {
        name: "Einstellungen",
        alternative: ["Settings"],
        keys: [],
        useTextIcon: true,
        icon: "settings",
        action: () => {
            history.push("/settings");
            closeModal();
        },
    },
    {
        name: "Gespeicherte Suche anlegen",
        alternative: [],
        keys: [ctrlKeyName, "O"],
        useTextIcon: true,
        icon: "filter-none",
        action: () => {
            genericDispatch(openModal(modalCodes.ADD_QUERY_MODAL, { name: "" }));
        },
    },
    {
        name: "Suchen",
        alternative: ["Search"],
        keys: [ctrlKeyName, "F"],
        useTextIcon: true,
        icon: "search",
        action: () => {
            const searchbar = getReference(ReferenceNames.SEARCHBAR_INPUT);
            if (searchbar) searchbar.focus();
            closeModal();
        },
    },
    {
        name: "Aktive Suche löschen",
        alternative: ["Clear search"],
        keys: [],
        useTextIcon: true,
        icon: "delete",
        action: () => {
            const searchbar = getReference(ReferenceNames.SEARCHBAR_INPUT);
            if (!searchbar) return;
            
            const nativeInputValueSetter = Object.getOwnPropertyDescriptor(
                window.HTMLInputElement.prototype,
                "value"
            ).set;
        
            // Set searchbar value (react overrides regular input value setter)
            nativeInputValueSetter.call(
                searchbar,
                ''
              );
              let ev2 = new Event("input", { bubbles: true });
              searchbar.dispatchEvent(ev2);
            closeModal();
        },
    },
]};

// https://github.com/bevacqua/fuzzysearch/blob/master/index.js
function fuzzysearch (needle, haystack, ignoreCaseAndTrim = true) {
    if (ignoreCaseAndTrim) {
        needle = needle.toLowerCase().trim();
        haystack = haystack.toLowerCase().trim();
    }
    
    const hlen = haystack.length;
    const nlen = needle.length;
    if (nlen > hlen) {
      return false;
    }

    if (nlen === hlen) {
      return needle === haystack;
    }

    outer:
    for (var i = 0, j = 0; i < nlen; i++) {
      const nch = needle.charCodeAt(i);
      while (j < hlen) {
        if (haystack.charCodeAt(j++) === nch) {
          continue outer;
        }
      }
      return false;
    }
    return true;
  }


const replaceBoldChars = (inputString) => {
    const boldSplitArray = inputString.split('*');
    let outputString = "";
    for (let i = 0; i < boldSplitArray.length; i += 1) {
        outputString += boldSplitArray[i];
        if (i !== boldSplitArray.length - 1) {
            if (i % 2 === 0) {
                outputString += " <b>";
            } else {
                outputString += "</b> ";
            }
        }
    }
    return outputString;
};

// filter arrays
export const getSearchResults = (searchString, queries = [], navParams) => {
    if (!searchString) return CMD_SEARCH_ACTIONS(navParams);

    const { history, closeModal } = navParams;

    // add queries to results
    // let { id, emoji, name, query } = q;
    const CMD_SEARCH_RESULTS = [
        ...CMD_SEARCH_ACTIONS(navParams),
        ...queries.map(x => (
            {
                name: `Zu *${x.name}* navigieren`,
                alternative: [],
                keys: [],
                icon: x.emoji,
                action: () => { 
                    console.log(`This worked (${x.name})`);
                    history.push("/query/" + x.id);
                    closeModal();
                },
                
            }
        )),
    ];
    
    ;
    

    return CMD_SEARCH_RESULTS.filter(x => {
        const strings = [x.name, ...x.alternative];
        return strings.filter(y => fuzzysearch(searchString, y)).length > 0
    })
    .map(x => ({ ...x, name: replaceBoldChars(x.name) }));
    

    // /\[(.*?)\]/
};

