import React from "react";
import { isDateTimeInPast, parseReadableDateTimeFromString } from "../../../utils/parsing";
import { MaterialIcons } from "react-web-vector-icons";

export const Reminder = ({ id, targetTime, editMode, removeEvent, gotReminder }) => {

  return (
    <div key={id} className={"reminder " + (isDateTimeInPast(targetTime) || gotReminder ? "old" : "")}>
      <span>{parseReadableDateTimeFromString(targetTime)}</span>
      {editMode && <div className="closeIcon" onClick={() => removeEvent(id)}><MaterialIcons name="close" size={17} /></div>}
    </div>
  )
}