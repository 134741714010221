import React, { Component } from "react";
import { MaterialIcons } from "react-web-vector-icons";
import { getReference, ReferenceNames } from "../../../utils/reference";

export class LinkAttachment extends Component {

  componentDidUpdate(prevProps) {
    if (prevProps.loading && !this.props.loading) {
      setTimeout(() => {
        const scrollView = getReference(ReferenceNames.ELEMENT_SCROLL_VIEW);
        const elementList = getReference(ReferenceNames.ELEMENT_LIST);
        if (elementList) scrollView.scrollTop = elementList.scrollHeight;
      }, 15); // Needs timeout for some reason, otherwise elementList is null
    }
  }

  _removeLink = e => {
    e.stopPropagation();
    this.props.removeEvent(this.props.id);
  }

  copyToClipboard = () => {
    const { url } = this.props;
    navigator.clipboard.writeText(url);
  }

  handleActionItemAnim = ref => {
    if (ref) ref.classList.add('focused');
    setTimeout(() => {
      if (ref) ref.classList.remove('focused')
    }, 80);
  }

  openLink = e => {
    if (e.target.tagName.toLowerCase() !== 'i') {
      window.open(this.props.url, '_blank')
    }
  }

  buildFixedOptionsClass = () => {
    const { editMode, title } = this.props;
    let base = "fixed-options ";
    base += editMode ? "visible " : "";
    base += !title ? "withoutTitle " : "";
    return base;
  }

  render() {
    const { title, url, description, faviconUrl, loading, editMode, hideDesc, boardElement } = this.props;
    const urlObj = new URL(url);

    if (loading) {
      return (
        <div className={boardElement ? "attachment boardElem" : "attachment"} onClick={e => this.openLink(e)}>
          <div className="iconContainer">
            <div className="icon"><div className="loadingSpinner"></div></div>
          </div>
          <div className="text">
              <div className="header">
                  <div className="titleCph"></div>
                  <span className="url">{urlObj.hostname}</span>
              </div>
          </div>
          <div className={this.props.editMode === true ? "options visible" : "options" }>
            <div className="actionIcon"><MaterialIcons name="delete" size={20} /></div>
          </div>
        </div>
      );
    } else {
      return (
        <div className={boardElement ? "attachment boardElem" : "attachment"} onClick={e => this.openLink(e)}>
          <div className="iconContainer">
            <div className={!faviconUrl || !title ? "icon empty" : "icon"}>{faviconUrl ? (<img src={faviconUrl} alt="" />) : (<MaterialIcons name="link" size={24} color={"var(--onPrimaryStrong)"} />)} </div>
          </div>
          <div className={title ? "text" : "text empty"}>
              <div className="header">
                  {title ? (<span className="title">{title}</span>) : null}
                  <span className="url">{urlObj.hostname}</span>
              </div>
              {description && !hideDesc ? (
              <div className="content">
                  <span className="preview">{description}</span>
              </div>
              ) : null}
          </div>
          <div className={this.buildFixedOptionsClass()}>
            <div className="actionIcon" onClick={this._removeLink}><MaterialIcons name="delete" size={20} /></div>
          </div>
          {!editMode && 
            <div className={title ? "options" : "options withoutTitle"}>
              <div
                className="actionIcon"
                onClick={() => this.copyToClipboard()}
                ref={ref => this.copyRef = ref}
                onMouseDown={() => this.handleActionItemAnim(this.copyRef)}
              >
                <MaterialIcons name="filter-none" size={16} />
              </div>
            </div>
          }
        </div>
      );
    }
  }
}