import React, { useState } from 'react';
import './Authentication.scss';
import { useDispatch, useSelector } from 'react-redux';
import { resetPasswordRequested, setResetPasswordError } from '../../actions/authentication';
import { useEffect } from 'react';

export const ResetPasswordView = ({ history, location }) => {

  const [ password, setPassword ] = useState('');
  const [ passwordConfirm, setPasswordConfirm ] = useState('');
  const [ success, setSuccess ] = useState(false);
  const dispatch = useDispatch();
  const resetPasswordError = useSelector(state => state.authentication.resetPasswordError);

  let submitButton = null;

  useEffect(() => {
    dispatch(setResetPasswordError(null));
  }, []);

  const paramString = location.search.substring(1).split('&');
  let params = {};
  paramString.forEach(p => { 
    const s = p.split('=');
    params[s[0]] = s[1];
  });

  const resetToken = params.token;
  if (!resetToken) {
    return (
      <div className="centeredWrapper">
        <div className="loginContainer material success">
          <h3>Ungültige Anfrage</h3>
        </div>
      </div>
    );
  }

  if (success) {
    return (
      <div className="centeredWrapper">
        <div className="loginContainer material success">
          <h3>Erfolgreich geändert</h3>
          <button className="primary" onClick={() => history.push("/login")}>Zum Login</button>
        </div>
      </div>
    );
  } else {
    return (
      <div className="centeredWrapper">
        <div className="loginContainer material">
          <h3>Passwort ändern</h3>
          <h5>Bitte wähle ein neues Passwort </h5>
          <div className="inputField">
            <input
              type="password"
              name="password"
              placeholder=" "
              onChange={e => setPassword(e.target.value)}
              onKeyDown={e => {
                if (e.key === 'Enter') submitButton.click();
              }}
            />
            <label placeholder="Passwort" />
          </div>
          <div className="inputField">
            <input
              type="password"
              name="password-confirmation"
              placeholder=" "
              onChange={e => setPasswordConfirm(e.target.value)}
              onKeyDown={e => {
                if (e.key === 'Enter') submitButton.click();
              }}
            />
            <label placeholder="Bestätigen" />
          </div>
          {resetPasswordError && <p className="errorMsg initialFade">{resetPasswordError}</p>}
          <div className="parallelInput actionButtons">
            <div />
            <button
              ref={ref => submitButton = ref}
              className="primary"
              onClick={() => {
                if (password === passwordConfirm) {
                  dispatch(resetPasswordRequested(resetToken, password, passwordConfirm, setSuccess));
                } else {
                  dispatch(setResetPasswordError('Passwörter stimmen nicht überein'));
                }
              }}
            >
              Speichern
            </button>
          </div>
        </div>
      </div>
    );
  }
};