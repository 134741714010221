export const retrieveElements = (metadata, append, setReqPendingDone) => ({
    type: "RETRIEVE_ELEMENTS_REQUESTED",
    metadata: metadata,
    append: append,
    setReqPendingDone
});

export const retrieveUntaggedElements = (metadata, append) => ({
    type: "RETRIEVE_UNTAGGED_ELEMENTS_REQUESTED",
    metadata: metadata,
    append: append
});

export const addElement = (metadata, elemData) => ({
    type: "ADD_ELEMENT_REQUESTED",
    metadata: metadata,
    elemData: elemData
});

export const addElementFailed = (error) => ({
    type: "ADD_ELEMENT_FAILED",
    error
});

export const revertableDeleteElement = (metadata, elementId) => ({
    type: "REVERTABLE_DELETE_ELEMENT_REQUESTED",
    metadata,
    elementId
});

export const deleteElement = (metadata, elementId) => ({
    type: "DELETE_ELEMENT_REQUESTED",
    metadata,
    elementId
});

export const deleteMultipleElements = (metadata, elementIds) => ({
    type: "DELETE_MULTIPLE_ELEMENTS_REQUESTED",
    metadata: metadata,
    elementIds: elementIds
});

export const addElementDirectly = element => ({
    type: "ADD_ELEMENT_DIRECTLY",
    element: element
});

export const addElementSucceeded = element => ({
    type: "ADD_ELEMENT_SUCCEEDED",
    element: element
});



export const deleteElementDirectly = elementId => ({
    type: "DELETE_ELEMENT_DIRECTLY",
    elementId: elementId
});

export const deleteMultipleElementsDirectly = elementIds => ({
    type: "DELETE_MULTIPLE_ELEMENTS_DIRECTLY",
    elementIds: elementIds
});

export const deleteElementSucceeded = elementId => ({
    type: "DELETE_ELEMENT_SUCCEEDED",
    elementId: elementId
});

export const deleteMultipleElementsSucceeded = elementIds => ({
    type: "DELETE_MULTIPLE_ELEMENTS_SUCCEEDED",
    elementIds: elementIds
});

export const resetAddElementError = () => ({
    type: "RESET_ADD_ELEMENT_ERROR"
});

export const getTagList = metadata => ({
    type: "TAG_LIST_REQUESTED",
    metadata: metadata
});

export const setEditElementStatus = editing => ({
    type: "SET_EDIT_ELEMENT_STATUS",
    editing
});

export const updateElementRequested = (metadata, elemData) => ({
    type: "UPDATE_ELEMENT_REQUESTED",
    metadata: metadata,
    elemData: elemData
});

export const updateElementDirectly = element => ({
    type: "UPDATE_ELEMENT_DIRECTLY",
    element: element
});

export const updateElementSucceeded = (element, filterNotMatched) => ({
    type: "UPDATE_ELEMENT_SUCCEEDED",
    // hiddenByFilter: false,
    element,
    filterNotMatched
});

export const socketInsertLinkDirectly = link => ({
    type: "SOCKET_INSERT_LINK_DIRECTLY",
    link: link
});

export const socketInsertLinkSucceeded = link => ({
    type: "SOCKET_INSERT_LINK_SUCCEEDED",
    link: link
});

export const socketLinkDataFailed = link => ({
    type: "SOCKET_LINK_DATA_FAILED",
    link: link
});

export const resetLastId = () => ({
    type: "RESET_LAST_ID"
});



export const selectElement = elementId => ({
    type: "SELECT_ELEMENT",
    elementId
});

export const unselectElement = elementId => ({
    type: "UNSELECT_ELEMENT",
    elementId
});

export const unselectAllElements = () => ({
    type: "UNSELECT_ALL_ELEMENTS"
});

export const addRevertableAction = payload => ({
    type: "ADD_REVERTABLE_ACTION",
    payload
});

export const removeRevertableAction = id => ({
    type: "REMOVE_REVERTABLE_ACTION",
    id
});

export const increaseNewIndicatorAmount = () => ({
    type: "INCREASE_NEW_INDICATOR_AMOUNT"
});

export const resetNewIndicatorAmount = () => ({
    type: "RESET_NEW_INDICATOR_AMOUNT"
});

/* File upload */

export const addFilesForUpload = files => ({
    type: "ADD_FILES_FOR_UPLOAD",
    files: files
});

export const uploadFileRequested = (metadata, file) => ({
    type: "UPLOAD_FILE_REQUESTED",
    metadata: metadata,
    file: file
});

export const uploadFinished = (fileId, remoteFileId) => ({
    type: "UPLOAD_FINISHED",
    fileId: fileId,
    remoteFileId: remoteFileId
});

export const removeUploadedFile = fileId => ({
    type: "REMOVE_UPLOADED_FILE",
    fileId: fileId
});

export const resetUploadedFiles = () => ({
    type: "RESET_UPLOADED_FILES"
});

export const refreshElementReminder = (elemId, reminderId) => ({
    type: "REFRESH_ELEMENT_REMINDER",
    elemId,
    reminderId
});

/* Old hidden by filter stuff */

// export const addElementHiddenByFilter = element => ({
//     type: "ADD_ELEMENT_HIDDEN_BY_FILTER",
//     element: element
// });

// export const deleteElementHiddenByFilterSucceeded = elementId => ({
//     type: "DELETE_ELEMENT_HIDDEN_BY_FILTER_SUCCEEDED",
//     elementId: elementId
// });

// export const deleteMultipleElementsHiddenByFilterSucceeded = elementIds => ({
//     type: "DELETE_MULTIPLE_ELEMENTS_HIDDEN_BY_FILTER_SUCCEEDED",
//     elementIds: elementIds
// });

// export const resetHiddenByFilter = () => ({
//     type: "RESET_HIDDEN_BY_FILTER"
// });

// export const updateElementHiddenByFilterSucceeded = element => ({
//     type: "UPDATE_ELEMENT_SUCCEEDED",
//     hiddenByFilter: true,
//     element: element
// });