import React, { Component } from 'react';
import './AppContainer.scss';
import SideMenu from './../SideMenu/SideMenu';
import { connect } from 'react-redux';
import { Modals } from './../Modals/Modals';
import { getTagList } from './../../actions/element';
import { listenForSocketEvents } from './../../socketio/root';
import MobileSideMenu from './../SideMenu/Mobile/MobileSideMenu';
import Notifications from '../Notifications/Notifications';
import ViewContainer from './ViewContainer';
import Tour from '../Tour/Tour';
import { LoadingScreen } from '../Loading/LoadingScreen';
import { requestUserInfo, setFileDragOver } from '../../actions/navigation';
import RouterUpdater from '../Routing/RouterUpdater';
import { ShortcutHandler } from './ShortcutHandler';
import { DropZone } from './DropZone';

class AppContainer extends Component {
  constructor(props) {
    super(props);

    this.mobileLimit = 768;
    this.state = { targetDevice: 'desktop' };

    props.requestUserInfo({
      token: props.token
    });
  }

  componentDidMount() {
    let { token, workspaceId, language } = this.props;

    // Get tag-list from backend
    this.props.getTagList({
      token: token,
      workspaceId: workspaceId,
      language: language
    });

    // Initialize SocketIO and listen for incoming updates
    listenForSocketEvents(
      {
        token: token,
        workspaceId: workspaceId,
        language: language
      },
      this.props.genericDispatch,
      document
    );

    // Viewport width
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
  }

  updateWindowDimensions = () => {
    let newTargetDevice = window.innerWidth < this.mobileLimit ? 'mobile' : 'desktop';

    if (newTargetDevice !== this.state.targetDevice) {
      this.setState({ ...this.state, targetDevice: newTargetDevice });
    }
  };

  handleFilePaste = e => {
    // Direct image data from clipboard
    // TODO
    // const items = (e.clipboardData || e.originalEvent.clipboardData).items;
    // console.log(JSON.stringify(items)); // will give you the mime types
    // for (let index in items) {
    //   var item = items[index];
    //   if (item.kind === 'file') {
    //     var blob = item.getAsFile();
    //     var reader = new FileReader();
    //     reader.onload = (e) => {
    //       console.log(e.target.result)}; // data url!
    //     reader.readAsDataURL(blob);
    //   }
    // }
  };

  containsFiles = e => {
    if (e.dataTransfer.types) {
      for (let i = 0; i < e.dataTransfer.types.length; i++) {
        if (e.dataTransfer.types[i] === 'Files') {
          return true;
        }
      }
    }
    return false;
  };

  render() {
    let VisibleSideMenu = () => {
      return this.state.targetDevice === 'mobile' ? <MobileSideMenu /> : <SideMenu />;
    };

    let VisibleModals = () => {
      // return this.state.targetDevice === "mobile" ? null : (
      return <Modals targetDevice={this.state.targetDevice} />;
      // );
    };

    if (this.props.userDataReceived) {
      return (
        <div
          className="appContainer"
          onDragEnter={e => this.containsFiles(e) && this.props.setFileDragOver(true)}
          onPaste={this.handleFilePaste}
        >
          <VisibleSideMenu />
          <ViewContainer targetDevice={this.state.targetDevice} />
          <VisibleModals />
          <Notifications />
          <RouterUpdater />
          {this.props.initialTour && this.state.targetDevice === 'desktop' ? <Tour /> : null}
          <ShortcutHandler initialTour={this.props.initialTour} />
          <DropZone />
        </div>
      );
    } else {
      return <LoadingScreen />;
    }
  }
}

const mapStateToProps = state => ({
  token: state.authentication.userData.token,
  workspaceId: state.authentication.userData.workspaceId,
  language: state.preferences.language,
  initialTour: state.navigation.initialTour,
  userDataReceived: state.navigation.userDataReceived
});

const mapDispatchToProps = dispatch => ({
  getTagList: metadata => dispatch(getTagList(metadata)),
  genericDispatch: fct => dispatch(fct),
  requestUserInfo: metadata => dispatch(requestUserInfo(metadata)),
  setFileDragOver: dragOver => dispatch(setFileDragOver(dragOver))
});

export default connect(mapStateToProps, mapDispatchToProps)(AppContainer);
