import React, { Component } from 'react';
import { connect } from 'react-redux';
import { closeModal } from './../../actions/navigation';
import { addQuery, addQueryRequested, resetAddQueryError } from './../../actions/query';
import { SimpleInput } from '../SimpleInput/SimpleInput';
import { SearchHelp } from './../HelpDialogs/SearchHelp';
import { LoadingBar } from '../Loading/LoadingBar';
import 'emoji-mart/css/emoji-mart.css';
import { Picker } from 'emoji-mart';
import { MaterialIcons } from 'react-web-vector-icons';
import { modalCodes } from '../../utils/enums';
import { maxFreeQueries, upgradeLink } from '../../utils/limitations';

class AddQueryModal extends Component {
  constructor(props) {
    super(props);

    this.emoji = React.createRef();
    this.name = React.createRef();
    this.addInput = React.createRef();
    this.initialFocus = true;

    this.state = {
      loadingBarShown: false,
      query: '',
      emoji: '❓',
      emojiPickerShown: false
    };
  }

  shouldComponentUpdate(nextProps) {
    // Only re-render if this modal is being opened or is already open and openModal changed
    return nextProps.openModal === modalCodes.ADD_QUERY_MODAL || this.props.openModal === modalCodes.ADD_QUERY_MODAL;
  }

  static getDerivedStateFromProps(props, state) {
    let modalClosed = props.openModal && props.openModal !== modalCodes.ADD_QUERY_MODAL;
    // Reset error when modal is closed
    if (modalClosed && props.addError !== null) props.resetAddQueryError();

    // Hide loading bar when modal closed or error occured
    if (modalClosed) {
      return {
        ...state,
        loadingBarShown: false,
        query: '',
        emoji: '❓'
      };
    }
    // Do not reset query when error visible
    if (props.addError !== null) {
      return {
        ...state,
        loadingBarShown: false
      };
    }
    return null;
  }

  componentDidUpdate() {
    if (this.props.openModal !== modalCodes.ADD_QUERY_MODAL) {
      this.initialFocus = true;
    }
  }

  _clickOutside = e => {
    if (e.target === e.currentTarget) {
      this.props.closeModal();
    }
  };

  _setLoadingBarShown = s => {
    this.setState({ ...this.state, loadingBarShown: s });
  };

  _handleKeyDownNameInput = e => {
    if (e.key === 'Enter') {
      this._manualSubmitForm();
    }
  };

  _queryChanged = query => {
    this.setState({ query: query });
  };

  _manualSubmitForm = () => {
    this._submitForm(this.state.query);
  };

  _submitForm = plainContent => {
    this._setLoadingBarShown(true);
    // this.props.addQuery(this.name.current.value, "");

    this.props.addQueryRequested(
      {
        token: this.props.token,
        workspaceId: this.props.workspaceId,
        language: this.props.language
      },
      {
        emoji: this.state.emoji,
        name: this.name.current.value.slice(0),
        query: plainContent
      }
    );
  };

  _showEmojiPicker = () => {
    this.setState({
      ...this.state,
      emojiPickerShown: true
    });
  };

  _hideEmojiPicker = e => {
    if (e.relatedTarget) {
      // Wait until clicked element received focus
      setTimeout(() => {
        let active = document.activeElement.getAttribute('id');
        if (this.state.emojiPickerShown && (!active || !active.startsWith('emoji-mart-search'))) {
          // Related target but not searchbar clicked -> focus input
          this.emoji.current.focus();
        } else {
          // Searchbar clicked, add focusout event
          document.activeElement.addEventListener('focusout', e => {
            if (!e.relatedTarget) this.setState({ ...this.state, emojiPickerShown: false });
          });
        }
      }, 30);
    } else {
      this.setState({ ...this.state, emojiPickerShown: false });
    }
  };

  _onSelectEmoji = emoji => {
    // this.emoji.current.firstChild.textContent = emoji.native;
    this.emoji.current.blur();
    this.setState({ ...this.state, emoji: emoji.native, emojiPickerShown: false });
  };

  render() {
    const { queries, isPaidUser, openModal, modalData, addError } = this.props;
    if (openModal === modalCodes.ADD_QUERY_MODAL && this.initialFocus) {
      this.initialFocus = false;
      this.name.current.value = modalData.name;
      setTimeout(() => {
        if (this.name.current) this.name.current.focus();
      }, 150);
    } else {
      if (openModal !== modalCodes.ADD_QUERY_MODAL) {
        // Remove focus from inputs after close
        setTimeout(() => {
          if (this.addInput.current) this.addInput.current.blur();
          if (this.name.current) this.name.current.blur();
        }, 150);
      }
    }

    const limitReached = queries && !isPaidUser && queries.length >= maxFreeQueries;
    return (
      <div
        className={
          openModal === modalCodes.ADD_QUERY_MODAL ? 'modalWrapper scrollable open' : 'modalWrapper scrollable'
        }
        onMouseDown={e => this._clickOutside(e)}
      >
        <div className="modalContent">
          <LoadingBar visible={this.state.loadingBarShown} />
          <h2>Neue Suche anlegen</h2>
          <div className="close" onClick={() => this.props.closeModal()}>
            <MaterialIcons name="close" size={18} />
          </div>
          <div className="addQuery">
            {limitReached && (
              <div className="limitReachedMessage">
                <span>
                  Hinweis: Du hast die maximale Anzahl an Suchabfragen erreicht. Benötigst du noch mehr?{' '}
                  <a href={upgradeLink}>Jetzt zu Tagstack Plus upgraden!</a>
                </span>
              </div>
            )}
            <div className="inputFields">
              <div className="labelFields">
                <div className="emoji">
                  <span>Emoji:</span>
                </div>
                <div className="name">
                  <span>Name der Suche:</span>
                </div>
              </div>
              <div className="dataFields">
                <div className="emoji material">
                  <button
                    className="primary emoji"
                    ref={this.emoji}
                    onClick={this._showEmojiPicker}
                    onBlur={this._hideEmojiPicker}
                  >
                    <span role="img" aria-label="emoji">
                      {this.state.emoji}
                    </span>
                  </button>
                  {this.state.emojiPickerShown ? (
                    <Picker
                      native={true}
                      // style={
                      //   this.state.emojiPickerShown
                      //     ? { visibility: "visible" }
                      //     : { visibility: "hidden" }
                      // }
                      // set="apple"
                      title="Wähle ein Emoji…"
                      emoji="point_up"
                      sheetSize={32}
                      showPreview={false}
                      onSelect={this._onSelectEmoji}
                    />
                  ) : null}
                </div>
                <div className="name">
                  <input
                    type="text"
                    className="nameInput"
                    ref={this.name}
                    spellCheck={false}
                    onKeyDown={this._handleKeyDownNameInput}
                  />
                </div>
              </div>
            </div>
            <div className="valueField">
              <span>Suchfilter: </span>
              <SimpleInput
                placeholder="Nach Titel oder #tags filtern"
                showHelpIcon={true}
                helpComponent={SearchHelp}
                resetToInitial={openModal === modalCodes.ADD_QUERY_MODAL ? false : true}
                loadingFunction={this._setLoadingBarShown}
                returnPlainContent={true}
                plainUpdateEvent={this._queryChanged}
                submitEvent={this._submitForm}
                inputRef={this.addInput} // Mandatory now
                returnFilter={true}
              />
            </div>
            {addError === null ? null : <p className="errorMsg">{addError}</p>}
            <div className="actionItems material">
              <button className="primary" onClick={this._manualSubmitForm}>
                Erstellen
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  openModal: state.navigation.openModal,
  modalData: state.navigation.modalData,
  token: state.authentication.userData.token,
  workspaceId: state.authentication.userData.workspaceId,
  addError: state.query.addError,
  language: state.preferences.language,
  queries: state.query.queries,
  isPaidUser: state.authentication.userData.isPaidUser
});

const mapDispatchToProps = dispatch => ({
  closeModal: () => dispatch(closeModal()),
  addQuery: (name, value) => dispatch(addQuery(name, value)),
  addQueryRequested: (metadata, queryData) => dispatch(addQueryRequested(metadata, queryData)),
  resetAddQueryError: () => dispatch(resetAddQueryError())
});

export default connect(mapStateToProps, mapDispatchToProps)(AddQueryModal);
