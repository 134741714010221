import React, { useEffect, useRef } from "react";
import { useDrag } from "react-dnd";
import { ItemTypes } from "../../utils/types";
import { getEmptyImage } from "react-dnd-html5-backend";
import { useDispatch, useSelector } from 'react-redux'
import { updateElementRequested } from "../../actions/element";
import { localMoveBoardElement } from "../../actions/board";

function getStyles(isDragging) {
  return {
    opacity: isDragging ? 0 : 1, // This somehow causes a few pixels of margin
    // height: isDragging || sent ? 0 : ""
    // display: isDragging || sent ? "none" : "block"
  };
}

export function DraggableElement(props) {
  const dispatch = useDispatch();
  const token = useSelector(state => state.authentication.userData.token);
  const language = useSelector(state => state.preferences.language);
  const workspaceId = useSelector(state => state.authentication.userData.workspaceId);
  const editingElement = useSelector(state => state.element.editingElement);

  const dragHeightRef = useRef(null);
  const [{ isDragging }, drag, preview] = useDrag({
    item: {
      type: ItemTypes.ELEMENT,
      element: props.children,
      dragItemCreatedAt: props.dragItemCreatedAt,
      dragHeightRef: dragHeightRef,
      originTags: props.originTags,
      elemData: props.elemData,
      dragElemListId: props.dragElemListId,
    },
    collect: monitor => ({
      item: monitor.getItem(),
      isDragging: !!monitor.isDragging(),
      didDrop: !!monitor.didDrop(),
      dropResult: monitor.getDropResult()
    }),
    canDrag: editingElement ? false : true,
    end: (item, monitor) => {
      const didDrop = monitor.didDrop();
      const dropResult = monitor.getDropResult();
      
      if (didDrop && dropResult && dropResult.elemId === props.elemData.id) {
        // Check if dropped in same list
        const sot = props.originTags.sort();
        const stt = dropResult.targetTags.sort();
        let equal = sot.length === stt.length;

        // Compare tags individually since direct array comparison doesn't work
        sot.forEach((t, i) => {
          if (!equal) return;
          if (t !== stt[i]) {
            equal = false;
          }
        });

        // If dropped in different list
        if (!equal)  {
          // Filter out tags from old list
          const lcOriginTags = props.originTags.map(t => t.toLowerCase());
          const leftoverTags = Object.keys(item.elemData.tags.data)
            .filter(e => lcOriginTags.indexOf(item.elemData.tags.data[e].tagname.toLowerCase()) === -1)
            .map(eid => item.elemData.tags.data[eid].tagname);
          const oldLinks = Object.keys(props.elemData.links.data).map(l => props.elemData.links.data[l].url);
          const oldFiles = Object.keys(props.elemData.files.data).map(f => props.elemData.files.data[f].id);
          
          const finalData = {
            elementId: props.elemData.id,
            title: props.elemData.title,
            tags: [...leftoverTags, ...dropResult.targetTags],
            links: oldLinks,
            files: oldFiles
          };
      
          // For instant visual drop feedback and prevention of "back-flicker"
          const tempTags = {};
          finalData.tags.forEach((tag, i) => {
            tempTags[i] = { id: i, tagname: tag }
          });
          dispatch(localMoveBoardElement(dropResult.elemId, tempTags, props.dragElemListId, dropResult.targetListId));

          dispatch(updateElementRequested({
            token: token,
            language: language,
            workspaceId: workspaceId
          }, finalData));
        }
      }
    }
  });

  useEffect(() => {
    preview(getEmptyImage(), { captureDraggingState: true });
  }, []);

  const childrenWithProps = React.Children.map(props.children, child =>
    React.cloneElement(child, { dragHeightRef: dragHeightRef })
  );

  return (
    <div ref={drag} style={getStyles(isDragging)}>
      {childrenWithProps}
    </div>
  );
}
