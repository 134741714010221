export const tagsIncludedApplies = (tagsIncluded, elementTags) => {
  const leftoverTags = tagsIncluded.filter(el => {
    return !elementTags.includes(el.toLowerCase());
  });
  return leftoverTags.length === 0 ? true : false;
};

export const tagsExcludedApplies = (tagsExcluded, elementTags) => {
  const leftoverTags = tagsExcluded.filter(el => {
    return elementTags.includes(el.toLowerCase());
  });
  return leftoverTags.length === 0 ? true : false;
};
