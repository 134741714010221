import React, { Component } from "react";
import "./App.scss";
import { connect } from 'react-redux'
import AppContainer from "./components/AppContainer/AppContainer";
import LoginView from "./components/Authentication/LoginView";
import RegisterView from "./components/Authentication/RegisterView";
import { BrowserRouter as Router, Route, Redirect } from "react-router-dom";
import ProtectedRoute from "./components/Authentication/ProtectedRoute";
import { NotFound } from "./sites/NotFound";
import { ResetPasswordView } from "./components/Authentication/ResetPasswordView";
import { ResetPasswordRequestView } from "./components/Authentication/ResetPasswordRequestView";
import { CacheSwitch } from 'react-router-cache-route'

class App extends Component {
  render() {
    document.title = "Tagstack";
    return (
      <Router>
        <CacheSwitch>
          <Route exact path="/" render={() => (<Redirect to="/timeline" />)} />

          <Route path="/login" component={LoginView} />
          <Route path="/register" component={RegisterView} />

          <Route path="/reset-password-request" component={ResetPasswordRequestView} />
          <Route path="/reset-password" component={ResetPasswordView} />

          <ProtectedRoute path={["/timeline", "/query/:id", "/untagged", "/boards", "/board/:id", "/insights", "/settings"]} component={AppContainer} />

          <Route exact path="/404" component={NotFound} />
          <Redirect to="/404" />
        </CacheSwitch>
      </Router>
    );
  }
}

const mapStateToProps = state => ({
  authentication: state.authentication
});

export default connect(mapStateToProps)(App);