import React, { Component } from "react";
import { MaterialIcons } from "react-web-vector-icons";

export class InactiveFilterItem extends Component {
    render() {
        return (
            <div className="inactiveFilterItem" onClick={this.props.doOnClick}>
                <MaterialIcons name="add" size={23} />
                <span className="title">{this.props.title}</span>
            </div>
        )
    }
}