export const retrieveBoardsRequested = metadata => ({
    type: "RETRIEVE_BOARDS_REQUESTED",
    metadata: metadata
});

export const addBoardRequested = (metadata, boardData) => ({
    type: "ADD_BOARD_REQUESTED",
    metadata: metadata,
    boardData: boardData
});

export const addBoardDirectly = boardData => ({
    type: "ADD_BOARD_DIRECTLY",
    boardData: boardData
});

export const resetAddBoardError = () => ({
    type: "RESET_ADD_BOARD_ERROR"
});

export const resetUpdateBoardError = () => ({
    type: "RESET_UPDATE_BOARD_ERROR"
});

export const resetAddBoardListError = () => ({
    type: "RESET_ADD_BOARD_LIST_ERROR"
});

export const resetUpdateBoardListError = () => ({
    type: "RESET_UPDATE_BOARD_LIST_ERROR"
});

export const setActiveBoard = boardId => ({
    type: "SET_ACTIVE_BOARD",
    boardId: boardId
});

export const boardDetailsRequested = (metadata, boardId) => ({
    type: "BOARD_DETAILS_REQUESTED",
    metadata: metadata,
    boardId: boardId
});

export const addBoardListRequested = (metadata, listData) => ({
    type: "ADD_BOARD_LIST_REQUESTED",
    metadata: metadata,
    listData: listData
});

export const addBoardListDirectly = list => ({
    type: "ADD_BOARD_LIST_DIRECTLY",
    list: list
});

export const addBoardListFailed = errorMsg => ({
    type: "ADD_BOARD_LIST_FAILED",
    errorMsg: errorMsg
});

export const retrieveListElementsRequested = (metadata, listId, bottomReachedEvent, setLastId) => ({
    type: "RETRIEVE_LIST_ELEMENTS_REQUESTED",
    metadata: metadata,
    listId: listId,
    bottomReachedEvent,
    setLastId
});

export const deleteBoardRequested = (metadata, boardId, history) => ({
    type: "DELETE_BOARD_REQUESTED",
    metadata,
    boardId,
    history
});

export const deleteBoardSucceeded = boardId => ({
    type: "DELETE_BOARD_SUCCEEDED",
    boardId: boardId
});

export const updateBoardRequested = (metadata, boardData) => ({
    type: "UPDATE_BOARD_REQUESTED",
    metadata: metadata,
    boardData: boardData
});

export const updateBoardSucceeded = boardData => ({
    type: "UPDATE_BOARD_SUCCEEDED",
    boardData: boardData 
});

export const deleteBoardListRequested = (metadata, listId) => ({
    type: "DELETE_BOARD_LIST_REQUESTED",
    metadata: metadata,
    listId: listId
});

export const deleteBoardListSucceeded = listId => ({
    type: "DELETE_BOARD_LIST_SUCCEEDED",
    listId: listId
});

export const updateBoardListRequested = (metadata, listData) => ({
    type: "UPDATE_BOARD_LIST_REQUESTED",
    metadata: metadata,
    listData: listData
});

export const updateBoardListSucceeded = list => ({
    type: "UPDATE_BOARD_LIST_SUCCEEDED",
    list: list
});

export const resetBoardListElements = listId => ({
    type: "RESET_BOARD_LIST_ELEMENTS",
    listId: listId
});

/* Element updates */

export const addBoardElementDirectly = element => ({
    type: "ADD_BOARD_ELEMENT_DIRECTLY",
    element: element
});

export const addBoardElementSucceeded = (element, listIds) => ({
    type: "ADD_BOARD_ELEMENT_SUCCEEDED",
    element: element,
    listIds: listIds
});

export const updateBoardElementDirectly = element => ({
    type: "UPDATE_BOARD_ELEMENT_DIRECTLY",
    element: element
});

export const updateBoardElementSucceeded = (element, listIds) => ({
    type: "UPDATE_BOARD_ELEMENT_SUCCEEDED",
    element: element,
    listIds: listIds
});

export const deleteBoardElementSucceeded = (elementId, listIds) => ({
    type: "DELETE_BOARD_ELEMENT_SUCCEEDED",
    elementId: elementId,
    listIds: listIds
});

export const deleteMultipleBoardElementsSucceeded = (elementIds) => ({
    type: "DELETE_MULTIPLE_BOARD_ELEMENTS_SUCCEEDED",
    elementIds
});

export const socketInsertBoardLinkSucceeded = link => ({
    type: "SOCKET_INSERT_BOARD_LINK_SUCCEEDED",
    link: link
});

export const localMoveBoardElement = (elementId, tempNewTags, originListId, targetListId) => ({
    type: "LOCAL_MOVE_BOARD_ELEMENT",
    elementId,
    tempNewTags,
    originListId,
    targetListId
})