import React, { Component } from "react";
import "./TopBar.scss";
// import { AdvancedInput } from "../AdvancedInput/AdvancedInput";
import { SearchHelp } from "./../HelpDialogs/SearchHelp";
import { connect } from "react-redux";
import { updateFilterRequested, resetAllFilters } from "./../../actions/filter";
import OfflineWarning from "./OfflineWarning";
import { unselectAllElements } from "./../../actions/element";
import { SimpleInput } from "../SimpleInput/SimpleInput";
import { withRouter, Redirect } from "react-router-dom";
import { openModal } from "../../actions/navigation";
import { modalCodes } from "../../utils/enums";
import { ReferenceNames } from "../../utils/reference";

class TopBar extends Component {
  constructor(props) {
    super(props);

    this.firstClickOnStatic = false;
    this.modifiedActiveQuery = false;
    this.initialSetFromQuery = true;
    this.prevProps = null;
  }

  componentDidUpdate(prevProps) {
    this.prevProps = prevProps;
    this.firstClickOnStatic =
      prevProps.location.pathname !== this.props.location.pathname &&
      (this.props.location.pathname === "/timeline" ||
        this.props.location.pathname === "/untagged")
        ? true
        : false;
  }

  searchBarSubmit = (_metadata, typeData) => {
    this.searchBarUpdate(typeData);
  }

  searchBarUpdate = typeData => {
    // Detect when query was modified and do not treat it as manually typing in searchbar
    let queryStringModified = (this.prevProps && this.prevProps.queries && this.props.queries && 
      this.prevProps.queries !== this.props.queries);

    if (this.prevProps) {
      this.modifiedActiveQuery =
        this.prevProps.location.pathname === this.props.location.pathname &&
        this.props.location.pathname.split('/')[1] === "query" &&
        !this.initialSetFromQuery &&
        !queryStringModified &&
        (this.props.filter.queryString !== typeData.queryString ||
          this.props.filter.tagsIncluded !== typeData.tagsIncluded ||
          this.props.filter.tagsExcluded !== typeData.tagsExcluded)
          ? true
          : false;

      // To allow initially setting the query string in the searchbar
      if (!this.modifiedActiveQuery && this.initialSetFromQuery) this.initialSetFromQuery = false;

      // If active query search-string was modified -> select timeline
      if (this.modifiedActiveQuery)
        this.props.history.push({
          pathname: "/timeline",
          state: { keepInput: true }
        });
    }

    // Recognize reset from click on static query and don't update filters
    if (!this.firstClickOnStatic || this.modifiedActiveQuery) {
      this.props.updateFilterRequested(typeData);
    }
  };

  filterIsEmpty = () => {
    let { filter } = this.props;
    if (filter.queryString) return false;
    if (filter.tagsIncluded.length > 0) return false;
    return true;
  };

  generateSearchStringFromFilter = () => {
    let final = "";
    let { filter } = this.props;
    if (filter.queryString) {
      final += filter.queryString + " ";
    }
    if (filter.tagsIncluded.length > 0) {
      filter.tagsIncluded.forEach(t => {
        final += "#" + t + " ";
      });
    }
    if (filter.tagsExcluded.length > 0) {
      filter.tagsExcluded.forEach(t => {
        final += "!" + t + " ";
      });
    }
    return final;
  };

  _clearAction = () => {
    this.props.resetAllFilters(false);
    this.props.history.push("/timeline");
  };

  render() {
    let titleText = "";
    let path = this.props.location.pathname.split('/');
    switch(path[1]) {
      case "untagged": {
        titleText = "Ungetaggt";
        break;
      }
      case "timeline": {
        titleText = "Zeitachse";
        break;
      }
      case "query": {
        if (this.props.queries) {
          const activeQuery = this.props.queries.filter(q => q.id === Number.parseInt(path[2]))[0];
          if (!activeQuery) return <Redirect to="/404" />

          titleText = activeQuery ? activeQuery.name : "";
        }
        break;
      }
      default: break;  
    }

    let { selectedElements, openModal, unselectAllElements } = this.props;
    const selectedElementsCount = selectedElements.length;

    return (
      <div className="topbar">
        <h2 className="title">{titleText}</h2>
        <SimpleInput
          placeholder="Nach Titel oder #tags filtern"
          centered={true}
          singleLine={true}
          showHelpIcon={true}
          helpComponent={SearchHelp}
          showClearBtn={true}
          clearAction={this._clearAction}
          // forceSetInitialValue={true}
          value={
            this.filterIsEmpty() ? null : this.generateSearchStringFromFilter()
          }
          inputRefName={ReferenceNames.SEARCHBAR_INPUT}
          returnFilter={true}
          updateEvent={this.searchBarUpdate}
          submitEvent={this.searchBarSubmit}
        />
        <OfflineWarning />

        <div className={selectedElementsCount > 0 ? "selectionMenu shown" : "selectionMenu"}>
          <button className="option" onClick={() => openModal(modalCodes.DELETE_CONFIRM_MODAL, { selectedElements: selectedElements })}>
            {selectedElementsCount} Element{selectedElementsCount > 1 ? 'e' : null} löschen
          </button>
          <button className="cancel" onClick={() => unselectAllElements()}>Abbrechen</button>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  lastId: state.element.lastId,
  filter: state.filter,
  language: state.preferences.language,
  queries: state.query.queries,
  selectedElements: state.element.selectedElements
});

const mapDispatchToProps = dispatch => ({
  updateFilterRequested: filter => dispatch(updateFilterRequested(filter)),
  resetAllFilters: triggerRequest => dispatch(resetAllFilters(triggerRequest)),
  unselectAllElements: () => dispatch(unselectAllElements()),
  openModal: (modalId, modalData) => dispatch(openModal(modalId, modalData))
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(TopBar)
);
