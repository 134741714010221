import { baseUrl } from "./config";

export const updateSettingsRequest = (metadata, settings) => {
  return new Promise((resolve, reject) => {
    fetch(`${baseUrl}/change-settings`, {
      method: "post",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${metadata.token}`
      },
      body: JSON.stringify({
        ...settings
      })
    })
      .then(res => {
        res.json().then(rj => {
        //   console.log(rj)
          resolve(rj);
        });
      })
      .catch(err => {
        reject(err);
      });
  });
};
