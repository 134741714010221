const initialState = {
    openModal: -1,
    modalData: null,
    mobileMenuOpen: false,
    activeSettingMenu: 0,
    initialTour: true,
    userDataReceived: false,
    userInfoError: null,
    pathname: null,
    activeTimelineQuery: '/timeline',
    scrollPositionBottom: true, // For elements in timeline
    fileDragOver: false
}

export const navigation = (state = initialState, action) => {
  switch (action.type) {
    case "SET_FILE_DRAG_OVER": {
      return {
        ...state,
        fileDragOver: action.isOver
      }
    }
    case "SET_SCROLL_POSITION_BOTTOM": {
      return {
        ...state,
        scrollPositionBottom: action.isBottom
      }
    }
    case "SET_ACTIVE_TIMELINE_QUERY": {
      return {
        ...state,
        activeTimelineQuery: action.queryPath
      }
    }
    case "UPDATE_URL_PATH": {
      return {
        ...state,
        pathname: action.pathname
      }
    }
    case "GET_USER_INFO_FAILED": {
      return {
        ...state,
        userInfoError: action.errorMsg
      }
    }
    case "SET_INITIAL_ONE_TIME_EVENTS": {
      return {
        ...state,
        initialTour: action.data.finishedTutorial ? false : true,
        userDataReceived: true
      }
    }
    case "FINISH_TOUR": {
      return {
        ...state,
        initialTour: false
      }
    }
    case "SWITCH_SETTING_MENU": {
      return {
        ...state,
        activeSettingMenu: action.id
      }
    }
    case "OPEN_MODAL": {
      return {
        ...state,
        openModal: action.modalId,
        modalData: action.modalData
      }
    }
    case "CLOSE_MODAL": {
      return {
        ...state,
        openModal: -1
      }
    }
    case "TOGGLE_MOBILE_MENU": {
      return {
        ...state,
        mobileMenuOpen: !state.mobileMenuOpen
      }
    }
    default:
      return state;
  }
};