import React from "react";
import "./SideMenu.scss";
import { MaterialIcons } from "react-web-vector-icons";

export const SideMenuItem = ({ icon, tooltip, path, active, switchEvent }) => {

  return (
    <div className={active ? "item active" : "item"} onClick={() => switchEvent(path)}>
      <MaterialIcons name={icon} size={28} />
      <div className="tooltip"><span>{tooltip}</span></div>
    </div>
  )
}