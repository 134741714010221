import React from 'react';
import './ContentArea.scss';
import { FadeGradient } from './FadeGradient';
import { setReference, ReferenceNames } from '../../utils/reference';
import { useRef } from 'react';

export const ContentArea = ({ children, handleScroll }) => {
  const fadeGradientRef = useRef(null);

  return (
    <div
      className="contentArea scrollable"
      ref={ref => setReference(ReferenceNames.ELEMENT_SCROLL_VIEW, ref)}
      onScroll={e => {
        if (fadeGradientRef.current) fadeGradientRef.current.onScroll(e);
        handleScroll(e);
      }}
    >
      <FadeGradient ref={fadeGradientRef} />
      {children}
    </div>
  );
}