import React, { Component } from "react";
import { connect } from "react-redux";
import { closeModal } from "./../../actions/navigation";
import { MaterialIcons } from "react-web-vector-icons";
import { modalCodes } from "../../utils/enums";

class ImageViewer extends Component {
  _clickOutside = e => {
    if (e.target === e.currentTarget) {
      this.props.closeModal();
    }
  };

  workaroundDownload() {
    fetch(this.props.modalData.fileUrl)
    .then(resp => resp.blob())
    .then(blob => {
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.style.display = 'none';
      a.href = url;
      a.download = this.props.modalData.fileName;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
    })
    .catch(() => console.log("error downloading file"));
  }

  render() {
    if (!this.props.modalData) return null;
    let { fileUrl } = this.props.modalData;
    return (
      <div
        className={
          this.props.openModal === modalCodes.IMAGE_VIEWER ? "modalWrapper ivWrapper open" : "modalWrapper ivWrapper"
        }
        onMouseDown={e => this._clickOutside(e)}
      >
        <div className="imageViewer">
          <div className="options">
            <div className="actionIcon" onClick={() => this.workaroundDownload()}>
                <MaterialIcons name="cloud-download" size={32} />
            </div>
            <div className="actionIcon" onClick={() => this.props.closeModal()}>
                <MaterialIcons name="close" size={32} />
            </div>
          </div>
          <div className="content" onClick={e => this._clickOutside(e)}>
            <img src={fileUrl} alt="" />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  openModal: state.navigation.openModal,
  modalData: state.navigation.modalData
});

const mapDispatchToProps = dispatch => ({
  closeModal: () => dispatch(closeModal())
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ImageViewer);
