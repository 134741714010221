import React, { Component } from "react";
import { connect } from "react-redux";
import Element from "../ElementList/Element";
import { AddElementCard } from "./AddElementCard";
import {
  retrieveListElementsRequested,
  deleteBoardListRequested,
  updateBoardListRequested,
  resetBoardListElements
} from "../../actions/board";
import { parseFilterFromString } from "../../utils/parsing";
import { MaterialIcons } from "react-web-vector-icons";
import { openModal } from "../../actions/navigation";
import { modalCodes } from "../../utils/enums";
import { DraggableElement } from "../Dragging/DraggableElement";
import { DropPlaceholder } from "../Dragging/DropPlaceholder";

class BoardColumn extends Component {
  constructor(props) {
    super(props);

    this.nameInput = React.createRef();
    this.state = {
      listName: props.name,
      addInputHeight: 50,
      bottomReached: false,
      lastId: null,
      reqPending: false
    };

    this.initValues();
  }

  componentDidMount() {
    this.retrieveListElements();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.name !== this.props.name) {
      this.setState({ ...this.state, listName: this.props.name });
    }

    if (prevProps.queryString !== this.props.queryString) {
      this.setState({ ...this.state, bottomReached: false, lastId: null }, () => {
        this.initValues();
        this.props.resetBoardListElements(this.props.id);
        this.retrieveListElements();
      });
    }
  }

  initValues = () => {
    this.filter = parseFilterFromString(this.props.queryString);
    // this.tagList = this.filter.tagsIncluded;
  };

  setBottomReached = () => {
    this.setState({ ...this.state, bottomReached: true });
  }
  setLastId = (lastId) => {
    this.setState({ ...this.state, lastId, reqPending: false });
  }

  handleScroll = e => {
    const { reqPending } = this.state;
    if (reqPending) return;

    const bottom = e.target.offsetHeight + e.target.scrollTop + 300 >= e.target.scrollHeight;
    if (bottom) this.setState({ ...this.state, reqPending: true }, () => {
      this.retrieveListElements();
    });
  }

  retrieveListElements = () => {
    const { retrieveListElementsRequested, id, token, workspaceId } = this.props;
    const { lastId } = this.state;

    // Only use tags
    this.filter.queryString = null;

    retrieveListElementsRequested(
      {
        token: token,
        workspaceId: workspaceId,
        lastId,
        filter: this.filter
      },
      id,
      this.setBottomReached,
      this.setLastId
    );
  };

  toggleOptionDropdown = e => {
    let nx = e.clientX;
    let ny = e.clientY;
    let fromTop = true;

    const contextMenuHeight = 4 * 44 + 12; // 4 = option length, 44 = item height, 12 = padding
    if (window.innerHeight < e.clientY + contextMenuHeight) {
      nx -= 160;
      ny -= contextMenuHeight;
      fromTop = false;
    }

    const contextOptions = {
      open: true, x: nx, y: ny, fromTop: fromTop,
      options: [{ 
        name: "Bearbeiten",
        action: () => this.openEditModal(),
        disableClose: true
      },  {
        name: "Löschen",
        action: () => this.deleteList()
      }]
    }
    if (this.props.boardElement) contextOptions.options.splice(0, 1); // Do not allow selection in board view
    this.props.openModal(modalCodes.ELEM_CONTEXT_MENU_MODAL, contextOptions);
  };

  updateListName = e => {
    let newVal = e.target.value;
    if (newVal) {
      let {
        updateBoardListRequested,
        token,
        emoji,
        name,
        id,
        queryString
      } = this.props;
      if (name === this.state.listName) return;

      updateBoardListRequested(
        {
          token: token
        },
        {
          id: id,
          name: this.state.listName,
          emoji: emoji,
          queryString: queryString
        }
      );
    } else {
      this.setState({ ...this.state, listName: this.props.name });
    }
  };

  listNameChangeEvent = e => {
    this.setState({ ...this.state, listName: e.target.value });
  };

  listNameKeyDownEvent = e => {
    if (e.key.toLowerCase() === "enter") {
      this.nameInput.current.blur();
    }
  };

  deleteList = () => {
    let { deleteBoardListRequested, id, token } = this.props;
    deleteBoardListRequested(
      {
        token: token
      },
      id
    );
  };

  openEditModal = () => {
    const { emoji, id, name, queryString } = this.props;
    this.props.openModal(modalCodes.EDIT_BOARD_LIST_MODAL, {
      id: id,
      name: name,
      emoji: emoji,
      query: queryString
    });
  };

  getPlaceholderIndex = () => {
    let { elements, id, dragItemCreatedAt } = this.props;
    let colElements = elements[id];

    if (Object.keys(colElements).length === 0) return 0;
    let lastDate = null;
    let phIndex = 0;
    Object.keys(colElements)
      .reverse()
      .forEach(e => {
        if (!lastDate) {
          lastDate = colElements[e].DATE_CREATED;
          return;
        }

        if (lastDate > dragItemCreatedAt) phIndex++;
        lastDate = colElements[e].DATE_CREATED;
      });
    return phIndex;
  };

  updateAddInputHeight = height => {
    this.setState({ ...this.state, addInputHeight: height });
  }

  render() {
    const {
      elements,
      emoji,
      id,
      isOver,
      dragHeightRef,
      dragElemListId,
      originTags
    } = this.props;
    const { bottomReached } = this.state;
    let colElements = elements[id];
    let elemList = colElements
      ? Object.keys(colElements)
          .map(e => {
            let {
              id: eid,
              title,
              tags,
              links,
              files,
              reminders,
              DATE_CREATED
            } = colElements[e];
            return (
              <DraggableElement
                key={eid}
                dragElemListId={id}
                dragItemCreatedAt={DATE_CREATED}
                originTags={this.filter.tagsIncluded}
                elemData={colElements[e]}
              >
                <Element
                  id={eid}
                  title={title}
                  tags={tags}
                  links={links}
                  files={files}
                  reminders={reminders}
                  searchBarRef={null}
                  hiddenTags={this.filter.tagsIncluded}
                  disableSelect={true}
                  boardElement={true}
                />
              </DraggableElement>
            );
          })
          .reverse()
      : null;

    if (originTags && elemList && isOver && dragElemListId !== id)
      elemList.splice(
        this.getPlaceholderIndex(),
        0,
        <DropPlaceholder dragHeightRef={dragHeightRef} key="ph" />
      );

    const tagsIn = this.filter.tagsIncluded.map(tag => <span key={tag}>#{tag}</span>)
    const tagsEx = this.filter.tagsExcluded.map(tag => <span key={tag}>!{tag}</span>)
    const tagsAll = [...tagsIn, ...tagsEx];

    return (
      <div className="boardColumn">
        <div className="header">
          <div className="titleWrapper">
            <span role="img" aria-label="emoji">
              {emoji}
            </span>
            <input
              type="text"
              className="title"
              value={this.state.listName}
              onBlur={this.updateListName}
              ref={this.nameInput}
              onChange={this.listNameChangeEvent}
              onKeyDown={this.listNameKeyDownEvent}
            />
            <div className="options">
              <div className="actionIcon" onClick={e => this.toggleOptionDropdown(e)}>
                <MaterialIcons name="more-horiz" size={28} />
              </div>
            </div>
          </div>
          {tagsAll ? (
            <div className="tagList">{tagsAll}</div>
          ) : null}
        </div>
        <div className={elemList && elemList.length === 0 ? "content empty scrollable" : "content scrollable"} 
          style={{ "maxHeight": `calc(100vh - 220px - ${this.state.addInputHeight}px)` }}
          onScroll={e => this.handleScroll(e)}
        >
          {elemList}
          {!bottomReached && (
          <div className="loadingDots">
            <div className="bounce1" />
            <div className="bounce2" />
            <div className="bounce3" />
          </div>)}
        </div>
        <AddElementCard onHeightChange={this.updateAddInputHeight} tagList={this.filter.tagsIncluded} />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  // This didn't work for some reason so for now subscribe to all element changes
  // elements: state.board.elements[ownProps.id],
  elements: state.board.elements,
  token: state.authentication.userData.token,
  language: state.preferences.language,
  workspaceId: state.authentication.userData.workspaceId
});

const mapDispatchToProps = dispatch => ({
  retrieveListElementsRequested: (metadata, listId, bottomReachedEvent, setLastId) =>
    dispatch(retrieveListElementsRequested(metadata, listId, bottomReachedEvent, setLastId)),
  deleteBoardListRequested: (metadata, listId) =>
    dispatch(deleteBoardListRequested(metadata, listId)),
  updateBoardListRequested: (metadata, listData) =>
    dispatch(updateBoardListRequested(metadata, listData)),
  openModal: (modalId, modalData) => dispatch(openModal(modalId, modalData)),
  resetBoardListElements: listId => dispatch(resetBoardListElements(listId))
});

export default connect(mapStateToProps, mapDispatchToProps)(BoardColumn);
