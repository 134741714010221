import React, { Component } from "react";
import { MaterialIcons } from 'react-web-vector-icons';

export class CmdSearchItem extends Component {
  constructor(props) {
    super(props);

    this.me = React.createRef();
  }

  componentDidUpdate() {
    if (this.props.selected === true) {
      let parent = this.props.parent.current.getBoundingClientRect();

      let rect = this.me.current.getBoundingClientRect();
      let elemTop = rect.top - parent.top;
      let elemBottom = rect.bottom - parent.top;

      let outTop = elemTop <= 0;
      let isVisible = elemTop >= 0 && elemBottom <= 300;

      let { current } = this.me;
      if (!isVisible) {
        if (outTop) {
          // current.parentNode.scrollTop = current.offsetTop
          current.parentNode.scrollTop = current.offsetTop - current.clientHeight;
        } else {
          // current.parentNode.scrollTop = current.offsetTop - (current.parentNode.clientHeight - current.clientHeight)
          current.parentNode.scrollTop = current.offsetTop - current.parentNode.clientHeight;
        }
      }
    }
  }

  render() {
    const { cmd, index } = this.props;

    let keyList = null;
    if (cmd.keys) {
        keyList = cmd.keys.map((key, ind) => {
          return (
            <React.Fragment key={ind}>
              {ind > 0 && <span className="plus">+</span>}
              <span className="key">{key}</span>
            </React.Fragment>
          )
        });
    }

    return (
        <div className={ this.props.selected === true ? "searchItem active" : "searchItem" }
                  key={index}
                  ref={this.me}
                  onMouseOver={this.props.mouseOverEvent}
                  onClick={this.props.clickEvent}
                  >
            <span className="cmdIcon">
              { cmd.useTextIcon === true 
                ? <MaterialIcons name={cmd.icon} size={18} color="var(--onSurfaceLight)" />
                : cmd.icon }
              </span>
            <span className="title" dangerouslySetInnerHTML={{ __html: cmd.name }}></span>
            <div className="cmdShortcuts">
              {keyList}
            </div>
        </div>
    );
  }
}
