import React, { Component } from "react";
import "./LoadingBar.scss";

export class LoadingBar extends Component {
    render() {
        return (
            <div className={this.props.visible ? "loadingBar visible" : "loadingBar"}></div>
        )
    }
}
