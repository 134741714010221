import React, { Component } from "react";
import "./Mobile.scss";
import { connect } from "react-redux";
import { addElement, resetAddElementError, addFilesForUpload, uploadFileRequested, addElementFailed } from "./../../actions/element";
import { SimpleInput } from "../SimpleInput/SimpleInput";
import { MaterialIcons } from "react-web-vector-icons";
import UploadedFile from "../Modals/SubComponents/UploadedFile";
import { maxFreeTotalUploaded, maxFreeTotalUploadedString, maxFreeUploadSize, maxFreeUploadSizeString, maxPaidTotalUploaded, maxPaidTotalUploadedString, maxPaidUploadSize, maxPaidUploadSizeString, upgradeLink } from "../../utils/limitations";

class AddElementBar extends Component {
  constructor(props) {
    super(props);

    this.uploadedFileId = 0;
    this.initialFocus = true;
    this.resetInitialFocus = false;

    this.state = {
      resetValue: false,
      inputValue: {
        title: null,
        tags: [],
        links: []
      }
    };
    this.fileInputRef = React.createRef();
  }

  componentDidUpdate(prevProps) {
    // Immediately set resetValue to false again after it has been reset in SimpleInput
    if (this.state.resetValue) {
      this.setState({ ...this.state, resetValue: false });
    }

    // Reset to initial after navigation so the new query value can be set
    if (this.state.resetValue || prevProps.location.pathname !== this.props.location.pathname) {
      this.initialFocus = true;
      this.resetInitialFocus = false;
      this.forceUpdate();
    }
  }

  _updateInputValue = data => {
    if (this.resetInitialFocus) {
      this.initialFocus = false;
      this.resetInitialFocus = false;
      this.forceUpdate();
    }

    this.setState({ ...this.state, inputValue: data, resetValue: false });
  };

  _submitAddElement = (metadata, elemData) => {
    let { uploadedFiles } = this.props;
    let files = Object.keys(uploadedFiles).map(f => {
      return uploadedFiles[f].remoteFileId;
    });

    let suIndex = files.indexOf(undefined);
    if (suIndex !== -1) { // At least one file hasn't finished uploading
      this.props.addElementFailed({ message: "Mindestens eine Datei wurde noch nicht vollständig hochgeladen."});
      return;
    }

    this.props.addElement(metadata, {
      ...elemData,
      files: files
    });

    this.setState({ ...this.state, resetValue: true });
  };

  _onFileChange = e => {
    let files = Array.from(e.target.files);
    let convFiles = {};

    // Reformat files
    files.forEach(file => {
      // Check if storage limit reached before upload
      const uploadLimit = this.props.isPaidUser ? maxPaidTotalUploaded : maxFreeTotalUploaded;
      if (this.props.storageUsed >= uploadLimit) {
        if (this.props.isPaidUser) {
          this.props.addElementFailed({ message: `Die maximale Auslastung deines verfügbaren Speicherplatzes von ${maxPaidTotalUploadedString} wurde erreicht.`});
          return;
        } else {
          this.props.addElementFailed({ 
            message: `Die maximale Auslastung deines verfügbaren Speicherplatzes von ${maxFreeTotalUploadedString} wurde erreicht.`,
            actionMessage: `Upgrade auf Tagstack Plus um deinen verfügbaren Speicherplatz auf ${maxPaidTotalUploadedString} zu erweitern.`,
            actionLink: upgradeLink
          });
          return;
        }
      }

      // Max filesize check
      if (this.props.isPaidUser) {
        if (file.size > maxPaidUploadSize) {
          this.props.addElementFailed({ message: `Die ausgewählte Datei überschreitet die maximale Dateigröße von ${maxPaidUploadSizeString}.`});
          return;
        }
      } else {
        if (file.size > maxFreeUploadSize) {
          this.props.addElementFailed({ 
            message: `Die ausgewählte Datei überschreitet die maximale Dateigröße von ${maxFreeUploadSizeString}.`,
            actionMessage: `Upgrade auf Tagstack Plus um Dateien bis zu ${maxPaidUploadSizeString} hochladen zu können.`,
            actionLink: upgradeLink
          });
          return;
        }
      }

      convFiles["m" + ++this.uploadedFileId] = {
        fileName: file.name,
        fileType: file.type,
        fileSize: file.size,
        lastModified: file.lastModified,
        originalFile: file,
        assignedToElement: false
      };
    });

    // Add to state
    this.props.addFilesForUpload(convFiles);

    // Begin uploading each
    Object.keys(convFiles).forEach(f => {
      this.props.uploadFileRequested(
        {
          token: this.props.token,
          id: f
        },
        convFiles[f]
      );
    });
  };

  render() {
    let { uploadedFiles, queries, location } = this.props;
    if (this.resetInitialFocus) {
      this.initialFocus = false;
      this.resetInitialFocus = false;
    }
      
    if (this.initialFocus && queries) {
      this.resetInitialFocus = true;
    }
        
    let uploadedFileList = Object.keys(uploadedFiles).map(file => {
      return uploadedFiles[file].assignedToElement ? null : (
        <UploadedFile
          id={file}
          file={uploadedFiles[file]}
          key={Math.random()
            .toString(36)
            .substr(2, 12)}
        />
      );
    });
    const loc = location.pathname.split("/");
    const activeQueryString =
      queries && loc[1] === "query"
        ? queries.filter(q => q.id === Number.parseInt(loc[2]))[0]
            .query
        : null;

    return (
      <div className="mobile addElementBar">
        <div className="fileArea">
          {uploadedFileList}
        </div>
        <div className="errorArea">
          {this.props.addError === null ? null : (
            <>
              <p className="errorMsg">{this.props.addError.message}</p>
              <div className="resetErrorMsg" onClick={() => this.props.resetAddElementError()}>
                <MaterialIcons name="close" size={24} />
              </div>
            </>
          )}
        </div>
        <div className="inputArea">
          <div className="actionItem attachment" onClick={() => this.fileInputRef.current.click()}>
            <MaterialIcons name="attach-file" size={22} />
          </div>

          <SimpleInput
            placeholder="Kurze Beschreibung, #tags und Links einfügen"
            singleLine={true}
            metadata={{
              token: this.props.token,
              workspaceId: this.props.workspaceId,
              language: this.props.language
            }}
            loadingFunction={this._setLoadingAnimShown}
            resetToInitial={this.state.resetValue}
            updateEvent={this._updateInputValue}
            submitEvent={this._submitAddElement}
            forceSetInitialValue={
              queries &&
              this.resetInitialFocus 
            }
            value={queries ? 
              (activeQueryString && activeQueryString.slice(-1) === " "
                ? activeQueryString
                : (activeQueryString ? activeQueryString + " " : null )) : null
            }
          />
          <input
            type="file"
            ref={this.fileInputRef}
            multiple="multiple"
            onChange={this._onFileChange}
          />

          <div
            className="actionItem submit"
            onClick={() =>
              this._submitAddElement(
                {
                  token: this.props.token,
                  workspaceId: this.props.workspaceId,
                  language: this.props.language
                },
                this.state.inputValue
              )
            }
          >
            <MaterialIcons name="send" size={22} />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  token: state.authentication.userData.token,
  workspaceId: state.authentication.userData.workspaceId,
  addError: state.element.addError,
  language: state.preferences.language,
  uploadedFiles: state.element.uploadedFiles,
  queries: state.query.queries,
  isPaidUser: state.authentication.userData.isPaidUser,
  storageUsed: state.authentication.userData.storageUsed
});

const mapDispatchToProps = dispatch => ({
  addElement: (metadata, elemData) => dispatch(addElement(metadata, elemData)),
  resetAddElementError: () => dispatch(resetAddElementError()),
  addFilesForUpload: files => dispatch(addFilesForUpload(files)),
  uploadFileRequested: (metadata, file) =>
    dispatch(uploadFileRequested(metadata, file)),
  addElementFailed: errorMsg => dispatch(addElementFailed(errorMsg))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AddElementBar);
