import React, { Component } from "react";
import { MaterialIcons } from "react-web-vector-icons";
import { connect } from "react-redux";
import { removeUploadedFile } from "../../../actions/element";

class UploadedFile extends Component {
  render() {
    let { uploadedFile } = this.props;
    if (uploadedFile && uploadedFile.uploadFinished) {
      return (
        <div className="uploadedFile">
          <div className="fileIcon">
            <MaterialIcons name="insert-drive-file" size={24} />
          </div>
          <span>{this.props.file.fileName}</span>
          <div className="removeFile" onClick={() => this.props.removeUploadedFile(this.props.id)}>
            <MaterialIcons name="close" size={24} />
          </div>
        </div>
      );
    } else {
      // If file just was removed but is re-rendered since it is listening as well
      if (!uploadedFile) {
        return null;
      } else {
        return (
          <div className="uploadedFile">
            <div className="fileIcon">
              <div className="loadingSpinner" />
            </div>
            <span>Wird hochgeladen...</span>
            <div className="removeFile">
              <MaterialIcons name="close" size={24} />
            </div>
          </div>
        );
      }
    }
  }
}

const mapStateToProps = (state, ownProps) => ({
  uploadedFile: state.element.uploadedFiles[ownProps.id]
});

const mapDispatchToProps = dispatch => ({
  removeUploadedFile: id => dispatch(removeUploadedFile(id))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UploadedFile);
