import { baseUrl } from "./config";

export const retrieveBoardListRequest = metadata => {
  return new Promise((resolve, reject) => {
    fetch(`${baseUrl}/get-boards`, {
      method: "post",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${metadata.token}`
      },
      body: JSON.stringify({
        workspaceId: metadata.workspaceId
        // language: metadata.language
      })
    })
      .then(res => {
        res.json().then(rj => {
          // console.log(rj)
          resolve(rj);
        });
      })
      .catch(err => {
        reject(err);
      });
  });
};

export const addBoardRequest = (metadata, boardData) => {
  return new Promise((resolve, reject) => {
    fetch(`${baseUrl}/add-board`, {
      method: "post",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${metadata.token}`
      },
      body: JSON.stringify({
        workspaceId: metadata.workspaceId,
        name: boardData.name
        // language: metadata.language
      })
    })
      .then(res => {
        res.json().then(rj => {
          // console.log(rj)
          resolve(rj);
        });
      })
      .catch(err => {
        reject(err);
      });
  });
};

export const addBoardListRequest = (metadata, listData) => {
  return new Promise((resolve, reject) => {
    fetch(`${baseUrl}/add-board-list`, {
      method: "post",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${metadata.token}`
      },
      body: JSON.stringify({
        boardId: listData.boardId,
        listName: listData.name,
        emoji: listData.emoji,
        queryString: listData.query
        // language: metadata.language
      })
    })
      .then(res => {
        res.json().then(rj => {
          // console.log(rj)
          resolve(rj);
        });
      })
      .catch(err => {
        reject(err);
      });
  });
};

export const retrieveBoardDetailsRequest = (metadata, boardId) => {
  return new Promise((resolve, reject) => {
    fetch(`${baseUrl}/get-board-details`, {
      method: "post",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${metadata.token}`
      },
      body: JSON.stringify({
        boardId: boardId
        // language: metadata.language
      })
    })
      .then(res => {
        res.json().then(rj => {
          // console.log(rj)
          resolve(rj);
        });
      })
      .catch(err => {
        reject(err);
      });
  });
};

export const retrieveListElementsRequest = metadata => {
  return new Promise((resolve, reject) => {
    fetch(`${baseUrl}/get-elements`, {
      method: "post",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${metadata.token}`
      },
      body: JSON.stringify({
        workspaceId: metadata.workspaceId,
        lastId: metadata.lastId,
        filter: metadata.filter
        // language: metadata.language
      })
    })
      .then(res => {
        res.json().then(rj => {
          // console.log(rj)
          resolve(rj);
        });
      })
      .catch(err => {
        reject(err);
      });
  });
};

export const deleteBoardRequest = (metadata, boardId) => {
  return new Promise((resolve, reject) => {
    fetch(`${baseUrl}/delete-board`, {
      method: "post",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${metadata.token}`
      },
      body: JSON.stringify({
        boardId: boardId
        // language: metadata.language
      })
    })
      .then(res => {
        res.json().then(rj => {
          // console.log(rj)
          resolve(rj);
        });
      })
      .catch(err => {
        reject(err);
      });
  });
};

export const updateBoardRequest = (metadata, boardData) => {
  return new Promise((resolve, reject) => {
    fetch(`${baseUrl}/edit-board`, {
      method: "post",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${metadata.token}`
      },
      body: JSON.stringify({
        boardId: boardData.boardId,
        name: boardData.name
        // language: metadata.language
      })
    })
      .then(res => {
        res.json().then(rj => {
          // console.log(rj)
          resolve(rj);
        });
      })
      .catch(err => {
        reject(err);
      });
  });
};

export const deleteBoardListRequest = (metadata, listId) => {
  return new Promise((resolve, reject) => {
    fetch(`${baseUrl}/delete-board-list`, {
      method: "post",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${metadata.token}`
      },
      body: JSON.stringify({
        listId: listId
        // language: metadata.language
      })
    })
      .then(res => {
        res.json().then(rj => {
          // console.log(rj)
          resolve(rj);
        });
      })
      .catch(err => {
        reject(err);
      });
  });
};

export const updateBoardListRequest = (metadata, listData) => {
  return new Promise((resolve, reject) => {
    fetch(`${baseUrl}/edit-board-list`, {
      method: "post",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${metadata.token}`
      },
      body: JSON.stringify({
        listId: listData.id,
        listName: listData.name,
        queryString: listData.queryString,
        emoji: listData.emoji
        // language: metadata.language
      })
    })
      .then(res => {
        res.json().then(rj => {
          // console.log(rj)
          resolve(rj);
        });
      })
      .catch(err => {
        reject(err);
      });
  });
};