import React, { Component } from "react";
import { connect } from "react-redux";
import { closeModal } from "./../../actions/navigation";
import { SimpleInput } from "../SimpleInput/SimpleInput";
import { SearchHelp } from "./../HelpDialogs/SearchHelp";
import { LoadingBar } from "../Loading/LoadingBar";
import "emoji-mart/css/emoji-mart.css";
import { Picker } from "emoji-mart";
import { MaterialIcons } from "react-web-vector-icons";
import { modalCodes } from "../../utils/enums";
import { addBoardListRequested, resetAddBoardListError, addBoardListFailed } from "../../actions/board";
import { parseFilterFromString } from "../../utils/parsing";

class AddBoardListModal extends Component {
  constructor(props) {
    super(props);

    this.emoji = React.createRef();
    this.name = React.createRef();
    this.addInput = React.createRef();
    this.initialFocus = true;

    this.state = {
      loadingBarShown: false,
      query: "",
      emoji: "❓",
      emojiPickerShown: false
    };
  }

  shouldComponentUpdate(nextProps) {
    // Only re-render if this modal is being opened or is already open and openModal changed
    return nextProps.openModal === modalCodes.ADD_BOARD_LIST_MODAL || this.props.openModal === modalCodes.ADD_BOARD_LIST_MODAL;
  }

  static getDerivedStateFromProps(props, state) {
    let modalClosed = props.openModal && props.openModal !== modalCodes.ADD_BOARD_LIST_MODAL;
    // Reset error when modal is closed
    if (modalClosed && props.addListError !== null) props.resetAddBoardListError();

    // Hide loading bar when modal closed or error occured
    if (modalClosed) {
      return {
        ...state,
        loadingBarShown: false,
        query: "",
        emoji: "❓"
      };
    }
    // Do not reset query when error visible
    if (props.addListError !== null) {
      return {
        ...state,
        loadingBarShown: false
      };
    }
    return null;
  }

  componentDidUpdate() {
    if (this.props.openModal !== modalCodes.ADD_BOARD_LIST_MODAL) {
      this.initialFocus = true;
    }
  }

  _clickOutside = e => {
    if (e.target === e.currentTarget) {
      this.props.closeModal();
    }
  };

  _setLoadingBarShown = s => {
    this.setState({ ...this.state, loadingBarShown: s });
  };

  _handleKeyDownNameInput = e => {
    if (e.key === "Enter") {
      this._manualSubmitForm();
    }
  };

  _queryChanged = query => {
    this.setState({ query: query });
  };

  _manualSubmitForm = () => {
    this._submitForm(this.state.query);
  };

  _submitForm = plainContent => {
    this._setLoadingBarShown(true);

    let currentFilter = parseFilterFromString(this.state.query);
    currentFilter.tagsIncluded = currentFilter.tagsIncluded.sort();
    currentFilter.tagsExcluded = currentFilter.tagsExcluded.sort();

    const { lists } = this.props;
    let foundEqual = false;

    Object.keys(lists).forEach(l => {
      if (foundEqual) return;

      let listFilter = parseFilterFromString(lists[l].queryString);
      listFilter.tagsIncluded = listFilter.tagsIncluded.sort();
      listFilter.tagsExcluded = listFilter.tagsExcluded.sort();

      let tagInEqual = currentFilter.tagsIncluded.length === listFilter.tagsIncluded.length;
      currentFilter.tagsIncluded.forEach((t, i) => {
        if (!tagInEqual) return;
        if (t !== listFilter.tagsIncluded[i]) {
          tagInEqual = false;
        }
      });

      let tagExEqual = currentFilter.tagsExcluded.length === listFilter.tagsExcluded.length;
      currentFilter.tagsExcluded.forEach((t, i) => {
        if (!tagExEqual) return;
        if (t !== listFilter.tagsExcluded[i]) {
          tagExEqual = false;
        }
      });

      if (tagInEqual && tagExEqual) foundEqual = true;
    });

    if (foundEqual) {
      this.props.addBoardListFailed("Eine Liste mit diesem Filter existiert bereits.")
    } else {
      this.props.addBoardListRequested({
        token: this.props.token,
        language: this.props.language
      }, {
        boardId: this.props.activeBoardId,
        emoji: this.state.emoji,
        name: this.name.current.value.slice(0),
        query: plainContent
      });
    }
  };

  _showEmojiPicker = () => {
    this.setState({
      ...this.state,
      emojiPickerShown: true
    });
  };

  _hideEmojiPicker = e => {
    if (e.relatedTarget) {
      // Wait until clicked element received focus
      setTimeout(() => {
        let active = document.activeElement.getAttribute("id");
        if (this.state.emojiPickerShown && (!active || !active.startsWith("emoji-mart-search"))) {
          // Related target but not searchbar clicked -> focus input
          this.emoji.current.focus();
        } else {
          // Searchbar clicked, add focusout event
          document.activeElement.addEventListener("focusout", e => {
            if (!e.relatedTarget)
              this.setState({ ...this.state, emojiPickerShown: false });
          });
        }
      }, 30);
    } else {
      this.setState({ ...this.state, emojiPickerShown: false });
    }
  };

  _onSelectEmoji = emoji => {
    // this.emoji.current.firstChild.textContent = emoji.native;
    this.emoji.current.blur();
    this.setState({ ...this.state, emoji: emoji.native, emojiPickerShown: false });
  };

  render() {
    if (this.props.openModal === modalCodes.ADD_BOARD_LIST_MODAL && this.initialFocus) {
      this.initialFocus = false;
      this.name.current.value = this.props.modalData.name;
      setTimeout(() => {
        if (this.name.current) this.name.current.focus();
      }, 150);
    } else {
      if (this.props.openModal !== modalCodes.ADD_BOARD_LIST_MODAL) {
        // Remove focus from inputs after close
        setTimeout(() => {
          if (this.addInput.current) this.addInput.current.blur();
          if (this.name.current) this.name.current.blur();
        }, 150);
      }
    }

    return (
      <div
        className={
          this.props.openModal === modalCodes.ADD_BOARD_LIST_MODAL
            ? "modalWrapper scrollable open"
            : "modalWrapper scrollable"
        }
        onMouseDown={e => this._clickOutside(e)}
      >
        <div className="modalContent">
          <LoadingBar visible={this.state.loadingBarShown} />
          <h2>Neue Liste anlegen</h2>
          <div className="close" onClick={() => this.props.closeModal()}>
            <MaterialIcons name="close" size={18} />
          </div>
          <div className="addQuery">
            <div className="inputFields">
              <div className="labelFields">
                <div className="emoji"><span>Emoji:</span></div>
                <div className="name"><span>Name der Liste:</span></div>
              </div>
              <div className="dataFields">
                <div className="emoji material">
                  <button
                    className="primary emoji"
                    ref={this.emoji}
                    onClick={this._showEmojiPicker}
                    onBlur={this._hideEmojiPicker}
                  ><span role="img" aria-label="emoji">{this.state.emoji}</span></button>
                  {this.state.emojiPickerShown ? <Picker
                    native={true}
                    // style={
                    //   this.state.emojiPickerShown
                    //     ? { visibility: "visible" }
                    //     : { visibility: "hidden" }
                    // }
                    // set="apple"
                    title="Wähle ein Emoji…"
                    emoji="point_up"
                    sheetSize={32}
                    showPreview={false}
                    onSelect={this._onSelectEmoji}
                  /> : null }
                </div>
                <div className="name">
                  <input
                    type="text"
                    className="nameInput"
                    ref={this.name}
                    spellCheck={false}
                    onKeyDown={this._handleKeyDownNameInput}
                    placeholder="z.B. Einkaufsliste"
                  />
                </div>
              </div>
            </div>
            <div className="valueField">
              <span>Suchfilter: </span>
              <div className="fieldInner">
                {/* <span>#</span> */}
                <SimpleInput
                  placeholder="Nach Tags filtern z.B. #todo #einkaufen"
                  showHelpIcon={true}
                  helpComponent={SearchHelp}
                  resetToInitial={this.props.openModal === modalCodes.ADD_BOARD_LIST_MODAL ? false : true}
                  loadingFunction={this._setLoadingBarShown}
                  returnPlainContent={true}
                  plainUpdateEvent={this._queryChanged}
                  submitEvent={this._submitForm}
                  inputRef={this.addInput} // Mandatory now
                  returnFilter={true}
                />
              </div>
            </div>
            {this.props.addListError === null ? null : (
              <p className="errorMsg">{this.props.addListError}</p>
            )}
            <div className="actionItems material">
              <button className="primary" onClick={this._manualSubmitForm}>
                Erstellen
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  openModal: state.navigation.openModal,
  modalData: state.navigation.modalData,
  token: state.authentication.userData.token,
  workspaceId: state.authentication.userData.workspaceId,
  addListError: state.board.addListError,
  language: state.preferences.language,
  activeBoardId: state.board.activeBoardId,
  lists: state.board.lists
});

const mapDispatchToProps = dispatch => ({
  closeModal: () => dispatch(closeModal()),
  addBoardListRequested: (metadata, listData) => dispatch(addBoardListRequested(metadata, listData)),
  resetAddBoardListError: () => dispatch(resetAddBoardListError()),
  addBoardListFailed: errorMsg => dispatch(addBoardListFailed(errorMsg))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AddBoardListModal);
