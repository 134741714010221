import { baseUrl } from './config';

export const loginRequest = (metadata, loginData) => {
  return new Promise((resolve, reject) => {
    fetch(`${baseUrl}/login`, {
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json'
      },
      body: JSON.stringify({
        email: loginData.email,
        password: loginData.password
        // language: metadata.language
      })
    })
      .then(res => {
        res.json().then(rj => {
          // console.log(rj);
          resolve(rj);
        });
      })
      .catch(err => {
        reject(err);
      });
  });
};

export const registerRequest = (metadata, registerData) => {
  return new Promise((resolve, reject) => {
    fetch(`${baseUrl}/register`, {
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json'
      },
      body: JSON.stringify({
        firstName: registerData.firstName,
        lastName: registerData.lastName,
        email: registerData.email,
        password: registerData.password,
        passwordConfirmation: registerData.passwordConfirmation
        // language: metadata.language
      })
    })
      .then(res => {
        res.json().then(rj => {
          resolve(rj);
        });
      })
      .catch(err => {
        reject(err);
      });
  });
};

export const getUserInfo = metadata => {
  return new Promise((resolve, reject) => {
    fetch(`${baseUrl}/get-user-info`, {
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${metadata.token}`
      },
      body: JSON.stringify({})
    })
      .then(res => {
        res.json().then(rj => {
          //   console.log(rj);
          resolve(rj);
        });
      })
      .catch(err => {
        reject(err);
      });
  });
};

export const requestResetPasswordRequest = email => {
  return fetch(`${baseUrl}/request-password-reset`, {
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json'
    },
    body: JSON.stringify({
      email
    })
  })
    .then(res => {
      return res.json();
    })
    .catch(err => {
      throw err;
    });
};

export const resetPasswordRequest = (resetToken, newPassword, newPasswordConfirmation) => {
  return fetch(`${baseUrl}/reset-password`, {
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json'
    },
    body: JSON.stringify({
      resetToken,
      newPassword,
      newPasswordConfirmation
    })
  })
    .then(res => {
      return res.json();
    })
    .catch(err => {
      throw err;
    });
};
