import { all, put } from 'redux-saga/effects';
import { watchAllAuthenticationActions } from './authenticationSaga';
import { watchAllElementActions } from './elementSaga';
import { watchAllNotificationActions } from './notificationSaga';
import { statusCodes } from "./../api/config";
import { showNotification } from '../actions/notification';
import { watchAllFilterActions } from './filterSaga';
import { watchAllQueryActions } from './querySaga';
import { watchAllPreferenceActions } from './preferenceSaga';
import { watchAllNavigationActions } from './navigationSaga';
import { watchAllBoardActions } from './boardSaga';

export default function* rootSaga() {
  yield all([
    watchAllAuthenticationActions(),
    watchAllElementActions(),
    watchAllNotificationActions(),
    watchAllFilterActions(),
    watchAllQueryActions(),
    watchAllPreferenceActions(),
    watchAllNavigationActions(),
    watchAllBoardActions()
  ])
}

export function* genericHandleResult(result, success, error, onSuccess) {
  switch(result.statusCode) {
    case statusCodes.SUCCESS: {
        if (success) yield put(success);
        if (onSuccess) {
          yield all(onSuccess.map(e => put(e)))
        };
        break;
    }
    case statusCodes.TOKEN_ERROR: {
      yield put({
        type: "LOGOUT"
      });
      break;
    }
    case statusCodes.AUTH_ERROR: {
        yield put(error);
      break;
    }
    case statusCodes.INVALID_PARAMS: {
      yield put(error);
      // yield put(showNotification({ type: "error", title: "Invalid params", desc: result.errorMsg }));
      break;
    }
    case statusCodes.UNKNOWN_ERROR: {
      yield put(error);
      break;
    }
    case statusCodes.NOT_FOUND: {
      yield put(showNotification({ type: "error", title: "Not found", desc: result.errorMsg }));
      break;
    }
    case statusCodes.INTERNAL_SERVER_ERROR: {
      yield put(showNotification({ type: "error", title: "Internal Server Error", desc: result.errorMsg }));
      break;
    }
    default: {
        yield put(error);
        break;
    }
  }
};