import React, { Component } from "react";
// import { MaterialIcons } from "react-web-vector-icons";
import { connect } from "react-redux";
// import { resetHiddenByFilter } from "../../actions/element";

class GroupedElements extends Component {
  constructor(props) {
    super(props);

    this.state = { showClose: false };
  }

  _mouseMoveSetShowClose = shown => {
    this.setState({ ...this.state, showClose: shown });
  };

  getDateString = date => {
    date.setHours(0,0,0,0);

    if (date.valueOf() === new Date().setHours(0,0,0,0)) {
      return "Heute";
    } else {
      let nDate = new Date();
      nDate.setDate(nDate.getDate() - 1);
      if (date.valueOf() === nDate.setHours(0,0,0,0)) {
        return "Gestern";
      } else {
        return date.toLocaleDateString("de-DE", options);
      }
    }
  }

  render() {
      let date = new Date(this.props.date);
      return (
        <div className="groupedElements">
          <h3>{this.getDateString(date)}</h3>
          {this.props.elements}
        </div>
      );
  }

  // OLD HIDDEN BY FILTER STUFF
  // if (this.props.type === "hidden") {
  //   return (
  //     <div
  //       className="groupedElements hiddenType"
  //       onMouseEnter={() => this._mouseMoveSetShowClose(true)}
  //       onMouseLeave={() => this._mouseMoveSetShowClose(false)}
  //     >
  //       <h3>Ausgeblendet durch aktiven Filter</h3>
  //       <div
  //         className={this.state.showClose ? "closeHiddenGroup shown" : "closeHiddenGroup" }
  //         onClick={() => this.props.resetHiddenByFilter()}
  //       >
  //         <MaterialIcons name="close" size={18} />
  //       </div>
  //       {this.props.elements}
  //     </div>
  //   );
  // } else {
}

const options = {
  weekday: "long",
  year: "numeric",
  month: "long",
  day: "numeric"
};

const mapDispatchToProps = dispatch => ({
  // resetHiddenByFilter: () => dispatch(resetHiddenByFilter())
});

export default connect(null, mapDispatchToProps)(GroupedElements);