import React, { Component } from "react";
import { InactiveFilterItem } from "./InactiveFilterItem";
import { connect } from "react-redux";
import { getReference, ReferenceNames } from "../../utils/reference";

class InactiveFilterArea extends Component {
  render() {
    let { queryString, tagsIncluded, tagsExcluded } = this.props;
    return (
      <div className="inactiveFilterArea">
        {queryString === undefined || queryString === null ? (
          <InactiveFilterItem
            title="Im Titel enthalten"
            doOnClick={() => {
              // this.props.updateFilterManually({ queryString: null });
              const searchbar = getReference(ReferenceNames.SEARCHBAR_INPUT);
              if (searchbar) searchbar.focus();
            }
            }
          />
        ) : null}
        {/* {date === undefined || date.start === undefined || date.end === undefined ? (
          <InactiveFilterItem
            title="Zeitraum"
          />
        ) : null} */}
        {tagsIncluded === undefined || tagsIncluded === null || tagsIncluded.length === 0 ? (
          <InactiveFilterItem title="Tags enthalten" doOnClick={() => null} />
        ) : null}
        {tagsExcluded === undefined || tagsExcluded === null || tagsExcluded.length === 0 ? (
          <InactiveFilterItem title="Tags ausgenommen" doOnClick={() => null} />
        ) : null}
        {(queryString !== null && queryString !== undefined) &&
        (tagsIncluded !== null && tagsIncluded !== undefined && tagsIncluded.length !== 0) &&
        (tagsExcluded !== null && tagsExcluded !== undefined && tagsExcluded.length !== 0) ? (
          <p className="noFilter">
            Alle verfügbaren Filter sind in Verwendung.
          </p>
        ) : null}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  queryString: state.filter.queryString,
  date: state.filter.date,
  tagsIncluded: state.filter.tagsIncluded,
  tagsExcluded: state.filter.tagsExcluded
});

export default connect(
  mapStateToProps,
  null
)(InactiveFilterArea);
