const initialState = {
    isOnline: undefined
};

export const connection = (state = initialState, action) => {
  switch (action.type) {
    case "SET_ONLINE_STATUS": {
      return {
        ...state,
        isOnline: action.status
      }
    }
    default:
      return state;
  }
};
