import React, { memo } from 'react'

const styles = {
  display: 'inline-block',
  // transform: 'rotate(-4deg)',
  width: '296px'
}

export const ElementDragPreview = memo(props => {
  return (
    <div style={styles}>
      {props.element}
    </div>
  )
})