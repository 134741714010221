export const setFavicon = (document, fileName) => {
  let link = document.querySelector("link[rel*='icon']") || document.createElement('link');
  link.type = 'image/x-icon';
  link.rel = 'shortcut icon';
  link.href = `/${fileName}.ico`;
  document.getElementsByTagName('head')[0].appendChild(link);
};

export const isMac = navigator.platform.toUpperCase().indexOf('MAC') >= 0;
export const isCtrlKeyActive = (e) => isMac ? e.metaKey : e.ctrlKey;
export const ctrlKeyName = isMac ? "⌘" : "Strg";
export const altKeyName = isMac ? "⌥" : "Alt";