import React, { Component } from "react";
import "./MobileSideMenu.scss";
import { connect } from "react-redux";
import StaticQueryArea from "../../QueryArea/StaticQueryArea";
import DynamicQueryArea from "../../QueryArea/DynamicQueryArea";
import NavigationBar from "./NavigationBar";
import FilterArea from "../../FilterArea/FilterArea";
import { toggleMobileMenu } from "../../../actions/navigation";

class MobileSideMenu extends Component {
  render() {
    return (
      <div className={this.props.mobileMenuOpen === true ? "mobileSideMenu opened" : "mobileSideMenu" }>
        <h3>Übersicht</h3>
        <StaticQueryArea mobile={true} />
        <h3>Gespeicherte Suchen</h3>
        <DynamicQueryArea mobile={true} />
        <FilterArea hidden={true} />
        <NavigationBar toggleMobileMenu={this.props.toggleMobileMenu} />
      </div>
    );
  }

  componentDidMount() {
    // Set default or last selected theme
    document.documentElement.setAttribute("theme", this.props.theme);
  }

  componentDidUpdate() {
    let currentTheme = document.documentElement.getAttribute("theme");
    if (currentTheme && currentTheme !== this.props.theme) {
      document.documentElement.classList.add("theme-transition");
      document.documentElement.setAttribute("theme", this.props.theme);
      window.setTimeout(() => {
        document.documentElement.classList.remove("theme-transition");
      }, 400);
    }
  }
}

const mapStateToProps = state => ({
    theme: state.preferences.theme,
    mobileMenuOpen: state.navigation.mobileMenuOpen
});

const mapDispatchToProps = dispatch => ({
  toggleMobileMenu: () => dispatch(toggleMobileMenu())
});

export default connect(mapStateToProps, mapDispatchToProps)(MobileSideMenu);