import React, { Component } from "react";
import "./MobileTopBar.scss";
import { MaterialIcons } from "react-web-vector-icons";
import { connect } from "react-redux";
import { toggleMobileMenu } from "./../../../actions/navigation";
import { SimpleInput } from "../../SimpleInput/SimpleInput";
import {
  resetAllFilters,
  updateFilterRequested
} from "../../../actions/filter";
import { retrieveElements } from "../../../actions/element";
import { withRouter } from "react-router-dom";
import { ReferenceNames, getReference } from "../../../utils/reference";

class MobileTopBar extends Component {
  constructor(props) {
    super(props);

    this.state = { searchBarOpen: false };
    this.modifiedActiveQuery = false;
    this.prevProps = null;
    this.filterIsSet = false;
  }

  componentDidUpdate(prevProps) {
    this.prevProps = prevProps;
  }

  _clearAction = () => {
    this.props.resetAllFilters(true);
    this.props.history.push("/timeline");
  };

  filterIsEmpty = () => {
    let { filter } = this.props;
    if (filter.queryString) return false;
    if (filter.tagsIncluded.length > 0) return false;
    return true;
  };

  generateSearchStringFromFilter = () => {
    let final = "";
    let { filter } = this.props;
    if (filter.queryString) {
      final += filter.queryString + " ";
    }
    if (filter.tagsIncluded.length > 0) {
      filter.tagsIncluded.forEach(t => {
        final += "#" + t + " ";
      });
    }
    return final;
  };

  searchBarSubmit = (_metadata, typeData) => {
    this.searchBarUpdate(typeData);
  };

  searchBarUpdate = typeData => {
    if (this.prevProps) {
      this.modifiedActiveQuery =
        this.prevProps.location.pathname === this.props.location.pathname &&
        this.props.location.pathname !== "/untagged" &&
        this.filterIsSet && // Make sure filter is set
        (this.props.filter.queryString !== typeData.title ||
          this.props.filter.tagsIncluded !== typeData.tags)
          ? true
          : false;

      // If active query search-string was modified -> select timeline
      if (this.modifiedActiveQuery)
        this.props.history.push({
          pathname: "/timeline",
          state: { keepInput: true }
        });
    }

    // Recognize reset from click on static query and don't update filters
    if (!this.firstClickOnStatic || this.modifiedActiveQuery) {
      this.props.updateFilterRequested({
        queryString: typeData.title === "" ? null : typeData.title,
        tagsIncluded: typeData.tags.length === 0 ? [] : typeData.tags,
        tagsExcluded: []
      });
      this.filterIsSet = true;
    }
  };

  _toggleSearchBar = val => {
    this.setState({ ...this.state, searchBarOpen: val });
  };

  render() {
    if (this.state.searchBarOpen) {
      setTimeout(() => {
        const searchbar = getReference(ReferenceNames.SEARCHBAR_INPUT);
        if (searchbar) searchbar.focus();
      }, 150);
    }

    let titleText = "";
    let path = this.props.location.pathname.split('/');
    switch(path[1]) {
      case "untagged": {
        titleText = "Ungetaggt";
        break;
      }
      case "timeline": {
        titleText = "Zeitachse";
        break;
      }
      case "query": {
        if (this.props.queries) {
          let qs = this.props.queries.filter(q => q.id === Number.parseInt(path[2]));
          titleText = qs[0] ? qs[0].name : "";
        }
        break;
      }
      default: break;  
    }
    
    let { searchBarOpen } = this.state;
    return (
      <div className="mobileTopBar">
        <div
          className="burgerMenu"
          onClick={() => this.props.toggleMobileMenu()}
        >
          <MaterialIcons
            name={this.props.mobileMenuOpen === true ? "close" : "menu"}
            size={32}
          />
        </div>
        <div className="pageTitle">
          <h2>{titleText}</h2>
        </div>
        <SimpleInput
          className={searchBarOpen ? "opened" : false}
          placeholder="Nach Titel oder #tags filtern"
          singleLine={true}
          showClearBtn={true}
          clearAction={this._clearAction}
          // forceSetInitialValue={true}
          value={
            this.filterIsEmpty() ? null : this.generateSearchStringFromFilter()
          }
          blurEvent={() => this._toggleSearchBar(false)}
          inputRefName={ReferenceNames.SEARCHBAR_INPUT}
          updateEvent={this.searchBarUpdate}
          submitEvent={this.searchBarSubmit}
        />
        <div
          className={searchBarOpen ? "searchBarContainer vanish" : "searchBarContainer" }
          onClick={() => this._toggleSearchBar(true)}
        >
          <MaterialIcons name="search" size={32} />
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  queries: state.query.queries,
  lastId: state.element.lastId,
  filter: state.filter,
  token: state.authentication.userData.token,
  workspaceId: state.authentication.userData.workspaceId,
  language: state.preferences.language
});

const mapDispatchToProps = dispatch => ({
  toggleMobileMenu: () => dispatch(toggleMobileMenu()),
  updateFilterRequested: filter => dispatch(updateFilterRequested(filter)),
  retrieveElements: (metadata, append) =>
    dispatch(retrieveElements(metadata, append)),
  resetAllFilters: triggerRequest => dispatch(resetAllFilters(triggerRequest))
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(MobileTopBar)
);
