import { put, takeEvery, select } from "redux-saga/effects";
import { resetLastId } from "../actions/element";
import { updateFilter } from "../actions/filter";

export function* watchAllFilterActions() {
  yield takeEvery("UPDATE_FILTER_REQUESTED", beginUpdateFilter);
}

const getLastId = state => state.element.lastId;
export function* beginUpdateFilter(action) {
    let lastId = yield select(getLastId);
    if (lastId) {
        yield put(resetLastId());
    }
    // yield put(resetHiddenByFilter());
    yield put(updateFilter(action.filter, action.triggerRequest));
}