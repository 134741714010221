import React, { useState, useRef, useEffect } from 'react';
import { MaterialIcons } from 'react-web-vector-icons';
import { SimpleInput } from '../SimpleInput/SimpleInput';
import { useDispatch, useSelector } from 'react-redux';
import { addElement } from '../../actions/element';
import { LoadingBar } from '../Loading/LoadingBar';

export const AddElementCard = ({ onHeightChange, tagList }) => {
  const [ inputValue, setInputValue ] = useState({
    title: null,
    tags: [],
    links: []
  });
  const [ loadingState, setLoadingState ] = useState({ loading: false, success: null });
  const dispatch = useDispatch();

  const { token, workspaceId } = useSelector(state => state.authentication.userData);
  const language = useSelector(state => state.preferences.language);

  let inputRef = useRef(null);
  let containerRef = useRef(null);

  const allowSubmit = inputValue.title || inputValue.links.length > 0;

  useEffect(
    () => {
      if (loadingState.success) {
        setInputValue({ title: null, tags: [], links: [] });
        setLoadingState({ loading: false, success: null });
      }
    },
    [ loadingState ]
  );

  const submitForm = () => {
    setLoadingState({ loading: true, success: null });
    let finalInputValue = inputValue;

    // Add column tags if not already there
    tagList.forEach(tag => {
      if (finalInputValue.tags.indexOf(tag) === -1) finalInputValue.tags.push(tag);
    });

    dispatch(
      addElement({ token, workspaceId, language, setLoadingState, boardView: true }, { ...inputValue, files: [] })
    );
  };

  return (
    <div
      className={inputRef.current && inputRef.current.value ? 'addElementCard filled' : 'addElementCard'}
      ref={ref => (containerRef = ref)}
    >
      <LoadingBar visible={loadingState.loading} />
      <div className="addIcon" onClick={() => inputRef.current.focus()}>
        <MaterialIcons name="add" size={23} />
      </div>
      <SimpleInput
        inputRef={inputRef}
        placeholder="Element hinzufügen"
        seamless={true}
        seamlessFocused={true}
        updateEvent={data => {
          setInputValue(data);
          if (onHeightChange) onHeightChange(containerRef.offsetHeight);
        }}
        submitEvent={submitForm}
        resetToInitial={loadingState.success}
      />
      <div className={allowSubmit ? 'submitBtn shown' : 'submitBtn'} onClick={submitForm}>
        <MaterialIcons name="arrow-forward" size={23} />
      </div>
    </div>
  );
};
