import openSocket from "socket.io-client";
import { setOnlineStatus } from "./../actions/connection";
import {
  addElementDirectly,
  socketInsertLinkDirectly,
  socketLinkDataFailed,
  deleteElementDirectly,
  updateElementDirectly,
  deleteMultipleElementsDirectly,
  refreshElementReminder
} from "./../actions/element";
import { convertDataFormat } from "../utils/conversions";
import {
  addQuerySucceeded,
  editQuerySucceeded,
  removeQueryDirectly
} from "../actions/query";
import { setInitialUserSettings } from "../actions/preferences";
import { showNotification } from "./../actions/notification";
import {
  addBoardDirectly,
  addBoardListDirectly,
  deleteBoardSucceeded,
  deleteBoardListSucceeded,
  updateBoardListSucceeded,
  updateBoardSucceeded
} from "../actions/board";
import { parseReadableDateTimeFromString } from "../utils/parsing";
import { setFavicon } from "../utils/other";

export const listenForSocketEvents = (metadata, genericDispatch, document) => {
  const socket = openSocket("https://api.tagstack.app");

  socket.on("connect", () => {
    genericDispatch(setOnlineStatus(true));
    socket.emit("joinRequest", {
      token: metadata.token,
      workspaceId: metadata.workspaceId
    }); // Workspace related
    socket.emit("userJoinRequest", { token: metadata.token }); // User related
  });

  socket.on("joinFailed", error => {
    genericDispatch(
      showNotification({
        type: "error",
        title: "Socket join failed",
        desc: error.errorMsg
      })
    );
  });

  socket.on("disconnect", () => {
    genericDispatch(setOnlineStatus(false));
  });

  /* Workspace related */

  /* Element */

  socket.on("addElement", element => {
    element.tags = convertDataFormat(element.tags);
    element.links = convertDataFormat(element.links);
    element.files = convertDataFormat(element.files);
    element.reminders = convertDataFormat(element.reminders);
    // Set all newly added links to loading
    Object.keys(element.links.data).forEach(l => {
      element.links.data[l].loading = true;
    });
    genericDispatch(addElementDirectly(element));
  });

  socket.on("deleteElement", element => {
    genericDispatch(deleteElementDirectly(element.elementId));
  });

  socket.on("deleteMultipleElements", element => {
    genericDispatch(deleteMultipleElementsDirectly(element.deletionSuccessIds));
    
    if (element.deletionFailureIds.length > 0) {
      genericDispatch(
        showNotification({
          type: "error",
          title: "Could not delete all elements",
          desc: "Some of the selected elements could not be deleted"
        })
      );
    }
  });

  socket.on("updateLinkMetadata", link => {
    genericDispatch(socketInsertLinkDirectly(link));
  });

  socket.on("updateLinkMetadataFailed", link => {
    genericDispatch(socketLinkDataFailed(link));
  });

  socket.on("editElement", element => {
    element.tags = convertDataFormat(element.tags);
    element.links = convertDataFormat(element.links);
    element.files = convertDataFormat(element.files);
    element.reminders = convertDataFormat(element.reminders);
    // Set all newly added links to loading
    Object.keys(element.links.data).forEach(l => {
      if (!(element.links.data[l].title || element.links.data[l].faviconUrl)) element.links.data[l].loading = true;
    });
    genericDispatch(updateElementDirectly(element));
  });

  /* Query */

  socket.on("addQuery", query => {
    genericDispatch(addQuerySucceeded(query));
  });

  socket.on("editQuery", query => {
    genericDispatch(editQuerySucceeded(query.query));
  });

  socket.on("removeQuery", query => {
    genericDispatch(removeQueryDirectly(query.removedQueryId));
  });

  /* Board */
  socket.on("addBoard", board => {
    let boardData = convertDataFormat(board);
    genericDispatch(addBoardDirectly(boardData.data));
  });

  socket.on("addBoardList", list => {
    genericDispatch(addBoardListDirectly(list));
  });

  socket.on("deleteBoard", board => {
    genericDispatch(deleteBoardSucceeded(board.deletedBoardId));
  });

  socket.on("editBoard", board => {
    genericDispatch(updateBoardSucceeded(board));
  });

  socket.on("deleteBoardList", board => {
    genericDispatch(deleteBoardListSucceeded(board.deletedBoardListId));
  });

  socket.on("editBoardList", list => {
    genericDispatch(updateBoardListSucceeded(list));
  });

  /* Account related */

  socket.on("userJoinFailed", error => {
    genericDispatch(
      showNotification({
        type: "error",
        title: "Socket join failed",
        desc: error.errorMsg
      })
    );
  });

  socket.on("changeSettings", settings => {
    genericDispatch(setInitialUserSettings(settings.settings));
  });

  socket.on("resetPassword", () => {
    genericDispatch({ type: "LOGOUT" });
  });

  /* Reminders */

  socket.on("newDueReminder", (reminder) => {
    console.log(reminder)
    genericDispatch(showNotification({ type: "info", title: "Neue Erinnerung", desc: parseReadableDateTimeFromString(reminder.targetTime) }));
    genericDispatch(refreshElementReminder(reminder.elementId, reminder.reminderId));

    let times = 0;
    const blinking = setInterval(() => {
      if (times >= 4) clearInterval(blinking);
      times++;
      setFavicon(document, "favicon-red");
      setTimeout(() => setFavicon(document, "favicon"), 800);
    }, 1600);
  });
};
