import React, { Component } from 'react';
import { connect } from 'react-redux';
import { closeModal } from './../../actions/navigation';
import { MaterialIcons } from 'react-web-vector-icons';
import { modalCodes } from '../../utils/enums';
import { deleteMultipleElements } from '../../actions/element';

class DeleteConfirmModal extends Component {

  componentDidUpdate() {
    if (this.props.openModal === modalCodes.DELETE_CONFIRM_MODAL) {
      // Doesn't work properly without delay
      setTimeout(() => {
        this.confirmRef.focus();
      }, 100);
    }
  }

  _clickOutside = e => {
    if (e.target === e.currentTarget) {
      this.props.closeModal();
    }
  };

  _confirmDeletion = () => {
    const { token, deleteMultipleElements } = this.props;
    const { selectedElements } = this.props.modalData;
    deleteMultipleElements({ token: token }, selectedElements);
  };

  render() {
    if (!this.props.modalData) return null;
    const { selectedElements } = this.props.modalData;

    return (
      <div
        className={this.props.openModal === modalCodes.DELETE_CONFIRM_MODAL ? 'modalWrapper open' : 'modalWrapper'}
        onMouseDown={e => this._clickOutside(e)}
      >
        <div className="modalContent confirmLogoout">
          <h2>
            Sind Sie sicher, dass Sie{' '}
            {selectedElements && selectedElements.length > 1 ? (
              `diese ${selectedElements.length} Elemente`
            ) : (
              'dieses Element'
            )}{' '}
            löschen möchten?
          </h2>
          <div className="close" onClick={() => this.props.closeModal()}>
            <MaterialIcons name="close" size={18} />
          </div>
          <div className="actionItems material">
            <button ref={ref => this.confirmRef = ref} className="primary deleteColoring" onClick={this._confirmDeletion}>
              Ja, löschen
            </button>
            <button className="primary delete" onClick={() => this.props.closeModal()}>
              Nein, abbrechen
            </button>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  openModal: state.navigation.openModal,
  modalData: state.navigation.modalData,
  token: state.authentication.userData.token
});

const mapDispatchToProps = dispatch => ({
  closeModal: () => dispatch(closeModal()),
  deleteMultipleElements: (metadata, elementIds) => dispatch(deleteMultipleElements(metadata, elementIds))
});

export default connect(mapStateToProps, mapDispatchToProps)(DeleteConfirmModal);
