import React, { Component } from 'react';
import './Tagging.scss';
import { SuggestionItem } from './SuggestionItem';
import { connect } from 'react-redux';

class TagSuggestions extends Component {
  constructor(props) {
    super(props);

    this.state = { selected: 0, prevFilter: this.props.filter };
    this.me = React.createRef();
  }

  componentDidUpdate() {
    if (this.props.filter !== this.state.prevFilter) {
      this.setState({
        ...this.state,
        selected: 0,
        prevFilter: this.props.filter
      });
    }
  }

  getSelectedSuggestion = () => {
    this.setState({ ...this.state, selected: 0 });
    let ftl = this.filteredTagList();
    return ftl.length === 0 ? null : ftl[this.state.selected].tagname;
  };

  filteredTagList = () => {
    return this.props.tagList === null
      ? null
      : this.props.tagList.filter(tag => tag.tagname.startsWith(this.props.filter) || this.props.filter === null);
  };

  moveDown = () => {
    if (this.state.selected < this.filteredTagList().length - 1)
      this.setState({ ...this.state, selected: this.state.selected + 1 });
  };

  moveUp = () => {
    if (this.state.selected > 0) this.setState({ ...this.state, selected: this.state.selected - 1 });
  };

  _clickItem = i => {
    this.setState({ ...this.state, selected: i }, () => {
      this.props.clickEvent();
    });
  };

  constructClassName = matchingTagsAmount => {
    let baseName = 'tagSuggestions scrollable';
    let { visible, showFromTop } = this.props;
    if (visible) baseName += ' shown';
    if (showFromTop) baseName += ' showFromTop';
    if (matchingTagsAmount === 0) baseName += ' noBorder';
    return baseName;
  }

  render() {
    let { offset, showFromTop, lastTriggerKey } = this.props;
    let that = this;
    let TagList = this.filteredTagList().map((tag, i) => {
      return (
        <SuggestionItem
          clickEvent={this._clickItem}
          filter={this.props.filter}
          index={i}
          selected={i === this.state.selected ? true : false}
          key={tag.id}
          name={tag.tagname}
          parent={that.me}
          lastTriggerKey={lastTriggerKey}
        />
      );
    });

    return (
      <div
        className={this.constructClassName(TagList.length)}
        style={showFromTop ? { bottom: offset } : null}
        ref={this.me}
      >
        {TagList}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  tagList: state.element.tagList,
  tagError: state.element.tagError
});

export default connect(mapStateToProps, null, null, { forwardRef: true })(TagSuggestions);
