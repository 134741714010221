import React, { Component } from 'react';
import './SimpleInput.scss';
import TagSuggestions from '../Tagging/TagSuggestions';
import { MaterialIcons } from 'react-web-vector-icons';
import { setReference, getReference, ReferenceNames } from '../../utils/reference';
import { parseDateTimeFromInputString, parseDateTimeFromShortInputString, parseDateTimeFromVeryShortInputString } from '../../utils/parsing';

const defaultTagTriggerKey = '#';

export class SimpleInput extends Component {
  constructor(props) {
    super(props);

    this.state = {
      content: this.props.value ? this.props.value : '',
      helpBoxVisible: false,
      tagSuggestionsShown: false,
      suggestionFilter: '',
      allowSubmit: false
    };

    this.suggestions = React.createRef();
    this.internalRef = React.createRef();

    this.tagRegex = /(^|\s)(#[^ \n#]+)/gi;
    this.tagExcludedRegex = /(^|\s)(![^ \n!]+)/gi;
    this.linkRegex = /(^|\s)(https?:\/\/[^\s]+)/g;
    this.reminderRegex = /@([0-2]\d|[3][0-1])\.([0]\d|[1][0-2])\.([2][01]|[1][6-9])\d{2}(\/([0-1]\d|[2][0-3])(:[0-5]\d))/g; // @05.12.2020/18:00
    this.shortReminderRegex = /@(\S*)(\/([0-1]\d|[2][0-3])(:[0-5]\d))/g; // @morgen/18:00
    this.veryShortReminderRegex = /@[^\s/]*(?!\S)/g; // @morgen

    this.forcedUpdate = false;

    this.lastKeyBackspace = false;
    this.caretPosition = 0;
    this.lastHeight = 0;
    this.lastTriggerKey = "#";
  }

  componentDidMount() {
    this.updateTextAreaHeightOnUpdate();
  }

  getSnapshotBeforeUpdate() {
    const scrollView = getReference(ReferenceNames.ELEMENT_SCROLL_VIEW);
    if (scrollView) return scrollView.scrollTop;
    return null;
  }

  componentDidUpdate(_prevProps, prevState, snapshot) {
    this.updateTextAreaHeightOnUpdate();
    // To set the initial value once and then trigger re-render on parent
    if (this.props.forceSetInitialValue && !this.forcedUpdate) {
      const { returnFilter, updateEvent, plainUpdateEvent, allowSubmitChangeEvent } = this.props;
      if (plainUpdateEvent) plainUpdateEvent(this.state.content);
      if (updateEvent) updateEvent(returnFilter ? this.getFilterTypeParts() : this.getElementTypeParts());
      if (allowSubmitChangeEvent && (prevState.allowSubmit !== this.state.allowSubmit))
        allowSubmitChangeEvent(this.state.allowSubmit); // Needs to explicitly refer to this.state
      this.forcedUpdate = true;
    } else {
      this.forcedUpdate = false;
    }

    const { heightChangeEvent, resetToInitial, forceSetInitialValue } = this.props;
    
    if (heightChangeEvent) {
      const newHeight = this.internalRef.current ? this.internalRef.current.offsetHeight : 0;
      if (newHeight !== this.lastHeight) {
        this.props.heightChangeEvent(newHeight);
        this.lastHeight = newHeight;
      }
    }

    if (resetToInitial) this.caretPosition = 0;
    if (forceSetInitialValue) this.caretPosition = this.state.content.length;

    // Keep scroll position when typing
    const scrollView = getReference(ReferenceNames.ELEMENT_SCROLL_VIEW);
    if (scrollView) scrollView.scrollTop = snapshot;
  }

  static getDerivedStateFromProps(props, state) {
    if (props.resetToInitial) {
      // Reset textarea height
      const ir = props.inputRefName ? getReference(props.inputRefName) : false;
      if (ir && !props.singleLine) {
        // ir.value = '';
        // ir.style.height = '1px';
        // ir.style.height = 0 + ir.scrollHeight + 'px';
      }

      return {
        ...state,
        content: '',
        tagSuggestionsShown: false,
        suggestionFilter: '',
        allowSubmit: false
      };
    } else {
      if (props.forceSetInitialValue) {
        return {
          ...state,
          content: props.value ? props.value : '',
          tagSuggestionsShown: false,
          suggestionFilter: '',
          allowSubmit: props.value ? true : false
        };
      }
    }
    return null;
  }

  constructClassName = () => {
    let baseName = 'simpleInput';
    const { centered, className, seamless, disableInteraction, seamlessFocused } = this.props;
    if (centered) baseName += ' centered';
    if (className) baseName += ' ' + className;
    if (seamless) baseName += ' seamless';
    if (disableInteraction && !seamlessFocused) baseName += ' disableInteraction';
    // if (seamlessFocused) baseName += ' seamlessFocused';
    return baseName;
  };

  render() {
    const {
      showHelpIcon,
      singleLine,
      inputRef,
      inputRefName,
      placeholder,
      showClearBtn,
      borderless,
      tagSuggestionsFromTop,
      seamless,
      seamlessFocused
    } = this.props;
    return (
      <div ref={this.internalRef} className={this.constructClassName()}>
        <div className={borderless ? 'inputWrapper borderless' : 'inputWrapper'}>
          {singleLine ? (
            <input
              type="text"
              className={
                showHelpIcon ? (showClearBtn ? (
                  'inputContainer withHelpIconAndClear'
                ) : (
                  'inputContainer withHelpIcon'
                )) : (
                  'inputContainer'
                )
              }
              ref={inputRef ? inputRef : ref => inputRefName && ref && setReference(inputRefName, ref)}
              spellCheck="false"
              onChange={this._handleChange}
              onKeyDown={this._handleKeyDown}
              placeholder={placeholder}
              value={this.state.content}
              onBlur={this._onBlurEvent}
            />
          ) : (
            <textarea
              className={showHelpIcon ? 'inputContainer scrollable withHelpIcon' : 'inputContainer scrollable'}
              ref={inputRef ? inputRef : ref => inputRefName && ref && setReference(inputRefName, ref)}
              rows="1"
              spellCheck="false"
              onChange={this._handleChange}
              onKeyDown={this._handleKeyDown}
              placeholder={placeholder}
              value={this.state.content}
              onFocus={this._onFocusEvent}
              onBlur={this._onBlurEvent}
              readOnly={seamless ? (seamlessFocused ? false : true) : false}
              onMouseDown={this._onMouseDownEvent}
              onDoubleClick={this._onDoubleClickEvent}
            />
          )}
          {showClearBtn ? (
            <div className={this.state.content.length > 0 ? 'clearBtn shown' : 'clearBtn'} onClick={this._clearFilters}>
              <MaterialIcons name="clear" size={23} />
            </div>
          ) : null}
          {showHelpIcon ? (
            <div
              className="helpWrapper"
              onMouseEnter={() => this._mouseMoveSetHelp(true)}
              onMouseLeave={() => this._mouseMoveSetHelp(false)}
            >
              <MaterialIcons name="help" size={24} />
            </div>
          ) : null}
        </div>
        <TagSuggestions
          ref={this.suggestions}
          visible={this.state.tagSuggestionsShown}
          filter={this.state.suggestionFilter}
          clickEvent={this.chooseSuggestion}
          showFromTop={tagSuggestionsFromTop}
          offset={this.lastHeight}
          lastTriggerKey={this.lastTriggerKey}
        />
        {showHelpIcon ? <this.props.helpComponent helpShown={this.state.helpBoxVisible} /> : null}
      </div>
    );
  }

  _onFocusEvent = e => {
    // Prevent invisible focus on single 
    // const { seamless, seamlessFocused } = this.props;
    // if (seamless && !seamlessFocused) this.props.inputRef.current.blur();
    
    if (this.props.focusEvent) this.props.focusEvent(e);
  }

  _onBlurEvent = e => {
    if (this.props.blurEvent) this.props.blurEvent(e);
  };

  _onDoubleClickEvent = e => {
    if (this.props.doubleClickEvent && !this.props.seamlessFocused) {
      e.preventDefault();
      this.props.doubleClickEvent(e);
    } 
  }

  _onMouseDownEvent = e => {
    // To prevent text selection on double click
    if (this.props.doubleClickEvent && e.detail > 1 && !this.props.seamlessFocused) {
      e.preventDefault();
    }
  }

  _clearFilters = () => {
    this.setState({
      content: '',
      helpBoxVisible: false,
      tagSuggestionsShown: false,
      suggestionFilter: '',
      allowSubmit: false
    }, () => {
      this.lastTriggerKey = defaultTagTriggerKey;
    });
    this.props.clearAction();
  };

  updateTextAreaHeightOnUpdate = () => {
    const { singleLine, inputRef, inputRefName } = this.props;
    if (!singleLine) {
      if (inputRef) {
        // Maybe undo this again
        if (inputRef.current) {
          this.adjustTextAreaHeight(inputRef.current, true)
        } else {
          this.adjustTextAreaHeight(inputRef, true)
        }
      }
      if (inputRefName) this.adjustTextAreaHeight(getReference(inputRefName), true);
    }
  };

  _mouseMoveSetHelp = shown => {
    this.setState({ ...this.state, helpBoxVisible: shown });
  };

  _handleChange = e => {
    // Re-open selection when deleting characters
    let { selectionStart, selectionEnd } = e.target;
    let { content, tagSuggestionsShown, allowSubmit } = this.state;
    let sugState = {};
    if (selectionStart === selectionEnd && content.length > 1 && !tagSuggestionsShown && this.lastKeyBackspace) {
      let t = content.substring(0, selectionEnd).split(/\s/g);
      let t2 = t[t.length - 1];

      const tk = this.beginsWithTriggerKey(t2)
      if (tk.valid) {
        this.lastTriggerKey = tk.key;
        sugState = {
          tagSuggestionsShown: true,
          suggestionFilter: t2.substring(1, t2.length)
        };
      }
    }
    if (selectionStart === selectionEnd) this.caretPosition = selectionEnd;

    // if (e.target.tagName.toUpperCase() === "TEXTAREA")
    //   this.adjustTextAreaHeight(e, false);

    const prevAllowSubmit = allowSubmit;
    this.setState(
      { ...this.state, ...sugState, content: e.target.value, allowSubmit: e.target.value.length > 0 },
      () => {
        const { updateEvent, plainUpdateEvent, allowSubmitChangeEvent, returnFilter } = this.props;
        if (updateEvent) updateEvent(returnFilter ? this.getFilterTypeParts() : this.getElementTypeParts());
        if (plainUpdateEvent) plainUpdateEvent(this.state.content);
        if (allowSubmitChangeEvent && (prevAllowSubmit !== this.state.allowSubmit))
          allowSubmitChangeEvent(this.state.allowSubmit); // Needs to explicitly refer to this.state
      }
    );
  };

  adjustTextAreaHeight = (e, direct) => {
    let t = direct ? e : e.target;

    // console.log(t)
    // console.log(t.scrollHeight)
    if (t.scrollHeight === 0) return; // When element not in view
    t.style.height = '1px';
    t.style.height = 1 + t.scrollHeight + 'px';
  };

  _handleKeyDown = e => {
    if (this.state.tagSuggestionsShown === true) {
      switch (e.key) {
        case ' ': {
          this.setState({
            ...this.state,
            tagSuggestionsShown: false,
            suggestionFilter: ''
          }, () => {
            this.lastTriggerKey = defaultTagTriggerKey;
          });
          break;
        }
        case 'Backspace': {
          if (this.state.suggestionFilter.length === 0) {
            this.setState({
              ...this.state,
              tagSuggestionsShown: false,
              suggestionFilter: ''
            }, () => {
              this.lastTriggerKey = defaultTagTriggerKey;
            });
          } else {
            this.setState({
              ...this.state,
              suggestionFilter: this.state.suggestionFilter.substring(0, this.state.suggestionFilter.length - 1)
            });
          }
          break;
        }
        case 'Enter': {
          e.preventDefault();
          this.chooseSuggestion(e);
          break;
        }
        case 'Tab': {
          e.preventDefault();
          this.chooseSuggestion(e, true);
          break;
        }
        case 'ArrowDown': {
          e.preventDefault();
          this.suggestions.current.moveDown();
          break;
        }
        case 'ArrowUp': {
          e.preventDefault();
          this.suggestions.current.moveUp();
          break;
        }
        case 'Escape': {
          e.preventDefault();
          this.setState({
            ...this.state,
            tagSuggestionsShown: false,
            suggestionFilter: ''
          }, () => {
            this.lastTriggerKey = defaultTagTriggerKey;
          });
          break;
        }
        default: {
          let k = e.which;
          if (
            k === 20 /* Caps lock */ ||
            k === 16 /* Shift */ ||
            k === 9 /* Tab */ ||
            k === 27 /* Escape Key */ ||
            k === 17 /* Control Key */ ||
            k === 91 /* Windows Command Key */ ||
            k === 19 /* Pause Break */ ||
            k === 18 /* Alt Key */ ||
            k === 93 /* Right Click Point Key */ ||
            (k >= 35 && k <= 40) /* Home, End, Arrow Keys */ ||
            k === 45 /* Insert Key */ ||
            (k >= 33 && k <= 34) /*Page Down, Page Up */ ||
            (k >= 112 && k <= 123) /* F1 - F12 */ ||
            (k >= 144 && k <= 145) ||
            e.keyCode === 90
          ) {
            break;
          } else {
            this.setState({
              ...this.state,
              suggestionFilter: this.state.suggestionFilter + e.key.toLowerCase()
            });
            break;
          }
        }
      }
    } else {
      if (e.key === 'Enter' && e.shiftKey) {
        return;
      } else {
        if (e.key === 'Enter') {
          this.regularSubmitInput(e);
        }
      }
    }

    // Show for first time on #
    const { content } = this.state;

    if (
      this.isTriggerKey(e.nativeEvent.key) &&
      (this.caretPosition === 0 ||
        content.charAt(this.caretPosition - 1) === ' ' ||
        content.charAt(this.caretPosition - 1) === '\n')
    ) {
      this.setState({ ...this.state, tagSuggestionsShown: true });
    }
    this.lastKeyBackspace = e.nativeEvent.key === 'Backspace' ? true : false;
  };

  isTriggerKey = key => {
    const { returnFilter } = this.props;
    let retVal = false;
    if (returnFilter) {
      retVal = (key === '#' || key === '!') ? true : false;
    } else {
      retVal = key === '#' ? true : false;
    }
    if (retVal) this.lastTriggerKey = key;
    return retVal;
  }

  beginsWithTriggerKey = input => {
    const { returnFilter } = this.props;
    if (returnFilter) {
      return { valid: (input.startsWith('#') || input.startsWith('!')) ? true : false, key: input.charAt(0) }
    } else {
      return { valid: input.startsWith('#') ? true : false, key: input.charAt(0) }
    }
  }

  getElementTypeParts = () => {
    let data = this.state.content;
    let title = data.replace(this.tagRegex, ''); // Remove tags
    title = title.replace(this.linkRegex, ''); // Remove links
    title = title.replace(this.reminderRegex, ''); // Remove reminders
    title = title.replace(this.shortReminderRegex, ''); // Remove reminders
    title = title.replace(this.veryShortReminderRegex, ''); // Remove reminders
    title = title.replace('#', ''); // Remove single #
    title = title.replace(/^\s+|\s+$/g, ''); // Remove leading and trailing whitespace
    title = title.replace(/ +(?= )/g, ''); // Remove double spaces

    let tags = [];
    let tagMatches = this.tagRegex.exec(data);
    while (tagMatches != null) {
      let tag = tagMatches[2]; // Use second capturing group
      tags.push(tag.substring(1, tag.length)); // Remove hashtag
      tagMatches = this.tagRegex.exec(data);
    }

    let links = [];
    let linkMatches = this.linkRegex.exec(data);
    while (linkMatches != null) {
      links.push(linkMatches[2]); // Use second capturing group
      linkMatches = this.linkRegex.exec(data);
    }

    let reminders = [];
    let dateMatches = this.reminderRegex.exec(data);
    while (dateMatches != null) {
      reminders.push(parseDateTimeFromInputString(dateMatches[0]));
      dateMatches = this.reminderRegex.exec(data);
    }
    let dateMatchesShort = this.shortReminderRegex.exec(data);
    while (dateMatchesShort != null) {
      const res = parseDateTimeFromShortInputString(dateMatchesShort[0]);
      if (res) reminders.push(res);
      dateMatchesShort = this.shortReminderRegex.exec(data);
    }
    let dateMatchesVeryShort = this.veryShortReminderRegex.exec(data);
    while (dateMatchesVeryShort != null) {
      const res = parseDateTimeFromVeryShortInputString(dateMatchesVeryShort[0]);
      if (res) reminders.push(res);
      dateMatchesVeryShort = this.veryShortReminderRegex.exec(data);
    }
    // console.log(reminders);

    return {
      title: title ? title : null,
      tags: tags,
      links: links,
      reminders
    };
  };

  getFilterTypeParts = () => {
    let data = this.state.content;
    let title = data.replace(this.tagRegex, ''); // Remove tags
    title = title.replace(this.tagExcludedRegex, ''); // Remove excluded tags
    title = title.replace(this.linkRegex, ''); // Remove links
    title = title.replace('#', ''); // Remove single #
    title = title.replace('!', ''); // Remove single !
    title = title.replace(/^\s+|\s+$/g, ''); // Remove leading and trailing whitespace
    title = title.replace(/ +(?= )/g, ''); // Remove double spaces

    let tagsIncluded = [];
    let tagMatches = this.tagRegex.exec(data);
    while (tagMatches != null) {
      let tag = tagMatches[2]; // Use second capturing group
      tagsIncluded.push(tag.substring(1, tag.length)); // Remove hashtag
      tagMatches = this.tagRegex.exec(data);
    }

    let tagsExcluded = [];
    let tagExMatches = this.tagExcludedRegex.exec(data);
    while (tagExMatches != null) {
      let tag = tagExMatches[2]; // Use second capturing group
      tagsExcluded.push(tag.substring(1, tag.length)); // Remove hashtag
      tagExMatches = this.tagExcludedRegex.exec(data);
    }

    return {
      queryString: title ? title : null,
      tagsIncluded,
      tagsExcluded
    };
  };

  isLastCharEmpty = () => {
    return this.state.content.slice(-1) === ' ' || this.state.content === '';
  };

  chooseSuggestion = (e, ignoreSubmit) => {
    let selectedSuggestion = this.suggestions.current.getSelectedSuggestion();
    if (selectedSuggestion === null) {
      // Regular submit since no suggestions
      if (!ignoreSubmit) this.regularSubmitInput(e);
    } else {
      // Suggestion is visible and was selected
      const { content } = this.state;
      const spaceAtEnd = content.charAt(this.caretPosition) === " ";
      const missingPart = selectedSuggestion.substring(this.state.suggestionFilter.length, selectedSuggestion.length) + (spaceAtEnd ? '' : ' ');
      const newContent = [content.slice(0, this.caretPosition), missingPart, content.slice(this.caretPosition)].join('');

      this.setState(
        {
          ...this.state,
          content: newContent,
          tagSuggestionsShown: false,
          suggestionFilter: ''
        },
        () => {
          const { returnFilter, updateEvent, plainUpdateEvent } = this.props;
          if (updateEvent) updateEvent(returnFilter ? this.getFilterTypeParts() : this.getElementTypeParts());
          if (plainUpdateEvent) plainUpdateEvent(this.state.content);

          const { singleLine, inputRef, inputRefName } = this.props;
          const input = inputRef ? inputRef.current : (inputRefName ? getReference(inputRefName) : null);

          const newCaretPos = this.caretPosition + missingPart.length;
          input.setSelectionRange(newCaretPos, newCaretPos);
          this.caretPosition = newCaretPos;
          
          if (!singleLine) {
            if (inputRef) this.adjustTextAreaHeight(inputRef.current, true);
            if (inputRefName) this.adjustTextAreaHeight(getReference(inputRefName), true);
          }
          this.lastTriggerKey = defaultTagTriggerKey;
        }
      );
    }
  };

  regularSubmitInput = e => {
    e.preventDefault();
    if (this.props.loadingFunction) this.props.loadingFunction(true);
    const { returnFilter } = this.props;
    let parts = returnFilter ? this.getFilterTypeParts() : this.getElementTypeParts();

    if (this.props.updateEvent) this.props.updateEvent(parts);
    if (this.props.plainUpdateEvent) this.props.plainUpdateEvent(this.state.content);

    if (this.props.returnPlainContent) {
      this.props.submitEvent(this.state.content);
    } else {
      this.props.submitEvent(this.props.metadata, parts);
    }
  };
}
