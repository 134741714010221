import React, { Component } from 'react';
import BoardColumn from './BoardColumn';
import { AddColumnCard } from './AddColumnCard';
import { connect } from 'react-redux';
import { boardDetailsRequested } from '../../actions/board';
import { DndProvider } from 'react-dnd';
import HTML5Backend from 'react-dnd-html5-backend';
import { DroppableList } from '../Dragging/DroppableList';
import CustomDragLayer from '../Dragging/CustomDragLayer';

class BoardLists extends Component {
  componentDidMount() {
    this.requestBoardDetails();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.activeBoardId !== this.props.activeBoardId) {
      this.requestBoardDetails();
    }
  }

  requestBoardDetails = () => {
    this.props.boardDetailsRequested(
      {
        token: this.props.token,
        language: this.props.language
      },
      this.props.activeBoardId
    );
  };

  render() {
    let { lists } = this.props;
    let columnList = lists
      ? Object.keys(lists).map(l => {
          let { id, emoji, listName, queryString } = lists[l];
          return (
            <DroppableList key={id} listId={id} queryString={queryString}>
              <BoardColumn id={id} emoji={emoji} name={listName} queryString={queryString} />
            </DroppableList>
          );
        })
      : null;

    return (
      <DndProvider backend={HTML5Backend}>
        {columnList}
        <div><AddColumnCard /></div>
        <CustomDragLayer />
      </DndProvider>
    );
  }
}

const mapStateToProps = state => ({
  token: state.authentication.userData.token,
  language: state.preferences.language,
  lists: state.board.lists
});

const mapDispatchToProps = dispatch => ({
  boardDetailsRequested: (metadata, boardId) => dispatch(boardDetailsRequested(metadata, boardId))
});

export default connect(mapStateToProps, mapDispatchToProps)(BoardLists);
