import React, { Component } from 'react';
import './NotFound.scss';

export class NotFound extends Component {
  render() {
    return (
      <div className="notFound">
        <img src="/images/tagstack-worried.png" alt="404" width={400} />
        <h1>Ohje, Fehler 404</h1>
        <p>Die angeforderte Seite konnte leider nicht gefunden werden</p>
      </div>
    );
  }
}
