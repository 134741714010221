import React, { Component } from "react";

export class SuggestionItem extends Component {
  constructor(props) {
    super(props);

    this.me = React.createRef();
  }

  componentDidUpdate() {
    if (this.props.selected === true) {
      let parent = this.props.parent.current.getBoundingClientRect();

      let rect = this.me.current.getBoundingClientRect();
      let elemTop = rect.top - parent.top;
      let elemBottom = rect.bottom - parent.top;

      let outTop = elemTop <= 0;
      let isVisible = elemTop >= 0 && elemBottom <= 240;

      let { current } = this.me;
      if (!isVisible) {
        if (outTop) {
          current.parentNode.scrollTop = current.offsetTop
        } else {
          current.parentNode.scrollTop = current.offsetTop - (current.parentNode.clientHeight - current.clientHeight)
        }
      }
    }
  }

  render() {
    const { name, filter, index, lastTriggerKey } = this.props;
    let matchingPart = name.substring(0, filter.length)
    let missingPart = name.substring(filter.length, name.length);
    return (
      <div
        onClick={() => this.props.clickEvent(index)}
        ref={this.me}
        className={
          this.props.selected === true
            ? "suggestionItem selected"
            : "suggestionItem"
        }
      >
        <span className="matching">{lastTriggerKey + matchingPart}</span>
        <span>{missingPart}</span>
      </div>
    );
  }
}
