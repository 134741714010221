import { put, takeEvery, select } from "redux-saga/effects";
import { generateRandomId } from "./../utils/generations";

export function* watchAllNotificationActions() {
  yield takeEvery("SHOW_NOTIFICATION_REQUESTED", beginShowNotification);
}

const getNotifications = state => state.notification.list;
export function* beginShowNotification(action) {
    let id = generateRandomId();
    const nlist = yield select(getNotifications);
    while (nlist[id]) {
        id = generateRandomId();
    }
    yield put({ type: 'ADD_NOTIFICATION', notification: { ...action.notification, id: id } });
}