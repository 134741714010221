const refs = {}

export const ReferenceNames = Object.freeze({
  ADD_ELEMENT_INPUT: 1, // Not 0 because then the name is considered false
  SEARCHBAR_INPUT: 2,
  ELEMENT_SCROLL_VIEW: 3,
  ELEMENT_LIST: 4
});

export const setReference = (name, value) => {
  refs[name] = value;
}

export const getReference = name => refs[name];