export const updateFilterRequested = filter => ({
    type: "UPDATE_FILTER_REQUESTED",
    filter: filter
});

export const updateFilter = filter => ({
    type: "UPDATE_FILTER", 
    filter: filter
});

export const resetAllFilters = triggerRequest => ({
    type: "RESET_ALL_FILTERS",
    triggerRequest: triggerRequest
});