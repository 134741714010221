import React, { Component } from "react";

export class FilterItem extends Component {
    render() {
        return (
            <div className="filterItem">
                <span className="title">{this.props.title}</span><br></br>
                <span className="content">{this.props.content}</span>
            </div>
        )
    }
}