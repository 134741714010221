import React from "react";
import { UploadedFile } from "./UploadedFile";

export const FileUploadBar = ({ visible, offset, uploadedFiles }) => {

  const uploadedFileList = Object.keys(uploadedFiles).map((file, i) => {
    return uploadedFiles[file].assignedToElement ? null : (
      <UploadedFile
        id={file}
        file={uploadedFiles[file]}
        key={i}
      />
    );
  });

  return (
    <div className={visible ? "fileUploadBar shown" : "fileUploadBar"} style={{ bottom: offset }}>
      {uploadedFileList}
    </div>
  )
}