import React from 'react';
import { useSelector } from 'react-redux';
import { convertFileSize } from '../../../utils/conversions';
import { maxFreeTotalUploaded, maxFreeTotalUploadedString, maxPaidTotalUploaded, maxPaidTotalUploadedString } from '../../../utils/limitations';

export const SubscriptionSetting = () => {
  const isPaidUser = useSelector(state => state.authentication.userData.isPaidUser);
  const storageUsed = useSelector(state => state.authentication.userData.storageUsed);
  const readableStorageUsed = convertFileSize(storageUsed);
  const percentageUsed = ((storageUsed / (isPaidUser ? maxPaidTotalUploaded : maxFreeTotalUploaded)) * 100).toFixed(2);

  return (
    <div className="settingContent material">
      <h5>Dein Abonnement</h5>
      <div className="separator" />

      <div className="parallelBox">
        <div className="settingName">
          <span>Abonnement:</span>
        </div>
        <div className="settingValue">{isPaidUser ? <span className="tagstackPlus">Tagstack Plus</span> : <span>Tagstack Basic</span>}</div>
      </div>

      <div className="parallelBox">
        <div className="settingName">
          <span>Speichernutzung:</span>
        </div>
        <div className="settingValue"><span>{`${readableStorageUsed} belegt von ${isPaidUser ? maxPaidTotalUploadedString : maxFreeTotalUploadedString} (${percentageUsed}%)`}</span></div>
      </div>
    </div>
  );
};
