import React, { Component } from 'react';
import { FilterItem } from './FilterItem';
import './FilterArea.scss';
import { connect } from 'react-redux';
import { retrieveElements, retrieveUntaggedElements } from './../../actions/element';
import { withRouter } from 'react-router-dom';

class FilterArea extends Component {
  componentDidUpdate(prevProps) {
    // Submit search whenever filter changes
    if (!this.isFilterEqual(prevProps.filter, this.props.filter) && this.props.triggerRequest) {
      switch (this.props.location.pathname.split('/')[1]) {
        case 'timeline':
        case 'query': {
          const { triggerRequest, ...rest } = this.props.filter;
          this.props.retrieveElements(
            {
              token: this.props.token,
              workspaceId: this.props.workspaceId,
              language: this.props.language,
              lastId: this.props.lastId,
              filter: rest
            },
            false
          );
          break;
        }
        case 'untagged': {
          const { retrieveUntaggedElements, token, workspaceId, language } = this.props;
          const { triggerRequest, tagsIncluded, tagsExcluded, ...rest } = this.props.filter;
          retrieveUntaggedElements(
            {
              token: token,
              workspaceId: workspaceId,
              language: language,
              lastId: null,
              filter: rest
            },
            false
          );
          break;
        }
        default: {
        }
      }
    }
  }

  // Compares previous filter to new filter
  isFilterEqual = (prevFilter, filter) => {
    // Comparing arrays the simple way does not work, therefore this complicated solution
    const inclEqual = prevFilter.tagsIncluded.length === filter.tagsIncluded.length && prevFilter.tagsIncluded.every((value, index) => value === filter.tagsIncluded[index]);
    const exclEqual = prevFilter.tagsExcluded.length === filter.tagsExcluded.length && prevFilter.tagsExcluded.every((value, index) => value === filter.tagsExcluded[index]);
    return (prevFilter.queryString === filter.queryString && inclEqual && exclEqual);
  }

  render() {
    if (this.props.hidden) return null;

    const { queryString, tagsIncluded, tagsExcluded } = this.props.filter;
    return (
      <div className="filterArea">
        {queryString && <FilterItem title="Im Titel enthalten" content={'"' + queryString + '"'} />}
        {tagsIncluded &&
        tagsIncluded.length > 0 && (
          <FilterItem title="Tags enthalten" content={tagsIncluded.map(tag => '#' + tag + ' ')} />
        )}
        {tagsExcluded &&
        tagsExcluded.length > 0 && (
          <FilterItem title="Tags ausgenommen" content={tagsExcluded.map(tag => '!' + tag + ' ')} />
        )}
        {!queryString && (tagsIncluded && tagsIncluded.length === 0) && (tagsExcluded && tagsExcluded.length === 0) ? (
          <p className="noFilter">
            Aktuell sind keine Filter aktiv. Du kannst deine gespeicherten Elemente mit Hilfe der unten angezeigten
            Filtertypen filtern.
          </p>
        ) : null}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  lastId: state.element.lastId,
  filter: state.filter,
  token: state.authentication.userData.token,
  workspaceId: state.authentication.userData.workspaceId,
  language: state.preferences.language,
  triggerRequest: state.filter.triggerRequest
});

const mapDispatchToProps = dispatch => ({
  retrieveElements: (metadata, append) => dispatch(retrieveElements(metadata, append)),
  retrieveUntaggedElements: (metadata, append) => dispatch(retrieveUntaggedElements(metadata, append))
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(FilterArea));
