import React, { Component } from "react";
import { MaterialIcons } from "react-web-vector-icons";
import { convertFileSize, convertDateTime } from "../../../utils/conversions";

export class FileAttachment extends Component {

  workaroundDownload() {
    fetch(this.props.fileUrl)
    .then(resp => resp.blob())
    .then(blob => {
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.style.display = 'none';
      a.href = url;
      a.download = this.props.fileName;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
    })
    .catch(() => console.log("error downloading file"));
  }

  _removeFile = e => {
    e.stopPropagation();
    this.props.removeEvent(this.props.id);
  }

  render() {
    let { fileName, fileLastModified, fileSize, editMode } = this.props;
    let lastChangedDate = new Date(fileLastModified);
    //onClick={() => window.open(fileUrl, '_blank')}

    return (
      <div className="attachment" onClick={() => this.workaroundDownload()}>
        <div className="iconContainer">
          <div className="icon"><MaterialIcons name="insert-drive-file" color={"var(--onPrimaryStrong)"} size={24} /></div>
        </div>
        <div className="text">
            <div className="header">
                <span className="filename">{fileName}</span>
            </div>
            <div className="content">
                <span className="preview">{convertFileSize(fileSize)} - {convertDateTime(lastChangedDate)}</span>
            </div>
        </div>
        <div className={editMode === true ? "fixed-options visible" : "fixed-options" }>
          <div className="actionIcon" onClick={this._removeFile}><MaterialIcons name="delete" size={20} /></div>
        </div>
      </div>
    );
  }
}