import React, { Component } from "react";
import "./ElementList.scss";
import { connect } from "react-redux";
import { MaterialIcons } from "react-web-vector-icons";
import { LinkAttachment } from "./Attachments/LinkAttachment";
import { FileAttachment } from "./Attachments/FileAttachment";
import { SimpleInput } from "../SimpleInput/SimpleInput";
// import { ArticleAttachment } from "./Attachments/ArticleAttachment";
import {
  updateElementRequested,
  addFilesForUpload,
  uploadFileRequested,
  resetUploadedFiles,
  selectElement,
  unselectElement,
  setEditElementStatus,
  // revertableDeleteElement,
  deleteElement,
  deleteElementSucceeded
} from "./../../actions/element";
import { LoadingBar } from "../Loading/LoadingBar";
import UploadedFile from "../Modals/SubComponents/UploadedFile";
import { ImageAttachment } from "./Attachments/ImageAttachment";
import { openModal } from "../../actions/navigation";
import { getReference, ReferenceNames } from "../../utils/reference";
import { modalCodes } from "../../utils/enums";
import { maxFreeTotalUploaded, maxFreeTotalUploadedString, maxFreeUploadSize, maxFreeUploadSizeString, maxPaidTotalUploaded, maxPaidTotalUploadedString, maxPaidUploadSize, maxPaidUploadSizeString } from "../../utils/limitations";
import { Reminder } from "./Attachments/Reminder";

class Element extends Component {
  constructor(props) {
    super(props);

    this.inputRef = React.createRef();
    this.fileInputRef = React.createRef();
    this.initialFocus = true;
    this.uploadedFileId = 0;
    this.jumpToInputEnd = false;
    this.is_Mounted = false;

    let isDone = false;
    Object.keys(props.tags.data).forEach(t => {
      if (!isDone && props.tags.data[t].tagname === "done") isDone = true;
    });

    this.state = {
      loadingBarShown: false,
      isDone: isDone,
      setDoneLocally: { valid: false },
      inputData: {
        title: this.props.title,
        tags: [],
        links: [],
        dates: [],
        reminders: []
      },
      // This initially holds all tags/links but can temporarily be modified
      // (e.g. by deletion) without altering the global state
      tempData: {
        tags: Object.assign({}, props.tags.data),
        links: Object.assign({}, props.links.data),
        files: Object.assign({}, props.files.data),
        reminders: Object.assign({}, props.reminders.data)
      },
      resetTitle: false,
      editMode: false,
      copyMouseOver: false,
      copiedMsgShown: false,
      animateOut: false
    };
    this.nativeInputValueSetter = Object.getOwnPropertyDescriptor(
      window.HTMLInputElement.prototype,
      "value"
    ).set;
    this.handleKeyDown = this.handleKeyDown.bind(this);
  }

  static getDerivedStateFromProps(props, state) {
    // let editMode = props.editingElement === props.id;
    let isDone = false;
    Object.keys(props.tags.data).forEach(t => {
      if (!isDone && props.tags.data[t].tagname === "done") isDone = true;
    });
    if (state.setDoneLocally.valid) {
      // This syntax to avoid warning due to passed reference
      isDone = state.setDoneLocally.value ? true : false;
    }

    if (!state.editMode) {
      return {
        ...state,
        loadingBarShown: false,
        isDone: isDone,
        setDoneLocally: { valid: false },
        tempData: {
          tags: Object.assign({}, props.tags.data),
          links: Object.assign({}, props.links.data),
          files: Object.assign({}, props.files.data),
          reminders: Object.assign({}, props.reminders.data)
        },
        inputData: {
          title: props.title,
          tags: [],
          links: [],
          dates: [],
          reminders: []
        }
      };
    }
    return null;
  }

  componentDidMount() { 
    this.is_Mounted = true;
  }
  
  componentWillUnmount() {
     this.is_Mounted = false;
  }

  getSnapshotBeforeUpdate() {
    const scrollView = getReference(ReferenceNames.ELEMENT_SCROLL_VIEW);
    if (scrollView) return scrollView.scrollTop;
    return null;
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if ((prevState.editMode && !this.state.editMode) || (this.props.title !== prevProps.title)) {
      this.initialFocus = true;
      this.setState({ ...this.state, resetTitle: true });
    }

    if (this.state.resetTitle) this.setState({ ...this.state, resetTitle: false });

    if (!prevProps.filterNotMatched && this.props.filterNotMatched) {
      this.setState({ ...this.state, animateOut: true }, () => {
        setTimeout(() => {
          this.props.deleteElementSucceeded(this.props.id);
        }, 640); // 400 wait 240 animation
      });
    }

    // Re-render causes this components height to temporarily change to 0 and therefore the parents scroll-position
    // changes by the height of this component, resulting in annoying scrolling bugs. Therefore recover previous scroll position
    const scrollView = getReference(ReferenceNames.ELEMENT_SCROLL_VIEW);
    if (scrollView) scrollView.scrollTop = snapshot;
  }

  _setEditMode = activated => {
    // To prevent state update on unmounted component (potential memory leak)
    // e.g. when board element is updated and no longer matches the list but
    // this event still is being called
    if (!this.is_Mounted) return; 

    this.setState({ ...this.state, editMode: activated }, () => {
      // Delay so the ShortcutHandler still has the previous values when executed
      setTimeout(() => { this.props.setEditElementStatus(activated); }, 30);
    });

    if (activated) {
      document.addEventListener("keydown", this.handleKeyDown);
    } else {
      document.removeEventListener("keydown", this.handleKeyDown);
    }
  };

  handleKeyDown(e) {
    if (!e.key) return;
    if (e.key === "Escape") this._setEditMode(false);
  }

  _toggleTodo = () => {
    let { title } = this.state.inputData;
    let { tags, links, files, reminders } = this.state.tempData;

    let isDone = false;
    let oldTags = Object.keys(tags)
      .filter(t => {
        if (!isDone && tags[t].tagname === "done") isDone = true;
        if (tags[t].tagname === "done" || tags[t].tagname === "todo")
          return false;
        return true;
      })
      .map(t => tags[t].tagname);

    let newTags = isDone ? ["todo"] : ["done"];
    let oldLinks = Object.keys(links).map(l => links[l].url);
    let oldFiles = Object.keys(files).map(f => files[f].id);
    let oldReminders = Object.keys(reminders).map(f => reminders[f].targetTime);

    // // Combine existing data with new data from input field
    let finalData = {
      elementId: this.props.id,
      title: title,
      tags: [...newTags, ...oldTags],
      links: oldLinks,
      files: oldFiles,
      reminders: oldReminders
    };

    this.props.updateElementRequested(
      {
        token: this.props.token,
        language: this.props.language,
        workspaceId: this.props.workspaceId
      },
      finalData
    );

    // For instant feedback
    this.setState({
      ...this.state,
      setDoneLocally: { value: !this.state.isDone, valid: true }
    });
  };

  _saveModifiedElement = () => {
    this.setState({ ...this.state, loadingBarShown: true });

    const { title, tags: newTags, links: newLinks, reminders: newReminders } = this.state.inputData;
    const { tags, links, files, reminders } = this.state.tempData;

    const oldTags = Object.keys(tags).map(t => tags[t].tagname);
    const oldLinks = Object.keys(links).map(l => links[l].url);
    const oldFiles = Object.keys(files).map(f => files[f].id);
    const oldReminders = Object.keys(reminders).map(f => reminders[f].targetTime);

    // Combine existing data with new data from input field
    const finalData = {
      elementId: this.props.id,
      title: title,
      tags: [...newTags, ...oldTags],
      links: [...newLinks, ...oldLinks],
      files: oldFiles,
      reminders: [...newReminders, ...oldReminders]
    };

    this.props.updateElementRequested(
      {
        token: this.props.token,
        language: this.props.language,
        workspaceId: this.props.workspaceId,
        resultHandler: this.updateResultHandler
      },
      finalData
    );
  };

  updateResultHandler = success => {
    if (success) {
      this._setEditMode(false);
    } else {
      this.setState({ ...this.state, loadingBarShown: false });
    }
  }

  _toggleOptionDropdown = e => {
    let nx = e.clientX;
    let ny = e.clientY;
    let fromTop = true;

    const options = [{ 
      name: "Auswählen",
      action: () => this.props.selectElement(this.props.id)
    }, {
      name: "Datei anhängen",
      action: () => this.fileInputRef.current.click()
    }, {
      name: "Löschen",
      action: () => this._deleteElement()
    }];

    const contextMenuHeight = options.length * 44 + 12; // 3 = option length, 44 = item height, 12 = padding
    if (window.innerHeight < e.clientY + contextMenuHeight) {
      nx -= 160;
      ny -= contextMenuHeight;
      fromTop = false;
    }

    const contextOptions = {
      open: true, x: nx, y: ny, fromTop: fromTop,
      options: options,
    }
    if (this.props.boardElement) contextOptions.options.splice(0, 1); // Do not allow selection in board view
    this.props.openModal(modalCodes.ELEM_CONTEXT_MENU_MODAL, contextOptions);
  };

  _deleteElement = () => {
    this.props.deleteElement({ token: this.props.token }, this.props.id);
  };

  _updateInputContent = data => {
    this.setState({ ...this.state, inputData: data });
  };

  _removeExistingTag = tagId => {
    this.inputRef.current.focus();

    let newTagData = this.state.tempData.tags;
    delete newTagData[tagId];
    this.setState({
      ...this.state,
      tempData: {
        ...this.state.tempData,
        tags: newTagData
      }
    });
  };

  _removeExistingLink = linkId => {
    this.inputRef.current.focus();

    let newLinkData = this.state.tempData.links;
    delete newLinkData[linkId];
    this.setState({
      ...this.state,
      tempData: {
        ...this.state.tempData,
        links: newLinkData
      }
    });
  };

  _removeExistingFile = fileId => {
    this.inputRef.current.focus();

    let newFileData = this.state.tempData.files;
    delete newFileData[fileId];
    this.setState({
      ...this.state,
      tempData: {
        ...this.state.tempData,
        files: newFileData
      }
    });
  };

  _removeExistingReminder = reminderId => {
    this.inputRef.current.focus();

    let newReminderData = this.state.tempData.reminders;
    delete newReminderData[reminderId];
    this.setState({
      ...this.state,
      tempData: {
        ...this.state.tempData,
        reminders: newReminderData
      }
    });
  };

  _filterByTag = (e, tagName) => {
    // Return if there is no searchbar e.g. in list view
    const searchbar = getReference(ReferenceNames.SEARCHBAR_INPUT);
    if (!searchbar) return;

    // Do not filter when remove icon is clicked
    if (e.target.tagName.toLowerCase() === "i") {
      return;
    }

    // Set searchbar value (react overrides regular input value setter)
    this.nativeInputValueSetter.call(
      searchbar,
      `#${tagName}`
    );
    let ev2 = new Event("input", { bubbles: true });
    searchbar.dispatchEvent(ev2);
  };

  _onFileChange = e => {
    let files = Array.from(e.target.files);
    let convFiles = {};

    // Reformat files
    files.forEach(file => {
      // Check if storage limit reached before upload
      const uploadLimit = this.props.isPaidUser ? maxPaidTotalUploaded : maxFreeTotalUploaded;
      if (this.props.storageUsed >= uploadLimit) {
        if (this.props.isPaidUser) {
          this.props.addFileError(`Die maximale Auslastung deines verfügbaren Speicherplatzes von ${maxPaidTotalUploadedString} wurde erreicht.`);
          return;
        } else {
          this.props.addFileError(`Die maximale Auslastung deines verfügbaren Speicherplatzes von ${maxFreeTotalUploadedString} wurde erreicht. 
            Upgrade auf Tagstack Plus um deinen verfügbaren Speicherplatz auf ${maxPaidTotalUploadedString} zu erweitern.`);
          return;
        }
      }

      // Max filesize check
      if (this.props.isPaidUser) {
        if (file.size > maxPaidUploadSize) {
          this.props.addFileError(`Die ausgewählte Datei überschreitet die maximale Dateigröße von ${maxPaidUploadSizeString}.`);
          return;
        }
      } else {
        if (file.size > maxFreeUploadSize) {
          this.props.addFileError(`Die ausgewählte Datei überschreitet die maximale Dateigröße von ${maxFreeUploadSizeString}. 
          Upgrade auf Tagstack Plus um Dateien bis zu ${maxPaidUploadSizeString} hochladen zu können.`);
          return;
        }
      }

      convFiles["e" + ++this.uploadedFileId] = {
        fileName: file.name,
        fileType: file.type,
        fileSize: file.size,
        lastModified: file.lastModified,
        originalFile: file,
        assignedToElement: this.props.id
      };
    });

    // Add to state
    this.props.addFilesForUpload(convFiles);

    // Begin uploading each
    Object.keys(convFiles).forEach(f => {
      this.props.uploadFileRequested(
        {
          token: this.props.token,
          id: f
        },
        convFiles[f]
      );
    });
  };

  handleElementPress = e => {
    if (this.props.disableSelect) return;
    if (e.button === 0 || e.button === 2) { // Left and right click
      const {
        selectElement,
        unselectElement,
        id,
        selectionMode,
        isSelected
      } = this.props;
      if (selectionMode) {
        if (isSelected) {
          unselectElement(id);
        } else {
          selectElement(id);
        }
      }
    }
  };

  handleElementRightClick = e => {
    e.preventDefault();
    if (!this.props.selectionMode) this._toggleOptionDropdown(e)
  }

  copyToClipboard = () => {
    const { title } = this.props;
    navigator.clipboard.writeText(title);
    this.setState({ ...this.state, copiedMsgShown: true });
  }

  handleActionItemAnim = ref => {
    if (ref) ref.classList.add('focused');
    setTimeout(() => {
      if (ref) ref.classList.remove('focused');
    }, 80);
  }

  handleEnterEditMode = () => {
    // TODO: Figure something for boardview out
    if (!this.props.boardElement) {
      // If other element is being edited -> exit first
      // Not checking for editingElement from state to prevent re-render of all elements 
      let event = document.createEvent('Event'); 
      event.initEvent('keydown', true, true); 
      event.keyCode = 27;
      event.key = 'Escape';
      document.body.dispatchEvent(event);
    }
    
    setTimeout(() => {
      this._setEditMode(true);
    }, 5);
  }

  isPriorityElement = () => {
    const { tags } = this.state.tempData;
    const impTags = ["wichtig", "important", "prio"];
    for (let tagId of Object.keys(tags)) {
      if (impTags.indexOf(tags[tagId].tagname) !== -1) return true;
    }
    return false;
  }

  constructClassName = () => {
    const { isSelected, selectionMode } = this.props;
    const { animateOut } = this.state;
    let baseName = "element";
    if (isSelected) baseName += " selected";
    if (selectionMode) baseName += " selectionMode";
    if (animateOut) baseName += " animateOut";
    if (this.isPriorityElement()) baseName += " prioHighlight";
    return baseName;
  }

  render() {
    const {
      id,
      title,
      hiddenTags,
      dragHeightRef,
      isSelected,
      selectionMode,
      uploadedFiles,
      boardElement
    } = this.props;
    const { tags, links, files, reminders } = this.state.tempData;
    const { isDone, resetTitle, editMode, copyMouseOver, copiedMsgShown } = this.state;

    if (editMode && this.initialFocus) {
      this.initialFocus = false;
      setTimeout(() => {
        if (this.inputRef.current) {
          this.inputRef.current.focus();
          
          if (this.jumpToInputEnd) {
            this.jumpToInputEnd = false;
            this.inputRef.current.selectionStart = this.inputRef.current.selectionEnd = this.inputRef.current.value.length;
          }
        }
      }, 100);
    }

    let isTodo = false;
    const tagList =
      tags === undefined
        ? null
        : Object.keys(tags).map(t => {
            let tag = tags[t];
            if (!isTodo && (tag.tagname === "todo" || tag.tagname === "done"))
              isTodo = true;
            if (hiddenTags && !editMode && hiddenTags.indexOf(tag.tagname) !== -1)
              return null;
            return (
              <div
                onClick={e => editMode ? this._removeExistingTag(tag.id) : this._filterByTag(e, tag.tagname)}
                className="pill"
                key={Math.random()
                  .toString(36)
                  .substr(2, 12)}
              >
                <span className="tagName">{tag.tagname}</span>
                <span className={editMode ? "actionIcon visible" : "actionIcon"}>
                  <MaterialIcons name="close" size={15} />
                </span>
              </div>
            );
          });

    const linkList =
      links === undefined
        ? null
        : Object.keys(links).map(l => {
            return (
              <LinkAttachment
                key={l}
                {...links[l]}
                editMode={editMode}
                removeEvent={this._removeExistingLink}
                hideDesc={boardElement}
                boardElement={boardElement}
              />
            );
          });

    const fileList =
      files === undefined
        ? null
        : Object.keys(files).map(f => {
            if (files[f].fileName.match(/.(jpg|jpeg|png|gif|webp)$/i)) {
              return (
                <ImageAttachment
                  key={f}
                  {...files[f]}
                  editMode={editMode}
                  removeEvent={this._removeExistingFile}
                  openModal={this.props.openModal}
                />
              );
            } else {
              return (
                <FileAttachment
                  key={f}
                  {...files[f]}
                  editMode={editMode}
                  removeEvent={this._removeExistingFile}
                />
              );
            }
          });

    const uploadedFileList = Object.keys(uploadedFiles).filter(fid => uploadedFiles[fid].assignedToElement === id).map(file => {
      return (
        <UploadedFile
          id={file}
          file={uploadedFiles[file]}
          key={Math.random()
            .toString(36)
            .substr(2, 12)}
        />
      );
    });

    const reminderKeys = Object.keys(reminders);
    const reminderList = reminderKeys.map((rid) => {
      const reminder = reminders[rid];
      return (
        <Reminder key={rid} {...reminder} editMode={editMode} removeEvent={this._removeExistingReminder} />
      )
    });

    return (
      <div
        ref={dragHeightRef ? dragHeightRef : null}
        className={this.constructClassName()}
        onTouchStart={this.handleElementPress}
        onMouseDown={this.handleElementPress}
        onContextMenu={this.handleElementRightClick}
      >
        <LoadingBar visible={this.state.loadingBarShown} />
        <div className={"reminders " + (reminderList.length > 0 ? "visible" : "")}>
          {reminderList}
        </div>
        <div className="header">
          {isTodo && 
            <>
              <input
                id={"cb" + id}
                type="checkbox"
                checked={isDone}
                onChange={() => this._toggleTodo()}
              />
              <label htmlFor={"cb" + id}><span className={selectionMode && isSelected ? "inverted" : null}></span></label>
            </>
          }
          <SimpleInput
            value={title}
            inputRef={this.inputRef}
            placeholder="Kurze Beschreibung, #tags und Links einfügen"
            updateEvent={this._updateInputContent}
            submitEvent={this._saveModifiedElement}
            seamless={true}
            seamlessFocused={editMode}
            forceSetInitialValue={resetTitle}
            doubleClickEvent={() => this.handleEnterEditMode()}
            disableInteraction={boardElement}
          />
          {selectionMode ? null : (editMode ? (
            <div className="options steady">
              <div
                className="actionIcon"
                onClick={() => this._setEditMode(false)}
                ref={ref => this.cancelRef = ref}
                onMouseDown={() => this.handleActionItemAnim(this.cancelRef)}
              >
                <MaterialIcons name="close" size={20} />
              </div>
              <div
                className="actionIcon"
                onClick={() => this._saveModifiedElement()}
                ref={ref => this.saveRef = ref}
                onMouseDown={() => this.handleActionItemAnim(this.saveRef)}
              >
                <MaterialIcons name="check" size={20} />
              </div>
            </div>
          ) :
            (<div className="options">
              {title && 
              <>
                <div className={copyMouseOver ? "completedMessage shown" : "completedMessage"}>
                  <div className="text"><span>{copiedMsgShown ? "Kopiert!" : "Kopieren"}</span></div>
                </div>
                <div
                  className="actionIcon"
                  onClick={() => this.copyToClipboard()}
                  ref={ref => this.copyRef = ref}
                  onMouseEnter={() => this.setState({ ...this.state, copyMouseOver: true })}
                  onMouseLeave={() => this.setState({ ...this.state, copyMouseOver: false, copiedMsgShown: false })}
                  onMouseDown={() => this.handleActionItemAnim(this.copyRef)}
                >
                  <MaterialIcons name="filter-none" size={16} />
                </div>
              </>
              }
              <div
                className="actionIcon"
                onClick={() => {
                  this.jumpToInputEnd = true;
                  this.handleEnterEditMode();
                }}
                ref={ref => this.editRef = ref}
                onMouseDown={() => this.handleActionItemAnim(this.editRef)}
              >
                <MaterialIcons name="edit" size={18} />
              </div>
            </div>)
          )}
          <input type="file" ref={this.fileInputRef} multiple="multiple" onChange={this._onFileChange} />
        </div>
        {linkList}
        {fileList}
        {uploadedFileList}
        {/* <ArticleAttachment /> */}

        { tagList.length > 0 ? (
          <div className="propertyContainer">
          <div className="tags">{tagList}</div>
          {/* <div className="tags dates">
            <div
                className="pill"
                key={Math.random().toString(36).substr(2, 12)}
              >
                <MaterialIcons name="date-range" size={16} />
                <span className="tagName">26.07.2020 15:30</span>
            </div>
          </div> */}
        </div>
        ) : null }
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  token: state.authentication.userData.token,
  language: state.preferences.language,
  workspaceId: state.authentication.userData.workspaceId,
  uploadedFiles: state.element.uploadedFiles,
  isSelected:
    state.element.selectedElements.filter(eid => eid === ownProps.id).length >
    0,
  selectionMode: state.element.selectedElements.length > 0,
  isPaidUser: state.authentication.userData.isPaidUser,
  storageUsed: state.authentication.userData.storageUsed
});

const mapDispatchToProps = dispatch => ({
  setEditElementStatus: id => dispatch(setEditElementStatus(id)),
  deleteElement: (metadata, elementId) =>
    dispatch(deleteElement(metadata, elementId)), // revertableDeleteElement
  updateElementRequested: (metadata, elemData) =>
    dispatch(updateElementRequested(metadata, elemData)),
  addFilesForUpload: files => dispatch(addFilesForUpload(files)),
  uploadFileRequested: (metadata, file) =>
    dispatch(uploadFileRequested(metadata, file)),
  resetUploadedFiles: () => dispatch(resetUploadedFiles()),
  openModal: (modalId, modalData) => dispatch(openModal(modalId, modalData)),
  selectElement: elementId => dispatch(selectElement(elementId)),
  unselectElement: elementId => dispatch(unselectElement(elementId)),
  addFileError: errorMsg => dispatch({
    type: "SHOW_NOTIFICATION_REQUESTED",
    notification: {
      type: "error", title: "Fehler beim Dateiupload", desc: errorMsg
    }
  }),
  deleteElementSucceeded: elemId => dispatch(deleteElementSucceeded(elemId))
});

export default connect(mapStateToProps, mapDispatchToProps)(Element);
