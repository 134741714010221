import React, { Component } from "react";
import { connect } from "react-redux";
import {
  switchTheme,
  updatePersonalizationSettings
} from "../../../actions/preferences";
import { MaterialIcons } from "react-web-vector-icons";

class PersonalizationSetting extends Component {
  constructor(props) {
    super(props);

    this.settingsModified = false;
  }

  capitalizeFirstLetter = s => {
    return s.charAt(0).toUpperCase() + s.slice(1);
  };

  _doSwitchTheme = theme => {
    this.settingsModified = true;
    this.props.switchTheme(theme);
  };

  componentDidUpdate() {
    if (this.settingsModified) {
      this.settingsModified = false;
      let { language, theme } = this.props;
      this.props.updatePersonalizationSettings(
        {
          token: this.props.token
        },
        {
          language: language,
          theme: theme
        }
      );
    }
  }

  render() {
    let { theme, language } = this.props;

    return (
      <div className="settingContent personalizationSetting material">
        <h5>Personalisierung</h5>
        <div className="separator" />

        <div className="parallelBox">
          <div className="settingName">
            <span>Sprache:</span>
          </div>
          <div className="settingValue">
            <span>{language}</span>
          </div>
        </div>

        <h6>Theme:</h6>

        <div className="themeSelector">
          <div
            className="theme light"
            onClick={() => this._doSwitchTheme("light")}
          >
            <div className="head">
              <span>Hell</span>
              <MaterialIcons
                name={
                  "radio-button-" +
                  (theme === "light" ? "checked" : "unchecked")
                }
                size={28}
              />
            </div>
            <svg
              width="100%"
              height="100%"
              className="themeSvg"
              viewBox="0 0 100 100"
              preserveAspectRatio="none"
            >
              <polygon
                points="0,80 105,40 105,105, 0,105, 0,80"
                fill="#f5f5f5"
              />
            </svg>
            <svg
              width="100%"
              height="100%"
              className="themeSvg"
              viewBox="0 0 100 100"
              preserveAspectRatio="none"
            >
              <polygon points="50,105 105,60 105,105 50,105" fill="#222e3c" />
            </svg>
          </div>
          <div
            className="theme night"
            onClick={() => this._doSwitchTheme("night")}
          >
            <div className="head">
              <span>Nacht</span>
              <MaterialIcons
                name={
                  "radio-button-" +
                  (theme === "night" ? "checked" : "unchecked")
                }
                size={28}
              />
            </div>
            <svg
              width="100%"
              height="100%"
              className="themeSvg"
              viewBox="0 0 100 100"
              preserveAspectRatio="none"
            >
              <polygon
                points="0,80 105,40 105,105, 0,105, 0,80"
                fill="#222e3c"
              />
            </svg>
            <svg
              width="100%"
              height="100%"
              className="themeSvg"
              viewBox="0 0 100 100"
              preserveAspectRatio="none"
            >
              <polygon points="50,105 105,60 105,105 50,105" fill="#161d27" />
            </svg>
          </div>
          <div
            className="theme dark"
            onClick={() => this._doSwitchTheme("dark")}
          >
            <div className="head">
              <span>Dunkel</span>
              <MaterialIcons
                name={
                  "radio-button-" + (theme === "dark" ? "checked" : "unchecked")
                }
                size={28}
              />
            </div>
            <svg
              width="100%"
              height="100%"
              className="themeSvg"
              viewBox="0 0 100 100"
              preserveAspectRatio="none"
            >
              <polygon
                points="0,80 105,40 105,105, 0,105, 0,80"
                fill="#212121"
              />
            </svg>
            <svg
              width="100%"
              height="100%"
              className="themeSvg"
              viewBox="0 0 100 100"
              preserveAspectRatio="none"
            >
              <polygon points="50,105 105,60 105,105 50,105" fill="#191919" />
            </svg>
          </div>
        </div>
        {/* 
        <div className="parallelBox">
          <div className="settingName">
            <span>Theme: </span>
          </div>
          <div className="settingValue">
            <div className="customSelect">
              <select value={theme} onChange={this._handleChange}>
                {ThemeOptions}
              </select>
            </div>
          </div>
        </div> */}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  token: state.authentication.userData.token,
  theme: state.preferences.theme,
  language: state.preferences.language
});

const mapDispatchToProps = dispatch => ({
  switchTheme: themeName => dispatch(switchTheme(themeName)),
  updatePersonalizationSettings: (metadata, settings) =>
    dispatch(updatePersonalizationSettings(metadata, settings))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PersonalizationSetting);
