import React, { Component } from 'react';
import './QueryArea.scss';
import { connect } from 'react-redux';
import AddQuery from './AddQuery';
import { withRouter } from 'react-router-dom';
import { retrieveQueriesRequested, setQueryTree } from '../../actions/query';
import {
  retrieveElements,
  retrieveUntaggedElements,
  // resetHiddenByFilter,
  unselectAllElements
} from './../../actions/element';
import { resetAllFilters } from '../../actions/filter';
import { getReference, ReferenceNames } from '../../utils/reference';
import { isTimelineView } from '../../utils/view';
import { setActiveTimelineQuery } from '../../actions/navigation';
import { convertQueriesToTree } from '../../utils/conversions';
// import { QueryTree } from './QueryTree';
import QueryItem from './QueryItem';

class DynamicQueryArea extends Component {
  constructor(props) {
    super(props);

    this.initialLoad = true;
    this.nativeInputValueSetter = Object.getOwnPropertyDescriptor(window.HTMLInputElement.prototype, 'value').set;
    this.nestableRef = React.createRef(null);

    this.dragging = false;
    this.interval = null;
    this.scrollDirUp = false;
  }

  componentDidMount() {
    this.props.retrieveQueriesRequested({
      token: this.props.token,
      workspaceId: this.props.workspaceId,
      language: this.props.language
    });
  }

  // Well somehow it now works without this, keep for now though

  // shouldComponentUpdate(nextProps) {
  //   // Only re-render if one of the element-display views is selected
  //   let firstLevel = nextProps.location.pathname.split("/")[1];
  //   if (["untagged", "timeline", "query"].indexOf(firstLevel) === -1) return false;
  //   return true;
  // }

  componentDidUpdate(prevProps) {
    // On any changes to the queries, update the tree display
    if (prevProps.queries !== this.props.queries) {
      this.props.setQueryTree(convertQueriesToTree(this.props.queries));
    }

    // User modified, switched or is loading the query for the first time
    if (
      (prevProps.queries &&
        this.props.queries &&
        prevProps.queries !== this.props.queries &&
        prevProps.queries.length === this.props.queries.length) || // Only if length stays the same to prevent data reload on add
      (prevProps.location.pathname !== this.props.location.pathname &&
        (isTimelineView(prevProps.location.pathname) && isTimelineView(this.props.location.pathname))) ||
      (this.props.queries && this.initialLoad)
    ) {
      if (this.initialLoad) {
        this.initialLoad = false;
      }

      this.props.unselectAllElements();

      let subpaths = this.props.location.pathname.split('/');
      let query = '';
      let elemView = false;

      const { setActiveTimelineQuery } = this.props;

      switch (subpaths[1]) {
        case 'untagged': {
          setActiveTimelineQuery('/untagged');
          this._retrieveElementsWithoutTag();
          elemView = true;
          break;
        }
        case 'timeline': {
          setActiveTimelineQuery('/timeline');
          // Reset state
          this.props.history.replace({
            pathname: '/timeline',
            state: {}
          });
          this._retrieveElements();
          elemView = true;
          break;
        }
        case 'query': {
          if (this.props.queries) {
            setActiveTimelineQuery(`/query/${subpaths[2]}`);
            const activeQuery = this.props.queries.filter(q => q.id === Number.parseInt(subpaths[2]))[0];
            if (activeQuery) query = activeQuery.query;
            elemView = true;
          }
          break;
        }
        default: {
        }
      }

      // Set searchbar value (react overrides regular input value setter)
      if (elemView && !(this.props.location.state && this.props.location.state.keepInput)) {
        const searchbar = getReference(ReferenceNames.SEARCHBAR_INPUT);
        if (!searchbar) return;
        this.nativeInputValueSetter.call(searchbar, query);
        let ev2 = new Event('input', { bubbles: true });
        searchbar.dispatchEvent(ev2);
      }

      const inputBar = getReference(ReferenceNames.ADD_ELEMENT_INPUT);
      if(inputBar) {
        inputBar.focus();
      }
    }

    // Update nodes accordingly on collapse
    // if (prevProps.collapsedQueries !== this.props.collapsedQueries) {
    //   this.nestableRef.current.collapse('NONE');
    //   this.nestableRef.current.collapse(this.props.collapsedQueries);
    // }
  }

  resetInterval = () => {
    clearInterval(this.interval);
    this.interval = null;
    this.scrollDirUp = false;
  }

  checkForBounds = (treeArea) => {
    // Reset interval when top or bottom reached
    if (this.interval && ((this.scrollDirUp && treeArea.scrollTop === 0 ) || (!this.scrollDirUp && treeArea.scrollTop + 1 >= (treeArea.scrollHeight - treeArea.offsetHeight)))) {
      this.resetInterval();
      return;
    }
  }

  render() {
    // const { queries, history, location, mobile } = this.props;
    const { queries } = this.props;

    let QueryItems = queries ? queries.map(q => {
      let { id, emoji, name, query } = q;
      return (
        <QueryItem
          key={id}
          id={id}
          emoji={emoji}
          name={name}
          query={query}
          editable={true}
          // ghost={draggingId === id}
          // draggingId={draggingId}
          history={this.props.history}
          location={this.props.location}
          mobile={this.props.mobile ? true : false}
        />
      );
    }) : null;

    // const renderItem = ({ item, _index, collapseIcon }) => (
    //   <DragQueryItem
    //     {...item}
    //     collapseIcon={collapseIcon}
    //     nestableRef={this.nestableRef}
    //     history={history}
    //     location={location}
    //     mobile={mobile ? true : false}
    //   />
    // );
    // const renderCollapseIcon = ({ isCollapsed }) =>
    //   isCollapsed ? <MaterialIcons name="expand-more" size={20} /> : <MaterialIcons name="expand-less" size={20} />;

    if (queries) {
      return (
        <div
          className="queryArea scrollable"
          id="queryTreeArea"
          // onMouseDown={() => (this.dragging = true)}
          // onMouseUp={() => {
          //   this.dragging = false;
          //   if (this.interval) this.resetInterval();
          // }}
          // onMouseMove={e => {
          //   if (this.dragging) {
          //     const treeArea = document.getElementById("queryTreeArea");
          //     const bounds = treeArea.getBoundingClientRect();

          //     // Abort scrolling when cursor leaves scroll area and the top/bottom has not been reached yet
          //     if (this.interval && this.scrollDirUp && !(e.clientY - 60 < bounds.top)) {
          //       this.resetInterval();
          //       return;
          //     }

          //     if (this.interval && !this.scrollDirUp && !(e.clientY + 60 > bounds.bottom)) {
          //       this.resetInterval();
          //       return;
          //     }

          //     // Enable auto-scroll top
          //     if (e.clientY - 60 < bounds.top && !this.interval) {
          //       this.scrollDirUp = true;
          //       this.interval = setInterval(() => {
          //         treeArea.scrollTop = treeArea.scrollTop - 2;
          //         this.checkForBounds(treeArea);
          //       }, 5);
          //     }

          //     // Enable auto-scroll bottom
          //     if (e.clientY + 60 > bounds.bottom && !this.interval) {
          //       this.scrollDirUp = false;
          //       this.interval = setInterval(() => {
          //         treeArea.scrollTop = treeArea.scrollTop + 2;
          //         this.checkForBounds(treeArea);
          //       }, 5);
          //     }
          //   }
          // }}
        >
          {QueryItems}
          {/* <Nestable
            className="queryTree"
            items={queryTree}
            renderItem={renderItem}
            renderCollapseIcon={renderCollapseIcon}
            collapsed={false}
            ref={this.nestableRef}
            onChange={(items, _item) => setQueryTree(items)}
          /> */}

          {/* <QueryTree history={history} location={location} mobile={mobile} /> */}
          <AddQuery />
        </div>
      );
    } else {
      return (
        <div className="loadingDots">
          <div className="bounce1" />
          <div className="bounce2" />
          <div className="bounce3" />
        </div>
      );
    }
  }

  _retrieveElements = () => {
    if (!(this.props.location.state && this.props.location.state.keepInput)) this.props.resetAllFilters(false);
    // this.props.resetHiddenByFilter();

    // Prevent request without filter if explicitly told by router location
    if (this.props.location.state && this.props.location.state.keepInput) return;

    let { retrieveElements, token, workspaceId, language } = this.props;
    retrieveElements(
      {
        token: token,
        workspaceId: workspaceId,
        language: language,
        lastId: null,
        filter: {
          queryString: null,
          tagsIncluded: [],
          tagsExcluded: [],
          dateStart: null,
          dateEnd: null
        }
      },
      false
    );
  };

  _retrieveElementsWithoutTag = () => {
    this.props.resetAllFilters(false);
    // this.props.resetHiddenByFilter();

    let { retrieveUntaggedElements, token, workspaceId, language } = this.props;
    retrieveUntaggedElements(
      {
        token: token,
        workspaceId: workspaceId,
        language: language,
        lastId: null,
        filter: {
          queryString: null,
          dateStart: null,
          dateEnd: null
        }
      },
      false
    );
  };
}

const mapStateToProps = state => ({
  queries: state.query.queries,
  draggingId: state.query.draggingId,
  token: state.authentication.userData.token,
  workspaceId: state.authentication.userData.workspaceId,
  language: state.preferences.language,
  lastId: state.element.lastId,
  // collapsedQueries: state.query.collapsedQueries
});

const mapDispatchToProps = dispatch => ({
  retrieveQueriesRequested: metadata => dispatch(retrieveQueriesRequested(metadata)),
  retrieveElements: (metadata, append) => dispatch(retrieveElements(metadata, append)),
  retrieveUntaggedElements: (metadata, append) => dispatch(retrieveUntaggedElements(metadata, append)),
  // resetHiddenByFilter: () => dispatch(resetHiddenByFilter()),
  resetAllFilters: triggerRequest => dispatch(resetAllFilters(triggerRequest)),
  unselectAllElements: () => dispatch(unselectAllElements()),
  setActiveTimelineQuery: queryPath => dispatch(setActiveTimelineQuery(queryPath)),
  setQueryTree: queryTree => dispatch(setQueryTree(queryTree))
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(DynamicQueryArea));
