import React, { Component } from 'react';
import './Authentication.scss';
import { connect } from 'react-redux';
import { requestLoginFetch, resetError } from './../../actions/authentication';
import { Redirect } from 'react-router-dom';

class LoginView extends Component {
  constructor(props) {
    super(props);

    this.state = {
      email: props.auth.userData.email == null ? '' : props.auth.userData.email,
      password: ''
    };
    this.errorMsg = React.createRef();
    this.fetchReq = false;
  }

  _onChangeInput = e => {
    this.setState({ ...this.state, [e.target.name]: e.target.value });
  };

  _onKeyDown = e => {
    if (e.key === 'Enter') {
      this.fetchReq = true;
      this.props.requestLoginFetch(
        { history: this.props.history, language: this.props.history },
        { email: this.state.email, password: this.state.password }
      );
    }
  };

  componentDidUpdate(prevProps) {
    if (this.errorMsg.current && this.props.auth.errorMsg === prevProps.auth.errorMsg && this.fetchReq) {
      this.fetchReq = false;
      this.errorMsg.current.classList.remove('initialFade');
      this.errorMsg.current.classList.add('pulsating');
      setTimeout(() => {
        if (!this.errorMsg.current) return;
        this.errorMsg.current.classList.remove('pulsating');
      }, 2000);
    }
  }

  render() {
    if (this.props.auth.loggedIn === true) {
      return <Redirect to="timeline" />;
    } else {
      return (
        <div className="centeredWrapper material">
          <div className="loginContainer material">
            <h3>Anmeldung</h3>
            <h5>Bitte gib deine Zugangsdaten an</h5>
            <div className="inputField">
              <input
                type="email"
                name="email"
                value={this.state.email}
                placeholder=" "
                onChange={e => this._onChangeInput(e)}
                onKeyDown={e => this._onKeyDown(e)}
                spellCheck="false"
              />
              <label placeholder="E-Mail" />
            </div>
            <div className="inputField">
              <input
                type="password"
                name="password"
                placeholder=" "
                onChange={e => this._onChangeInput(e)}
                onKeyDown={e => this._onKeyDown(e)}
              />
              <label placeholder="Passwort" />
            </div>
            {this.props.auth.errorOccured === true ? (
              <p ref={this.errorMsg} className="errorMsg initialFade">
                {this.props.auth.errorMsg}
              </p>
            ) : null}
            <div className="parallelInput actionButtons">
              <a
                href
                className="flat"
                onClick={() => {
                  this.props.resetError();
                  this.props.history.push('/register');
                }}
              >
                Konto erstellen
              </a>
              <button
                className="primary"
                onClick={() => {
                  this.fetchReq = true;
                  this.props.requestLoginFetch(
                    {
                      history: this.props.history,
                      language: this.props.history
                    },
                    { email: this.state.email, password: this.state.password }
                  );
                }}
              >
                Anmelden
              </button>
            </div>
          </div>
          <a
            href
            className="flat resetPwLabel"
            onClick={() => this.props.history.push('/reset-password-request')}
          >
            Passwort vergessen?
          </a>
        </div>
      );
    }
  }
}

const mapStateToProps = state => ({
  auth: state.authentication,
  language: state.preferences.language
});

const mapDispatchToProps = dispatch => ({
  resetError: () => dispatch(resetError()),
  requestLoginFetch: (metadata, email, password) => dispatch(requestLoginFetch(metadata, email, password))
});

export default connect(mapStateToProps, mapDispatchToProps)(LoginView);
