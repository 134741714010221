import React, { Component } from "react";
import "./MobileView.scss";
import MobileMinTopBar from "./../../components/TopBar/Mobile/MobileMinTopBar";
import { connect } from "react-redux";
import { toggleMobileMenu } from "../../actions/navigation";

class MobileListView extends Component {
  render() {
    return (
      <div
        className={
          this.props.mobileMenuOpen === true
            ? "mobileViewContent openMenu"
            : "mobileViewContent"
        }
      >
        <div
          className="openMenuOverlay"
          onClick={() => this.props.toggleMobileMenu()}
        />
        <MobileMinTopBar
          title="Listenansicht"
          mobileMenuOpen={this.props.mobileMenuOpen}
        />
        <div className="contentContainer">
          <div className="settingsList material">
              <p>Bald verfügbar</p>
            </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  mobileMenuOpen: state.navigation.mobileMenuOpen
});

const mapDispatchToProps = dispatch => ({
  toggleMobileMenu: () => dispatch(toggleMobileMenu())
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MobileListView);
