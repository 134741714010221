import React, { Component } from "react";
import { connect } from "react-redux";
import { closeModal, openModal } from "./../../actions/navigation";
import { MaterialIcons } from "react-web-vector-icons";
import { logout } from "../../actions/authentication";
import { modalCodes } from "../../utils/enums";
import { LoadingBar } from "../Loading/LoadingBar";
import { addBoardRequested, updateBoardRequested, resetUpdateBoardError } from "../../actions/board";

class AddBoardModal extends Component {
  constructor(props) {
    super(props);

    this.initialFocus = true;
    this.nameInput = React.createRef();
    this.state = { loadingBarShown: false, name: "" };
  }

  static getDerivedStateFromProps(props, state) {
    let modalClosed =
      props.openModal && props.openModal !== modalCodes.EDIT_BOARD_MODAL;

    // Reset error when modal is closed
    // if (modalClosed && props.updateBoardError !== null && props.targetDevice === "desktop") props.resetAddElementError();
    if (modalClosed && props.updateBoardError !== null) props.resetUpdateBoardError();

    if (modalClosed) {
      return {
        ...state,
        name: ""
      };
    }
    return null;
  }

  _clickOutside = e => {
    if (e.target === e.currentTarget) {
      this.props.closeModal();
    }
  };

  componentDidUpdate(prevProps) {
    if (this.props.openModal === modalCodes.EDIT_BOARD_MODAL && prevProps.openModal !== modalCodes.EDIT_BOARD_MODAL) {
      this.initialFocus = true;
      this.forceUpdate();
    }
  }

  _handleChange = e => {
    this.setState({ ...this.state, name: e.target.value });
  };

  _keyDown = e => {
    if (e.key.toLowerCase() === "enter") {
      e.preventDefault();
      this._submitUpdateBoard();
    }
  };

  _deleteBoard = () => {
    const { boardId } = this.props.modalData;
    this.props.doOpenModal(modalCodes.DELETE_BOARD_CONFIRM_MODAL, { boardId });
  }

  _submitUpdateBoard = () => {
    let { updateBoardRequested, token } = this.props;
    let { boardId } = this.props.modalData;
    updateBoardRequested({
      token: token
    }, {
      boardId: boardId,
      name: this.state.name
    });
  };

  render() {
    if (
      this.props.openModal === modalCodes.EDIT_BOARD_MODAL &&
      this.initialFocus
    ) {
      this.initialFocus = false;
      setTimeout(() => {
        if (this.nameInput.current)
          this.nameInput.current.value = this.props.modalData.boardName;
      }, 50);
      setTimeout(() => {
        if (this.nameInput.current) this.nameInput.current.focus();
      }, 150);
    } else {
      // Remove focus from input after close
      if (this.props.openModal !== modalCodes.EDIT_BOARD_MODAL) {
        setTimeout(() => {
          if (this.nameInput.current) this.nameInput.current.blur();
        }, 150);
      }
    }

    return (
      <div
        className={
          this.props.openModal === modalCodes.EDIT_BOARD_MODAL
            ? "modalWrapper open"
            : "modalWrapper"
        }
        onMouseDown={e => this._clickOutside(e)}
      >
        <div className="modalContent addBoard">
          <LoadingBar visible={this.state.loadingBarShown} />
          <h2>Board bearbeiten</h2>
          <div className="close" onClick={() => this.props.closeModal()}>
            <MaterialIcons name="close" size={18} />
          </div>
          <div className="fields">
            <span>Name des Boards: </span>
            <input
              type="text"
              ref={this.nameInput}
              value={this.state.name}
              onChange={this._handleChange}
              onKeyDown={this._keyDown}
              className="nameInput"
              spellCheck={false}
            />
          </div>

          {this.props.updateBoardError === null ? null : (
            <p className="errorMsg">{this.props.updateBoardError}</p>
          )}

          <div className="actionItems material">
            <button className="primary delete" onClick={() => this._deleteBoard()}>Löschen</button>
            <button
              className="primary"
              onClick={() => this._submitUpdateBoard()}
            >
              Speichern
            </button>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  openModal: state.navigation.openModal,
  modalData: state.navigation.modalData,
  token: state.authentication.userData.token,
  workspaceId: state.authentication.userData.workspaceId,
  updateBoardError: state.board.updateBoardError
});

const mapDispatchToProps = dispatch => ({
  closeModal: () => dispatch(closeModal()),
  doOpenModal: (modalId, modalData) => dispatch(openModal(modalId, modalData)),
  logout: () => dispatch(logout()),
  addBoardRequested: (metadata, boardData) =>
    dispatch(addBoardRequested(metadata, boardData)),
  resetUpdateBoardError: () => dispatch(resetUpdateBoardError()),
  updateBoardRequested: (metadata, boardData) => dispatch(updateBoardRequested(metadata, boardData))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AddBoardModal);
