import React, { Component } from "react";
import { connect } from "react-redux";
import { MaterialIcons } from "react-web-vector-icons";
import { setOnlineStatus } from "../../actions/connection";

class OfflineWarning extends Component {
  componentDidMount() {
    setTimeout(() => {
      if (this.props.isOnline === undefined) {
        // Manually set to offline after 3 seconds
        this.props.setOnlineStatus(false);
      }
    }, 3000);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.isOnline === false && this.props.isOnline === true) {
      window.location.reload();
    }
  }

  render() {
    return this.props.isOnline === false ? (
      <div className="offlineWarning">
        <MaterialIcons name="signal-wifi-off" size={22} />
        <span>Keine Internetverbindung</span>
        <div className="pulseBox" />
      </div>
    ) : null;
  }
}

const mapStateToProps = state => ({
  isOnline: state.connection.isOnline
});

const mapDispatchToProps = dispatch => ({
  setOnlineStatus: tf => dispatch(setOnlineStatus(tf))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OfflineWarning);
