import React from 'react';

export const APISetting = () => {
  return (
    <div className="settingContent material">
      <h5>Tagstack API</h5>
      <div className="separator" />

      <h6>Die Tagstack API ist derzeit noch nicht öffentlich zugänglich.</h6>

    </div>
  );
};
