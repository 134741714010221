import React, { Component } from "react";
import { NavigationItem } from "./NavigationItem";
import { withRouter } from "react-router-dom";

class NavigationBar extends Component {
  _switchView = clickedView => {
    this.props.history.push(clickedView);
    // There might be a better solution for this
    setTimeout(() => {
      this.props.toggleMobileMenu();
    }, 30);
  };

  render() {
    return (
      <div className="mobileNavigationBar">
        <NavigationItem
          path="/timeline"
          icon="layers"
          tooltip="Übersicht"
          switchEvent={this._switchView}
        />
        <NavigationItem
          path="/boards"
          icon="view-compact"
          tooltip="Listenansicht"
          switchEvent={this._switchView}
        />
        <NavigationItem
          path="/settings"
          icon="settings"
          tooltip="Einstellungen"
          switchEvent={this._switchView}
        />
      </div>
    );
  }
}
export default withRouter(NavigationBar);