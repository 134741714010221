import React, { Component } from "react";
import { SettingMenuItem } from "./SettingMenuItem";
import { connect } from "react-redux";
import { switchSettingMenu } from "../../actions/navigation";

class SettingMenu extends Component {
  render() {
    let { activeSettingMenu, switchSettingMenu } = this.props;
    return (
      <div className="settingMenu">
        <SettingMenuItem
          id={0}
          active={activeSettingMenu}
          onSelect={switchSettingMenu}
          icon="person"
          title="Dein Profil"
          desc="Details zu deinem Account"
        />
        <SettingMenuItem
          id={1}
          active={activeSettingMenu}
          onSelect={switchSettingMenu}
          icon="dashboard"
          title="Deine Workspaces"
          desc="Konfiguration deiner Workspaces"
        />
        <SettingMenuItem
          id={2}
          active={activeSettingMenu}
          onSelect={switchSettingMenu}
          icon="color-lens"
          title="Personalisierung"
          desc="Deine persönlichen Präferenzen"
        />
        <SettingMenuItem
          id={3}
          active={activeSettingMenu}
          onSelect={switchSettingMenu}
          icon="payment"
          title="Abonnement"
          desc="Details zu deinem Abonnement"
        />
        <SettingMenuItem
          id={4}
          active={activeSettingMenu}
          onSelect={switchSettingMenu}
          icon="code"
          title="Tagstack API"
          desc="Informationen zum API Zugriff"
        />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  activeSettingMenu: state.navigation.activeSettingMenu
});

const mapDispatchToProps = dispatch => ({
    switchSettingMenu: id => dispatch(switchSettingMenu(id))
});

export default connect(mapStateToProps, mapDispatchToProps)(SettingMenu);
