import React, { Component } from "react";
import { MaterialIcons } from "react-web-vector-icons";
import { convertFileSize, convertDateTime } from "../../../utils/conversions";

export class ImageAttachment extends Component {

  _removeFile = e => {
    e.stopPropagation();
    this.props.removeEvent(this.props.id);
  }

  render() {
    let { fileName, fileUrl, fileLastModified, fileSize, editMode } = this.props;
    let lastChangedDate = new Date(fileLastModified);

    return (
      <div className="attachment image" onClick={() => this.props.openModal(5, { fileName: fileName, fileUrl: fileUrl })}>
        <div className="imagePreview">
          <img src={fileUrl} alt="" />
        </div>

        <div className="text">
          <div className="header">
            <span className="filename">{fileName}</span>
          </div>
          <div className="content">
            <span className="preview">{convertFileSize(fileSize)} - {convertDateTime(lastChangedDate)}</span>
            
          </div>
        </div>

        <div
          className={
            editMode === true ? "fixed-options visible" : "fixed-options"
          }
        >
          <div className="actionIcon" onClick={this._removeFile}>
            <MaterialIcons name="delete" size={20} />
          </div>
        </div>
      </div>
    );
  }
}
