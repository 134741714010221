// This is necessary to separete the views from the side menu
// Otherwise "activeView"-changes trigger a complete re-render of the sidemenu

import React, { Component } from "react";
import { connect } from "react-redux";
import DefaultView from "./../../views/DefaultView";
import { SettingsView } from "./../../views/SettingsView";
import MobileDefaultView from "./../../views/Mobile/MobileDefaultView";
import MobileSettingsView from "../../views/Mobile/MobileSettingsView";
import MobileListView from "../../views/Mobile/MobileListView";
import BoardView from "../../views/BoardView";
import CacheRoute from 'react-router-cache-route'
import { InsightsView } from "../../views/InsightsView";

class ViewContainer extends Component {
  render() {
    const isMobile = this.props.targetDevice === "mobile";
    return (
      <>
        <CacheRoute path={["/timeline", "/untagged", "/query"]} component={isMobile ? MobileDefaultView : DefaultView} />
        <CacheRoute path={["/boards", "/board"]} component={isMobile ? MobileListView : BoardView} />
        <CacheRoute path={["/insights"]} component={isMobile ? InsightsView : InsightsView} />
        <CacheRoute path={["/settings"]} component={isMobile ? MobileSettingsView : SettingsView} />
      </>
    )
  }
}

const mapStateToProps = state => ({
  activeView: state.navigation.activeView
});

export default connect(mapStateToProps)(ViewContainer);
