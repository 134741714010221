import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { reqestResetPasswortRequested, setResetPasswordError } from "../../actions/authentication";

export const ResetPasswordRequestView = () => {

  const [ email, setEmail ] = useState();
  const [ success, setSuccess ] = useState(false);
  const resetPasswordError = useSelector(state => state.authentication.resetPasswordError);
  const dispatch = useDispatch();
  
  let submitButton = null;

  useEffect(() => {
    dispatch(setResetPasswordError(null));
  }, []);

  if (success) {
    return (
      <div className="centeredWrapper">
        <div className="loginContainer material success">
          <h3>E-Mail versendet.</h3>
          <h5>Bitte überprüfe deinen Posteingang.</h5>
        </div>
      </div>
    );
  } else {
    return (
      <div className="centeredWrapper">
        <div className="loginContainer material">
            <h3>Passwort zurücksetzen</h3>
            <h5>Bitte gib deine E-Mail Adresse ein. Wir senden dir einen Link für das Zurücksetzen deines Passworts.</h5>
            <div className="inputField">
              <input
                type="email"
                name="email"
                placeholder=" "
                onChange={e => setEmail(e.target.value)}
                onKeyDown={e => {
                  if (e.key === 'Enter') submitButton.click();
                }}
              />
              <label placeholder="E-Mail" />
            </div>
            {resetPasswordError && <p className="errorMsg initialFade">{resetPasswordError}</p>}
            <div className="parallelInput actionButtons">
              <div />
              <button
                ref={ref => submitButton = ref}
                className="primary"
                onClick={() => dispatch(reqestResetPasswortRequested(email, setSuccess))}
              >
                Anfordern
              </button>
            </div>
        </div>
      </div>
    );
  }
}