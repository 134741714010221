import React, { Component } from 'react';
import { connect } from 'react-redux';
import { closeModal }  from './../../actions/navigation';
import { MaterialIcons } from 'react-web-vector-icons';
import { modalCodes } from '../../utils/enums';
import { withRouter } from 'react-router-dom';
import { getSearchResults } from '../../utils/search';
import { CmdSearchItem } from './CmdSearchItem';

class CmdSearchModal extends Component {

  initialState = { searchString: "", activeElement: 0 };

  constructor(props) {
    super(props);
    this.me = React.createRef();

    this.state = this.initialState;
  }

  componentDidUpdate(prevProps) {
    if (this.props.openModal === modalCodes.CMD_SEARCH_MODAL) {
      // Doesn't work properly without delay
      setTimeout(() => {
        document.addEventListener('keydown', this.handleKeyDown);
        this.cmdSearchbarRef.focus();
      }, 100);
    } 

    // Reset state to initial state
    setTimeout(() => {
        if (prevProps.openModal === modalCodes.CMD_SEARCH_MODAL && this.props.openModal !== modalCodes.CMD_SEARCH_MODAL) {
          this.setState(this.initialState);
          document.removeEventListener('keydown', this.handleKeyDown);
        }
      }, 150);
  }

  handleKeyDown = (e) => {  
    switch (e.key.toLowerCase()) {
      case "enter":
        e.preventDefault();
        const res = this.searchResults();
        const resultCount = res.length;
        if (this.state.activeElement >= 0 && this.state.activeElement < resultCount) {
          const action = res[this.state.activeElement].action;
          if (action) {
            action();
          }
          break;
        }
        break;
      case "arrowup":
        e.preventDefault();
        if (this.state.activeElement > 0) {
          this.setState({ activeElement: this.state.activeElement - 1 })
        }
        break;
      case "arrowdown":
        e.preventDefault();
        if (this.state.activeElement < this.searchResults().length - 1) {
          this.setState({ activeElement: this.state.activeElement + 1 })
        }
        break;
      default: break;
    }
  };

  _clickOutside = e => {
    if (e.target === e.currentTarget) {
      this.props.closeModal();
    }
  };

  searchResults = () => {
    const navParams = {
      history: this.props.history,
      closeModal: this.props.closeModal,
      genericDispatch: this.props.genericDispatch
    };

    return getSearchResults(this.state.searchString, this.props.queries ? this.props.queries : [], navParams);
  }

  render() {
    // if (!this.props.modalData) return null;
    let that = this;
    const searchResults = this.searchResults();

    return (
      <div
        className={this.props.openModal === modalCodes.CMD_SEARCH_MODAL ? 'modalWrapper open' : 'modalWrapper'}
        onMouseDown={e => this._clickOutside(e)}
      >
        <div className="modalContent cmdModal">
            <div className="cmdSeachbarContainer">
              <div className="cmdIcon">
                <MaterialIcons name="search" size={24} color="var(--onSurfaceLight)" />
              </div>
              <input 
              className="cmdSeachbar" 
              placeholder="Was willst du tun?"
              value={this.state.searchString} 
              onChange={(e) => {
                this.setState({ searchString: e.target.value, activeElement: 0 });
              }} 
              ref={ ref => this.cmdSearchbarRef = ref } />
            </div>
            <div className="cmdSearchDropdown scrollable" ref={this.me}>
              {searchResults.map((cmd, i) => (
                <CmdSearchItem
                cmd={cmd}
                key={i}
                index={i}
                selected={i === this.state.activeElement}
                parent={that.me}
                mouseOverEvent={() => {this.setState({ activeElement: i })}}
                clickEvent={cmd.action}
                ></CmdSearchItem>
              ))}
              
              { searchResults.length < 1 ? <span className="emptyMessage">Nichts gefunden. Nochmal versuchen?</span> : null }

            </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  openModal: state.navigation.openModal,
  modalData: state.navigation.modalData,
  token: state.authentication.userData.token,
  queries: state.query.queries,
});

const mapDispatchToProps = dispatch => ({
  closeModal: () => dispatch(closeModal()),
  genericDispatch: (action) => dispatch(action)
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(CmdSearchModal));
