import React, { useEffect } from "react";
import { MaterialIcons } from "react-web-vector-icons";
import { useState } from "react";
import { isCtrlKeyActive, ctrlKeyName, altKeyName } from "../../utils/other";

let expandedGlobal = false;

export const ShortcutHelp = () => {
  const [expanded, setExpanded] = useState(false);

  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  function handleKeyDown(e) {
    // if (!e.key || ((e.target.tagName.toUpperCase() === 'INPUT' || e.target.tagName.toUpperCase() === 'TEXTAREA') && 
    //   !e.target.hasAttribute('readonly'))) return;
  
    if ((isCtrlKeyActive(e)) && e.key.toLowerCase() === 'h') {
      e.preventDefault();
      setExpanded(!expandedGlobal);
    }
  };

  // Set global variable because state updates are not
  // passed to keydown event listeners
  useEffect(() => {
    expandedGlobal = expanded;
  }, [ expanded ]);

  const shortcuts = [
    {
      keys: [ ctrlKeyName, "F" ],
      action: "Suchfeld fokussieren"
    }, {
      keys: [ ctrlKeyName, "O" ],
      action: "Neue Suche anlegen"
    }, {
      keys: [ "Esc" ],
      action: "Abbrechen von Aktionen"
    }, {
      keys: [ "Entf" ],
      action: "Entfernen ausgew. Elemente"
    }, {
      keys: [ "Enter" ],
      action: "Bestätigen von Aktionen"
    }, {
      keys: [ altKeyName, "Up/Down" ],
      action: "Navigation zwischen Ansichten"
    }, {
      keys: [ ctrlKeyName, "K" ],
      action: "Navigationsmenü öffnen"
    },
  ];

  const shortcutList = shortcuts.map((s, i) => {
    const keyList = s.keys.map((key, ind) => {
      return (
        <React.Fragment key={ind}>
          {ind > 0 && <span className="plus">+</span>}
          <span className="key">{key}</span>
        </React.Fragment>
      )
    })
    return (
      <div key={i} className="shortcut">
        <div className="key-wrapper">{keyList}</div>
        <span className="action">{s.action}</span>
      </div>
    )
  });

  const shortcutComp = (
    <>
    <div className="shortcut-container">
      <h3>Globale Shortcuts</h3>
      <div className="shortcut-help">
        {shortcutList}
      </div>
    </div>
    </>
  )

  return (
    <>
      <div className="shortcut-spacer" />

      {expanded && shortcutComp}

      <div className="shortcut-toggle"  onClick={() => setExpanded(!expanded)}>
        <MaterialIcons name="keyboard" size={24} />
        <span>Shortcut Hilfe ({ctrlKeyName} + H)</span>
        <MaterialIcons name={expanded ? "keyboard-arrow-down" : "keyboard-arrow-up"} size={24} />
      </div>
    </>
  )
}