import React, { Component } from 'react';
import './Tour.scss';
import { MaterialIcons } from 'react-web-vector-icons';
import { connect } from 'react-redux';
import { updateOneTimeEventRequested } from './../../actions/navigation';
import { withRouter } from 'react-router-dom';
import { addElement } from './../../actions/element';
import { getReference, ReferenceNames } from '../../utils/reference';

class Tour extends Component {
  constructor(props) {
    super(props);

    this.guides = [
      {
        id: 0,
        title: `Hallo, ${props.firstName}!`,
        desc:
          'Damit du direkt loslegen kannst, zeigen wir dir zunächst in einem kurzen Tutorial die wichtigsten Funktionen von Tagstack.'
      },
      {
        id: 1,
        title: 'Elemente anlegen',
        desc:
          'Mit diesem Eingabefeld kannst du Elemente hinzufügen. Außerdem kannst du Tags verwenden, um das Element später schnell wieder zu finden. Auch Links kannst du einfach in das Feld einfügen, diese werden dann besonders hervorgehoben.',
        example: 'Praktischer Alltagshelfer #tools https://tagstack.app'
      },
      {
        id: 2,
        title: 'Elemente',
        desc:
          'Erstellte Elemente werden in der Zeitachse angezeigt. Die angegebenen Tags und auch der Link wurden automatisch erkannt.'
      },
      {
        id: 3,
        title: 'Elemente finden',
        desc:
          'In der Suchleiste kannst du nach Elementen suchen oder auch nach deren Tags filtern. Alle Elemente auf die dieser Filter zutrifft werden dir dann angezeigt.',
        example: '#tools helfer'
      },
      {
        id: 4,
        title: 'Gespeicherte Suchen',
        desc:
          'Unter der Zeitachse kannst du oft genutzte Suchabfragen speichern und mit einem Klick ausführen. So kannst du direkt nach bestimmten Filtern suchen, ohne diese ständig in die Suchleiste tippen zu müssen.'
      },
      {
        id: 5,
        title: "Los geht's!",
        desc:
          'Wir freuen uns, dich als Nutzer willkommen zu heißen. Falls du noch Fragen oder Feedback hast, kannst du uns unter tagstack@mbng.de kontaktieren.'
      }
    ];
    this.state = {
      currentGuide: 0,
      width: document.documentElement.clientWidth,
      height: document.documentElement.clientHeight,
      elementCreated: false
    };
  }

  _nextGuide = () => {
    let setElemCreated = false;
    if (this.state.currentGuide === 1 && !this.state.elementCreated) {
      const { token, workspaceId, language } = this.props;
      this.props.addElement(
        { token, workspaceId, language },
        { title: 'Praktischer Alltagshelfer', tags: [ 'tools' ], links: [ 'https://tagstack.app' ], files: [] }
      );
      setElemCreated = true;
    }
    if (this.state.currentGuide + 1 !== this.guides.length) {
      this.setState(
        {
          ...this.state,
          currentGuide: this.state.currentGuide + 1,
          elementCreated: !this.state.elementCreated ? setElemCreated : true
        },
        () => {
          setTimeout(() => this.resetAllInputs(), 100);
          const guide = this.guides[this.state.currentGuide];
          if (guide.example && guide.component) this.typeWriteExample(guide.component, guide.example, this.state.currentGuide);
        }
      );
    }
  };

  _previousGuide = () => {
    if (this.state.currentGuide !== 0) {
      this.setState(
        {
          ...this.state,
          currentGuide: this.state.currentGuide - 1
        },
        () => {
          setTimeout(() => this.resetAllInputs(), 100);
          const guide = this.guides[this.state.currentGuide];
          if (guide.example && guide.component) this.typeWriteExample(guide.component, guide.example, this.state.currentGuide);
        }
      );
    }
  };

  componentDidMount() {
    window.addEventListener('resize', this.updateDimensions);
  }
  componentWillUnmount() {
    window.removeEventListener('resize', this.updateDimensions);
  }

  componentDidUpdate(prevProps) {
    const { elements } = this.props;
    if (prevProps.elements && elements && prevProps.elements !== elements) {
      const scrollView = getReference(ReferenceNames.ELEMENT_SCROLL_VIEW);
      const elementList = getReference(ReferenceNames.ELEMENT_LIST);
      scrollView.scrollTop = elementList.scrollHeight;
    }

    this.guides[1].component = getReference(ReferenceNames.ADD_ELEMENT_INPUT);
    this.guides[3].component = getReference(ReferenceNames.SEARCHBAR_INPUT);
  }

  updateDimensions = () => {
    this.setState({
      ...this.state,
      width: document.documentElement.clientWidth,
      height: document.documentElement.clientHeight
    });
  };

  typeWriteExample = (component, text, currentGuide) => {
    let speed = 50;
    let i = 0;
    setTimeout(() => {
      // React overrides regular input value setter so this is required
      // Check if component is input or textarea and choose appropriate value

      let nativeInputValueSetter = this.getNativeInputValueSetter(component);
      this.doTypeWrite(i, nativeInputValueSetter, component, text, speed, currentGuide);
    }, 1000);
  };

  getNativeInputValueSetter = comp => {
    if (comp.tagName.toUpperCase() === 'INPUT') {
      return Object.getOwnPropertyDescriptor(window.HTMLInputElement.prototype, 'value').set;
    }
    if (comp.tagName.toUpperCase() === 'TEXTAREA') {
      return Object.getOwnPropertyDescriptor(window.HTMLTextAreaElement.prototype, 'value').set;
    }
  };

  doTypeWrite = (i, nvs, cmp, text, speed, currentGuide) => {
    if (i <= text.length) {
      nvs.call(cmp, text.substring(0, i));
      let ev2 = new Event('input', { bubbles: true });
      cmp.dispatchEvent(ev2);
      i++;
      if (currentGuide === this.state.currentGuide)
        setTimeout(() => this.doTypeWrite(i, nvs, cmp, text, speed, currentGuide), speed);
    }
  };

  _beginFinishTour = () => {
    this.resetAllInputs();
    this.props.updateOneTimeEventRequested(
      {
        token: this.props.token
      },
      'finishedTutorial'
    );
  };

  resetAllInputs = () => {
    this.guides.forEach(g => {
      // Reset modified inputs
      if (g.component) {
        let nivs = this.getNativeInputValueSetter(g.component);
        nivs.call(g.component, '');
        let ev2 = new Event('input', { bubbles: true });
        g.component.dispatchEvent(ev2);
      }
    });
  };

  render() {
    if (this.props.location.pathname !== '/timeline') return null;

    let { width, height, currentGuide } = this.state;
    let guide = this.guides[currentGuide];

    return (
      <div className="tour">
        <div className={'infoContainer pos-' + currentGuide}>
          {/* <div className="close" onClick={() => this._beginFinishTour()}>
            <MaterialIcons name="close" size={18} />
          </div> */}
          <h2>{guide.title}</h2>
          <p>{guide.desc}</p>
          {guide.example ? (
            <div className="exampleWrapper">
              <h4>Beispiel:</h4>
              <div className="example">
                <span>{guide.example}</span>
              </div>
            </div>
          ) : null}

          <div className="navigation">
            <div className={currentGuide === 0 ? 'backButton hidden' : 'backButton'} onClick={this._previousGuide}>
              <MaterialIcons name="arrow-back" size={28} />
            </div>
            <div className="progress">
              <span>{`${currentGuide + 1} / ${this.guides.length}`}</span>
            </div>
            {currentGuide === this.guides.length - 1 ? (
              <div className="finishWrapper material">
                <button className="primary" onClick={() => this._beginFinishTour()}>
                  Loslegen
                </button>
              </div>
            ) : (
              <div className="forwardButton" onClick={this._nextGuide}>
                <MaterialIcons name="arrow-forward" size={28} />
              </div>
            )}
          </div>
        </div>
        <svg className="svgWrapper" width={width} height={height}>
          <defs>
            <mask id="mask" x="0" y="0">
              <rect x="0" y="0" width={width} height={height} fill="#fff" />
              {/* {currentGuide === 1 ? (
                <ellipse cx="28" cy="84" rx="56" ry="56" className="highlight" />
              ) : null} */}
              {currentGuide === 1 ? (
                <rect className="addRect" x="0" y="0" height="80" width="calc(100% - 640px)" rx="4" ry="4" />
              ) : null}
              {currentGuide === 2 ? (
                <rect className="showElemRect" x="0" y="0" height="260" width="592" rx="4" ry="4" />
              ) : null}
              {currentGuide === 3 ? (
                <rect className="searchBarRect" x="0" y="0" height="56" width="600" rx="4" ry="4" />
              ) : null}
              {currentGuide === 4 ? <rect className="x" x="56" y="212" height="116" width="280" rx="4" ry="4" /> : null}
            </mask>
          </defs>
          <rect x="0" y="0" width={width} height={height} mask="url(#mask)" fillOpacity="0.65" />
        </svg>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  token: state.authentication.userData.token,
  firstName: state.authentication.userData.firstName,
  workspaceId: state.authentication.userData.workspaceId,
  language: state.preferences.language,
  elements: state.element.elements
});

const mapDispatchToProps = dispatch => ({
  updateOneTimeEventRequested: (metadata, eventName) => dispatch(updateOneTimeEventRequested(metadata, eventName)),
  addElement: (metadata, elemData) => dispatch(addElement(metadata, elemData))
});
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Tour));
