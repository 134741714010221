import React, { Component } from 'react';
import { connect } from 'react-redux';
import { MaterialIcons } from 'react-web-vector-icons';
import { openModal } from './../../actions/navigation';

class AddQuery extends Component {
  constructor(props) {
    super(props);

    this.input = React.createRef();
    this.submitBtn = React.createRef();
    this.state = { name: '', focused: false };
  }

  _focusInput = () => {
    this.input.current.focus();
  };

  _onKeyDown = e => {
    if (e.key === 'Enter') {
      e.preventDefault();
      this.openQueryModal();
    }
  };

  openQueryModal = () => {
    if (!(this.state.name || this.state.focused)) return;

    // document.activeElement.blur();
    this.submitBtn.current.classList.remove('shown');
    this.props.openModal(1, { name: this.state.name });

    setTimeout(() => {
      this.setState({ ...this.state, name: '', focused: false });
    }, 50);
  };

  _onChange = e => {
    this.setState({ ...this.state, name: e.target.value });

    if (e.target.value === '') {
      this.submitBtn.current.classList.remove('shown');
    } else {
      if (!this.submitBtn.current.classList.contains('shown')) this.submitBtn.current.classList.add('shown');
    }
  };

  render() {
    const { focused } = this.state;
    return (
      <div
        className={this.state.name || this.state.focused ? 'addQuery filled' : 'addQuery'}
        onClick={() => (this.input === document.activeElement ? null : this._focusInput())}
      >
        <div className="addIcon">
          <MaterialIcons name="add" size={23} />
        </div>
        <input
          ref={this.input}
          onChange={e => this._onChange(e)}
          onKeyDown={e => this._onKeyDown(e)}
          value={this.state.name}
          type="text"
          spellCheck={false}
          placeholder={focused ? "Bezeichnung wählen" : "Hinzufügen"}
          onFocus={() => this.setState({ ...this.state, focused: true })}
          onBlur={() => this.setState({ ...this.state, focused: false })}
          />
        <div ref={this.submitBtn} onClick={this.openQueryModal} onFocus={e => e.target.blur()} className="submitBtn">
          <MaterialIcons name="arrow-forward" size={23} />
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  openModal: (modalId, modalData) => dispatch(openModal(modalId, modalData))
});

export default connect(null, mapDispatchToProps)(AddQuery);
