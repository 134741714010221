import React from 'react';
import { MaterialIcons } from 'react-web-vector-icons';
import { getReference, ReferenceNames } from '../../utils/reference';

export const ScrollToBottomButton = ({ shown, newIndicatorAmount }) => {
  return (
    <div
      className={shown ? "scrollToBottomFab shown" : "scrollToBottomFab"}
      onClick={scrollToBottom}
    >
      <MaterialIcons name="arrow-downward" size={28} />
      {newIndicatorAmount > 0 && <div className="newAmount">
        <span>{newIndicatorAmount}</span>
      </div>}
    </div>
  );
};

const scrollToBottom = () => {
  const scrollView = getReference(ReferenceNames.ELEMENT_SCROLL_VIEW);
  const elementList = getReference(ReferenceNames.ELEMENT_LIST);
  scrollView.scrollTop = elementList.scrollHeight;
}