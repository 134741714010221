import React, { Component } from "react";
import "./SettingsView.scss";
import { SettingHeader } from "../components/Settings/SettingHeader";
import SettingMenu from "../components/Settings/SettingMenu";
import SettingContainer from "../components/Settings/SettingContainer";

export class SettingsView extends Component {
  render() {
    return (
      <div className="settingsView">
        <SettingHeader />
        <div className="settingContent">
          <SettingMenu />
          <SettingContainer />
        </div>
      </div>
    );
  }
}
