import React from 'react';
import { useHistory } from 'react-router-dom';

export const BoardTile = ({ id, name, theme }) => {
  const history = useHistory();

  return (
    <div
      className="boardTile"
      style={{ backgroundImage: `url('/images/board-${theme}.png')` }}
      onClick={() => history.push(`/board/${id}`)}
    >
      <span className="title">{name}</span>
    </div>
  );
};
