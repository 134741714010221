import React, { Component } from "react";
import { MaterialIcons } from "react-web-vector-icons";

export class SettingMenuItem extends Component {
  render() {
    let { id, active, icon, title, desc } = this.props;
    return (
      <div
        className={id === active ? "settingMenuItem active" : "settingMenuItem"}
        onClick={() => this.props.onSelect(id)}
      >
        <div className="settingIcon">
          <MaterialIcons name={icon} size={32} />
        </div>
        <div className="settingDesc">
          <h4>{title}</h4>
          <span>{desc}</span>
        </div>
      </div>
    );
  }
}
