import { baseUrl } from "./config";

export const retrieveElementsRequest = metadata => {
  return new Promise((resolve, reject) => {
    fetch(`${baseUrl}/get-elements`, {
      method: "post",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${metadata.token}`
      },
      body: JSON.stringify({
        workspaceId: metadata.workspaceId,
        lastId: metadata.lastId,
        filter: metadata.filter
        // language: metadata.language
      })
    })
      .then(res => {
        res.json().then(rj => {
          // console.log(rj)
          resolve(rj);
        });
      })
      .catch(err => {
        reject(err);
      });
  });
};

export const retrieveUntaggedElementsRequest = metadata => {
  return new Promise((resolve, reject) => {
    fetch(`${baseUrl}/get-untagged-elements`, {
      method: "post",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${metadata.token}`
      },
      body: JSON.stringify({
        workspaceId: metadata.workspaceId,
        lastId: metadata.lastId,
        filter: metadata.filter
        // language: metadata.language
      })
    })
      .then(res => {
        res.json().then(rj => {
          // console.log(rj)
          resolve(rj);
        });
      })
      .catch(err => {
        reject(err);
      });
  });
};

export const addElementRequest = (metadata, elemData) => {
  return new Promise((resolve, reject) => {
    fetch(`${baseUrl}/add-element`, {
      method: "post",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${metadata.token}`
      },
      body: JSON.stringify({
        workspaceId: metadata.workspaceId,
        // language: metadata.language,
        title: elemData.title,
        tags: elemData.tags,
        links: elemData.links,
        files: elemData.files,
        reminders: elemData.reminders
      })
    })
      .then(res => {
        res.json().then(rj => {
          // console.log(rj)
          resolve(rj);
        });
      })
      .catch(err => {
        reject(err);
      });
  });
};

export const deleteElementRequest = (metadata, elementId) => {
  return new Promise((resolve, reject) => {
    fetch(`${baseUrl}/remove-element`, {
      method: "post",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${metadata.token}`
      },
      body: JSON.stringify({
        elementId: elementId
      })
    })
      .then(res => {
        res.json().then(rj => {
          // console.log(rj)
          resolve(rj);
        });
      })
      .catch(err => {
        reject(err);
      });
  });
};

export const deleteMultipleElementsRequest = (metadata, elementIds) => {
  return new Promise((resolve, reject) => {
    fetch(`${baseUrl}/remove-multiple-elements`, {
      method: "post",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${metadata.token}`
      },
      body: JSON.stringify({
        elementIds: elementIds
      })
    })
      .then(res => {
        res.json().then(rj => {
          // console.log(rj)
          resolve(rj);
        });
      })
      .catch(err => {
        reject(err);
      });
  });
};

export const updateElementRequest = (metadata, elemData) => {
  return new Promise((resolve, reject) => {
    fetch(`${baseUrl}/edit-element`, {
      method: "post",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${metadata.token}`
      },
      body: JSON.stringify({
        // language: metadata.language,
        elementId: elemData.elementId,
        title: elemData.title,
        tags: elemData.tags,
        links: elemData.links,
        files: elemData.files,
        reminders: elemData.reminders
      })
    })
      .then(res => {
        res.json().then(rj => {
          // console.log(rj)
          resolve(rj);
        });
      })
      .catch(err => {
        reject(err);
      });
  });
};

export const getTagListRequest = metadata => {
  return new Promise((resolve, reject) => {
    fetch(`${baseUrl}/get-tags`, {
      method: "post",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${metadata.token}`
      },
      body: JSON.stringify({
        workspaceId: metadata.workspaceId
        // language: metadata.language
      })
    })
      .then(res => {
        res.json().then(rj => {
          // console.log(rj)
          resolve(rj);
        });
      })
      .catch(err => {
        reject(err);
      });
  });
};

export const getPresignedURL = (metadata, fileData) => {
  return new Promise((resolve, reject) => {
    let { originalFile, assignedToElement, ...rest } = fileData;
    fetch(`${baseUrl}/get-presigned-url`, {
      method: "post",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${metadata.token}`
      },
      body: JSON.stringify({
        // language: metadata.language,
        ...rest
      })
    })
      .then(res => {
        res.json().then(rj => {
          // console.log(rj)
          resolve(rj);
        });
      })
      .catch(err => {
        reject(err);
      });
  });
};

export const submitUploadFileRequest = (signedResponse, file) => {
  return new Promise((resolve, reject) => {
    let formData = new FormData();
    Object.keys(signedResponse.fields).forEach(f => {
      formData.append(f, signedResponse.fields[f]);
    });
    formData.append('file', file.originalFile);

    fetch(signedResponse.url, {
      method: "POST",
      headers: {
        Accept: "application/json"
      },
      body: formData
    })
      .then(res => {
        if (res.status === 204) {
          res.text().then(rj => {
            resolve({ status: res.status, body: rj });
          });
        } else {
          // Handle XML error message
          res
            .text()
            .then(str =>
              new window.DOMParser().parseFromString(str, "text/xml")
            )
            .then(data => {
              let msg =
                data.childNodes[0].childNodes[0].textContent +
                ": " +
                data.childNodes[0].childNodes[1].textContent;
              resolve({ status: res.status, body: msg });
            });
        }
      })
      .catch(err => {
        reject(err);
      });
  });
};

// export const submitUploadFileRequest = signedResponse => {
//     return new Promise((resolve, reject) => {
//         let formData = new FormData();
//         Object.keys(signedResponse.fields).forEach(f => {
//             formData.append(f, signedResponse.fields[f]);
//         });
//         formData.append("x-amz-acl", "public-read");

//         fetch(signedResponse.url, {
//             method: 'PUT',
//             body: formData
//         }).then(res => {
//             res.json().then(rj => {
//                 console.log(rj)
//                 resolve(rj);
//             })
//         }).catch(err => {
//             reject(err);
//         });

// let data = new FormData();
// Object.keys(signedResponse.fields).forEach(f => {
//     data.append(f, signedResponse.fields[f]);
// });

// let xhr = new XMLHttpRequest();
// xhr.open('POST', signedResponse.url, true);
// xhr.onload = function () {
//     // do something to response
//     // console.log(this.responseText);
//     resolve(this.responseText)
// };
//         // xhr.send(data);
//     });
// }
