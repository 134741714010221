import React, { useRef } from 'react';
import { MaterialIcons } from 'react-web-vector-icons';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { openModal } from '../../actions/navigation';
import { modalCodes } from '../../utils/enums';

export const AddColumnCard = () => {
  const [ columnName, setColumnName ] = useState('');
  const dispatch = useDispatch();
  const inputRef = useRef(null);

  const submitForm = () => {
    dispatch(openModal(modalCodes.ADD_BOARD_LIST_MODAL, { name: columnName }));
    setColumnName('');
  }

  return (
    <div className="addColumnCard">
      <div className="addIcon" onClick={() => inputRef.current.focus()}>
        <MaterialIcons name="add" size={23} />
      </div>
      <input
        type="text"
        ref={inputRef}
        placeholder="Name der Liste"
        value={columnName}
        onChange={e => setColumnName(e.target.value)}
        onKeyDown={e => {
          if (e.keyCode === 13) submitForm();
        }}
      />
      <div
        className={columnName !== '' ? 'submitBtn shown' : 'submitBtn'}
        onClick={() => submitForm()}
      >
        <MaterialIcons name="arrow-forward" size={23} />
      </div>
    </div>
  );
};
