import React, { Component } from "react";
import "./MobileView.scss";
import MobileMinTopBar from "./../../components/TopBar/Mobile/MobileMinTopBar";
import { connect } from "react-redux";
import { toggleMobileMenu } from "../../actions/navigation";
import { logout } from "../../actions/authentication";

class MobileSettingsView extends Component {
  render() {
    return (
      <div
        className={
          this.props.mobileMenuOpen === true
            ? "mobileViewContent openMenu"
            : "mobileViewContent"
        }
      >
        <div
          className="openMenuOverlay"
          onClick={() => this.props.toggleMobileMenu()}
        />
        <MobileMinTopBar
          title="Einstellungen"
          mobileMenuOpen={this.props.mobileMenuOpen}
        />
        <div className="contentContainer">
          <div className="settingsList material">
            <p>Aktuell nur in der Desktopansicht verfügbar</p>
            <button className="primary" onClick={() => this.props.logout()}>
              Abmelden
            </button>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  mobileMenuOpen: state.navigation.mobileMenuOpen
});

const mapDispatchToProps = dispatch => ({
  toggleMobileMenu: () => dispatch(toggleMobileMenu()),
  logout: () => dispatch(logout())
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MobileSettingsView);
