import React, { Component } from "react";
import "./Authentication.scss";
import { connect } from "react-redux";
import {
  requestRegisterFetch,
  resetError
} from "./../../actions/authentication";
import { Redirect } from "react-router-dom";

class RegisterView extends Component {
  constructor(props) {
    super(props);

    const { firstName, lastName, email } = props.auth.userData;

    this.state = {
      firstName: firstName == null ? "" : firstName,
      lastName: lastName == null ? "" : lastName,
      email: email == null ? "" : email,
      password: "",
      passwordConfirmation: ""
    };

    this.errorMsg = React.createRef();
    this.fetchReq = false;
  }

  componentDidUpdate(prevProps) {
    if (this.errorMsg.current && this.props.auth.errorMsg === prevProps.auth.errorMsg && this.fetchReq) {
      this.fetchReq = false;
      this.errorMsg.current.classList.remove("initialFade");
      this.errorMsg.current.classList.add("pulsating");
      setTimeout(() => {
        if (!this.errorMsg.current) return;
        this.errorMsg.current.classList.remove("pulsating");
      }, 2000)
    }
  }

  _onChangeInput = e => {
    this.setState({ ...this.state, [e.target.name]: e.target.value });
  };

  _onKeyDown = e => {
    if (e.key === "Enter") {
      const {
        firstName,
        lastName,
        email,
        password,
        passwordConfirmation
      } = this.state;
      this.fetchReq = true;
      this.props.requestRegisterFetch(
        { history: this.props.history, language: this.props.history },
        { firstName: firstName, lastName: lastName, email: email, password: password, passwordConfirmation: passwordConfirmation}
      );
    }
  };

  render() {
    if (this.props.auth.loggedIn === true) {
      return <Redirect to="timeline" />;
    } else {
      const {
        firstName,
        lastName,
        email,
        password,
        passwordConfirmation
      } = this.state;
      return (
        <div className="centeredWrapper">
          <div className="registerContainer material">
            <h3>Neues Konto erstellen</h3>
            <h5>Wie dürfen wir dich ansprechen?</h5>
            <div className="parallelInput">
              <div>
                <input
                  type="text"
                  name="firstName"
                  value={this.state.firstName}
                  placeholder=" "
                  onChange={e => this._onChangeInput(e)}
                  onKeyDown={e => this._onKeyDown(e)}
                  spellCheck="false"
                />
                <label placeholder="Vorname" />
              </div>
              <div>
                <input
                  type="text"
                  name="lastName"
                  value={this.state.lastName}
                  placeholder=" "
                  onChange={e => this._onChangeInput(e)}
                  onKeyDown={e => this._onKeyDown(e)}
                  spellCheck="false"
                />
                <label placeholder="Nachname" />
              </div>
            </div>
            <h5>Wie lautet deine E-Mail Adresse?</h5>
            <div>
              <input
                type="email"
                name="email"
                value={this.state.email}
                placeholder=" "
                onChange={e => this._onChangeInput(e)}
                onKeyDown={e => this._onKeyDown(e)}
                spellCheck="false"
              />
              <label placeholder="E-Mail Adresse" />
            </div>
            <h5>Bitte wähle ein sicheres Passwort</h5>
            <div className="parallelInput">
              <div>
                <input
                  type="password"
                  name="password"
                  placeholder=" "
                  onChange={e => this._onChangeInput(e)}
                  onKeyDown={e => this._onKeyDown(e)}
                />
                <label placeholder="Passwort" />
              </div>
              <div>
                <input
                  type="password"
                  name="passwordConfirmation"
                  placeholder=" "
                  onChange={e => this._onChangeInput(e)}
                  onKeyDown={e => this._onKeyDown(e)}
                />
                <label placeholder="Bestätigen" />
              </div>
            </div>
            {this.props.auth.errorOccured === true ? (
              <p ref={this.errorMsg} className="errorMsg initialFade">{this.props.auth.errorMsg}</p>
            ) : null}
            <div className="parallelInput actionButtons">
              <a
                href
                className="flat"
                onClick={() => {
                  this.props.resetError();
                  this.props.history.push("/login");
                }}
              >
                Ich habe bereits ein Konto
              </a>
              <button
                className="primary"
                onClick={() => {
                  this.fetchReq = true;
                  this.props.requestRegisterFetch(
                    { history: this.props.history, language: this.props.history },
                    { firstName: firstName, lastName: lastName, email: email, password: password, passwordConfirmation: passwordConfirmation}
                  )
                }}
              >
                Registrieren
              </button>
            </div>
          </div>
        </div>
      );
    }
  }
}

const mapStateToProps = state => ({
  auth: state.authentication,
  language: state.preferences.language
});

const mapDispatchToProps = dispatch => ({
  resetError: () => dispatch(resetError()),
  requestRegisterFetch: (
    metadata,
    registerData
  ) =>
    dispatch(
      requestRegisterFetch(
        metadata,
        registerData
      )
    )
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RegisterView);
