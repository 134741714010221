export const requestLoginFetch = (metadata, loginData) => ({
    type: "LOGIN_FETCH_REQUESTED",
    metadata: metadata,
    loginData: loginData
});

export const requestRegisterFetch = (metadata, registerData) => ({
    type: "REGISTER_FETCH_REQUESTED",
    metadata: metadata,
    registerData: registerData
});

export const resetError = () => ({
    type: "RESET_ERROR"
});

export const logout = () => ({
    type: "LOGOUT"
});

export const updateUserData = userData => ({
    type: "UPDATE_USER_DATA",
    userData: userData
});

export const reqestResetPasswortRequested = (email, onSuccess) => ({
    type: "REQUEST_RESET_PASSWORD_REQUESTED",
    email,
    onSuccess
});

export const resetPasswordRequested = (resetToken, newPassword, newPasswordConfirmation, onSuccess) => ({
    type: "RESET_PASSWORD_REQUESTED",
    resetToken,
    newPassword,
    newPasswordConfirmation,
    onSuccess
});

export const setResetPasswordError = val => ({
    type: "SET_RESET_PASSWORD_ERROR",
    val
});