import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
import CacheRoute from 'react-router-cache-route'

class ProtectedRoute extends Component {
  render() {
    return (
      <CacheRoute path={this.props.path}
        render={props =>
          this.props.loggedIn === true ? (
            <this.props.component />
          ) : (
            <Redirect
              to={{
                pathname: "/login",
                state: { from: this.props.location }
              }}
            />
          )
        }
      />
    );
  }
}

const mapStateToProps = state => ({
  loggedIn: state.authentication.loggedIn
});

export default connect(mapStateToProps)(ProtectedRoute);
