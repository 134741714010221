import React, { useState, useEffect } from 'react';
import './AddElementBar.scss';
import { MaterialIcons } from 'react-web-vector-icons';
import { SimpleInput } from '../SimpleInput/SimpleInput';
import { useSelector, useDispatch } from 'react-redux';
import { addElement, addFilesForUpload, uploadFileRequested, addElementFailed } from '../../actions/element';
import { LoadingBar } from '../Loading/LoadingBar';
import { ErrorBar } from './ErrorBar';
import { ReferenceNames } from '../../utils/reference';
import { FileUploadBar } from './FileUploadBar';
import { parseTagsFromString } from '../../utils/parsing';
import { maxFreeTotalUploaded, maxFreeTotalUploadedString, maxFreeUploadSize, maxFreeUploadSizeString, maxPaidTotalUploaded, maxPaidTotalUploadedString, maxPaidUploadSize, maxPaidUploadSizeString, upgradeLink } from '../../utils/limitations';

export const AddElementBar = ({ history, location }) => {
  const [ showSubmit, setShowSubmit ] = useState(false);
  const [ inputValue, setInputValue ] = useState(null);
  const [ loadingState, setLoadingState ] = useState({ loading: false, success: null });
  const [ heightOffset, setHeightOffset ] = useState(0);
  const [ highlighted ] = useState(false);
  const [ showFiles, setShowFiles ] = useState(false);
  const [ queryStringSet, setQueryStringSet ] = useState(false);

  const { token, workspaceId } = useSelector(state => state.authentication.userData);
  const language = useSelector(state => state.preferences.language);
  const addError = useSelector(state => state.element.addError);
  const uploadedFiles = useSelector(state => state.element.uploadedFiles);
  const queries = useSelector(state => state.query.queries);
  const isPaidUser = useSelector(state => state.authentication.userData.isPaidUser);
  const storageUsed = useSelector(state => state.authentication.userData.storageUsed);
  const dispatch = useDispatch();

  // OLD FOCUS INPUT SHORTCUT
  // useEffect(() => {
  //   document.addEventListener('keydown', handleKeyDown);
  //   return () => {
  //     document.removeEventListener('keydown', handleKeyDown);
  //   };
  // }, []);

  // function handleKeyDown(e) {
  //   // if (!e.key || ((e.target.tagName.toUpperCase() === 'INPUT' || e.target.tagName.toUpperCase() === 'TEXTAREA') && 
  //   //   !e.target.hasAttribute('readonly'))) return;
  
  //   if (e.shiftKey && e.keyCode === 32) { // Space
  //     e.preventDefault();
  //     const ref = getReference(ReferenceNames.ADD_ELEMENT_INPUT);
  
  //     // if (e.shiftKey) {
  //     setInputValue('');

  //     if (ref) {
  //       const nivs = Object.getOwnPropertyDescriptor(window.HTMLTextAreaElement.prototype, 'value').set;
  //       nivs.call(ref, '');
  //       const ev2 = new Event("input", { bubbles: true });
  //       ref.dispatchEvent(ev2);
  //     }

  //     history.push('/timeline');
  //     // }
  
  //     if (ref) {
  //       ref.focus();
  
  //       ref.selectionStart = ref.value.length;
  //       ref.selectionEnd = ref.value.length;
  //     }
  
  //     setHighlighted(true);
  //     setTimeout(() => {
  //       setHighlighted(false);
  //     }, 320);
  //   }
  // };

  useEffect(
    () => {
      const { loading, success } = loadingState;
      if (!loading && success) {
        setLoadingState({ loading: false, success: null });
        setShowSubmit(false);
        setQueryStringSet(false);

        // Moved into saga to preving this being called early
        // const scrollView = getReference(ReferenceNames.ELEMENT_SCROLL_VIEW);
        // const elementList = getReference(ReferenceNames.ELEMENT_LIST);
        // scrollView.scrollTop = elementList.scrollHeight;
      }
    },
    [ loadingState ]
  );

  useEffect(
    () => {
      setShowFiles(Object.keys(uploadedFiles).length > 0 ? true : false);
    },
    [ uploadedFiles ]
  );

  const fileInputRef = React.createRef(null);
  const loc = location.pathname.split('/');
  const activeQuery = queries && loc[1] === 'query' ? queries.filter(q => q.id === Number.parseInt(loc[2]))[0] : null;

  // Reset query string in input when location or query itself changes
  useEffect(() => {
    setQueryStringSet(false);
  }, [ location.pathname, activeQuery ]);

  const activeQueryString = activeQuery ? activeQuery.query : null;
  let includedTagsString = "";
  
  if (activeQueryString) {
    const includedFilterTags = parseTagsFromString(activeQueryString);
    includedFilterTags.forEach(t => {
      includedTagsString += "#" + t + " ";
    });
  }

  const onFileChange = e => {
    const files = Array.from(e.target.files);
    const convFiles = {};
  
    // Reformat files
    files.forEach((file, i) => {
      // Check if storage limit reached before upload
      const uploadLimit = isPaidUser ? maxPaidTotalUploaded : maxFreeTotalUploaded;
      if (storageUsed >= uploadLimit) {
        if (isPaidUser) {
          dispatch(addElementFailed({ message: `Die maximale Auslastung deines verfügbaren Speicherplatzes von ${maxPaidTotalUploadedString} wurde erreicht.`}));
          return;
        } else {
          dispatch(addElementFailed({ 
            message: `Die maximale Auslastung deines verfügbaren Speicherplatzes von ${maxFreeTotalUploadedString} wurde erreicht.`,
            actionMessage: `Upgrade auf Tagstack Plus um deinen verfügbaren Speicherplatz auf ${maxPaidTotalUploadedString} zu erweitern.`,
            actionLink: upgradeLink
          }));
          return;
        }
      }

      // Max filesize check
      if (isPaidUser) {
        if (file.size > maxPaidUploadSize) {
          dispatch(addElementFailed({ message: `Die ausgewählte Datei überschreitet die maximale Dateigröße von ${maxPaidUploadSizeString}.`}));
          return;
        }
      } else {
        if (file.size > maxFreeUploadSize) {
          dispatch(addElementFailed({ 
            message: `Die ausgewählte Datei überschreitet die maximale Dateigröße von ${maxFreeUploadSizeString}.`,
            actionMessage: `Upgrade auf Tagstack Plus um Dateien bis zu ${maxPaidUploadSizeString} hochladen zu können.`,
            actionLink: upgradeLink
          }));
          return;
        }
      }
  
      convFiles[i] = {
        fileName: file.name,
        fileType: file.type,
        fileSize: file.size,
        lastModified: file.lastModified,
        originalFile: file,
        assignedToElement: false
      };
    });
  
    // Add to state
    dispatch(addFilesForUpload(convFiles));
  
    // Begin uploading each
    Object.keys(convFiles).forEach(f => {
      dispatch(
        uploadFileRequested(
          {
            token,
            id: f
          },
          convFiles[f]
        )
      );
    });

    fileInputRef.current.value = null;
  };

  const submitAddElement = () => {
    setLoadingState({ loading: true, success: null });
  
    const files = Object.keys(uploadedFiles).map(f => {
      return uploadedFiles[f].remoteFileId;
    });
  
    const suIndex = files.indexOf(undefined);
    if (suIndex !== -1) {
      // At least one file hasn't finished uploading
      dispatch(addElementFailed({ message: 'Mindestens eine Datei wurde noch nicht vollständig hochgeladen.'}));
      return;
    }
  
    dispatch(addElement({ token, workspaceId, language, setLoadingState, boardView: false }, { ...inputValue, files }));
  };

  return (
    <div className={highlighted ? 'addElementBar highlighted' : 'addElementBar'}>
      <ErrorBar error={addError} offset={heightOffset} />
      <FileUploadBar uploadedFiles={uploadedFiles} visible={showFiles} offset={heightOffset} />
      <LoadingBar visible={loadingState.loading} />

      <input type="file" ref={fileInputRef} multiple="multiple" onChange={onFileChange} />
      <div className="iconButton" onClick={() => fileInputRef.current.click()}>
        <MaterialIcons name="attach-file" size={24} />
      </div>

      <SimpleInput
        placeholder="Kurze Beschreibung, #tags und Links einfügen"
        inputRefName={ReferenceNames.ADD_ELEMENT_INPUT}
        metadata={{
          token: token,
          workspaceId: workspaceId,
          language: language
        }}
        updateEvent={val => {
          setInputValue(val);
          !queryStringSet && setQueryStringSet(true);
        }}
        submitEvent={submitAddElement}
        showHelpIcon={false}
        borderless={true}
        tagSuggestionsFromTop={true}
        allowSubmitChangeEvent={setShowSubmit}
        resetToInitial={loadingState.success}
        heightChangeEvent={setHeightOffset}
        forceSetInitialValue={!queryStringSet}
        value={includedTagsString}
      />

      <div
        onClick={() =>
          submitAddElement(
            dispatch,
            setLoadingState,
            { token, workspaceId, language, setLoadingState },
            inputValue,
            uploadedFiles
          )}
        className={showSubmit ? 'iconButton shown submit' : 'iconButton submit'}
      >
        <MaterialIcons name="send" size={24} />
      </div>
    </div>
  );
};