import React from "react";
import { useSelector } from "react-redux";
import { Redirect } from "react-router-dom";
import { SettingHeader } from "../components/Settings/SettingHeader";

export const InsightsView = () => {

  const isPaidUser = useSelector(state => state.authentication.userData.isPaidUser);
  if (!isPaidUser) return <Redirect to="timeline" />;

  return (
    <div className="settingsView">
      <SettingHeader />
      <div className="settingContent">
        
      </div>
    </div>
  )
}