const initialState = {
    list: { }
}

export const notification = (state = initialState, action) => {
  switch (action.type) {
    case "CLEAR_ALL_NOTIFICATIONS": {
      return {
        ...state,
        list: { }
      }
    }
    case "ADD_NOTIFICATION": {
      return {
        ...state,
        list: {
            ...state.list,
            [action.notification.id]: {
                ...action.notification
            }
        }
      }
    }
    case "REMOVE_NOTIFICATION": {
        const { [action.id]: _, ...rest } = state.list;
        return {
            ...state,
            list: rest
        }
    }
    default:
      return state;
  }
};