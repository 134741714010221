import React, { Component } from 'react';
import './MobileView.scss';
import MobileTopBar from './../../components/TopBar/Mobile/MobileTopBar';
import ElementList from '../../components/ElementList/ElementList';
import { connect } from 'react-redux';
import AddElementBar from '../../components/Mobile/AddElementBar';
import { withRouter } from 'react-router-dom';
import { toggleMobileMenu } from '../../actions/navigation';
import { setReference, ReferenceNames } from '../../utils/reference';

class MobileDefaultView extends Component {
  constructor(props) {
    super(props);

    this.elementList = React.createRef();
    this.outOfBottom = true;

    this.state = { mobileViewportHeight: window.innerHeight };
  }

  componentDidMount() {
    window.addEventListener('resize', this.updateViewportHeight);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateViewportHeight);
  }

  updateViewportHeight = () => {
    this.setState({ ...this.state, mobileViewportHeight: window.innerHeight });
  };

  render() {
    /*
style={{ height: this.state.mobileViewportHeight + "px" }}
style={{ height: "calc(" + this.state.mobileViewportHeight + "px - 56px - 56px)" }}

    */
    return (
      <div className={this.props.mobileMenuOpen === true ? 'mobileViewContent openMenu' : 'mobileViewContent'}>
        <div className="openMenuOverlay" onClick={() => this.props.toggleMobileMenu()} />
        <MobileTopBar mobileMenuOpen={this.props.mobileMenuOpen} />
        <div
          ref={ref => setReference(ReferenceNames.ELEMENT_SCROLL_VIEW, ref)}
          onScroll={this.elementList.current ? this.elementList.current.handleScroll : null}
          className="contentContainer"
        >
          <ElementList location={this.props.location} ref={this.elementList} />
        </div>
        <AddElementBar location={this.props.location} />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  mobileMenuOpen: state.navigation.mobileMenuOpen
});

const mapDispatchToProps = dispatch => ({
  toggleMobileMenu: () => dispatch(toggleMobileMenu())
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MobileDefaultView));
