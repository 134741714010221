import React, { Component } from "react";
import { MaterialIcons } from "react-web-vector-icons";

export class NavigationItem extends Component {

  _switchView = (clickedView) => {
    this.props.switchEvent(clickedView);
  }

  render() {
    return (
      <div className="item" onClick={() => this._switchView(this.props.path)}>
        <MaterialIcons name={this.props.icon} size={36} />
        {/* <span>{this.props.tooltip}</span> */}
      </div>
    );
  }
}