import { put, takeEvery, call } from "redux-saga/effects";
import { updateSettingsRequest } from "../api/preferences";
import { genericHandleResult } from "./rootSaga";
import { showNotification } from '../actions/notification';

export function* watchAllPreferenceActions() {
  yield takeEvery("UPDATE_PERSONALIZATION_SETTINGS", beginUpdateSettings);
}

export function* beginUpdateSettings(action) {
  try {
    const result = yield call(
      updateSettingsRequest,
      action.metadata,
      action.settings
    );

    yield genericHandleResult(
      result,
      showNotification({ type: "success", title: "Settings saved", desc: "All changes have been saved" }),
      showNotification({ type: "error", title: "Settings update failed", desc: result.errorMsg })
    );
  } catch (e) {
    yield put(showNotification({ type: "error", title: "Settings update failed", desc: e.message }));
  }
}
