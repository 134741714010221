import React, { Component } from "react";
import "./MobileTopBar.scss";
import { MaterialIcons } from "react-web-vector-icons";
import { connect } from "react-redux";
import { toggleMobileMenu } from "./../../../actions/navigation";

class MobileMinTopBar extends Component {
  render() {
    return (
      <div className="mobileTopBar">
        <div
          className="burgerMenu"
          onClick={() => this.props.toggleMobileMenu()}
        >
          <MaterialIcons
            name={this.props.mobileMenuOpen === true ? "close" : "menu"}
            size={32}
          />
        </div>
        <div className="pageTitle">
          <h2>{this.props.title}</h2>
        </div>
        <div className="searchBarContainer"></div>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  toggleMobileMenu: () => dispatch(toggleMobileMenu())
});

export default 
  connect(
    null,
    mapDispatchToProps
  )(MobileMinTopBar);
