import React from "react";
import { MaterialIcons } from 'react-web-vector-icons';
import { useDispatch } from "react-redux";
import { resetAddElementError } from "../../actions/element";

export const ErrorBar = ({ error, offset }) => {

  const dispatch = useDispatch();
  const actionMsg = error && error.actionMessage ? (<>
    <br></br><a href={error.actionLink} className="actionMessage">{error.actionMessage}</a>
  </>) : null;

  return (
    <div className={error ? "errorBar shown" : "errorBar"} style={{ bottom: offset }}>
      {/* <div className="textContainer"> */}
        <span>{error && error.message}{actionMsg}</span>
        
      {/* </div> */}
      <div className="closeIcon" onClick={() => dispatch(resetAddElementError())}>
        <MaterialIcons name="close" size={24} />
      </div>
    </div>
  )
}