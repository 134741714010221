import React, { Component } from "react";
import "./SideMenu.scss";

export class SideMenuSlider extends Component {
  render() {
    return (
      <div className="slider" style={{transform: "translateY(" + this.props.pos * 56 + "px)"}}></div>
    );
  }
}
