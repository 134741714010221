export const switchTheme = themeName => ({
    type: "SWITCH_THEME",
    themeName: themeName
});

export const updatePersonalizationSettings = (metadata, settings) => ({
    type: "UPDATE_PERSONALIZATION_SETTINGS",
    metadata: metadata,
    settings: settings
});

export const setInitialUserSettings = settings => ({
    type: "SET_INITIAL_USER_SETTINGS",
    settings: settings
});