import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";

import { Provider } from "react-redux";
import { createStore, applyMiddleware } from "redux";
import { rootReducer } from "./reducers/root";
import createSagaMiddleware from "redux-saga";
import rootSaga from "./sagas/rootSaga";

import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { PersistGate } from 'redux-persist/integration/react';

import { composeWithDevTools } from 'remote-redux-devtools';

const persistConfig = {
  key: "root",
  storage,
  whitelist: ['authentication', 'preferences']
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const sagaMiddleware = createSagaMiddleware();
const composeEnhancers = composeWithDevTools({ realtime: true, hostname: 'localhost', port: 8000 });
// const composeEnhancers = composeWithDevTools({ realtime: true });
const store = createStore(persistedReducer, (window.location.hostname === "localhost" || window.location.hostname === "127.0.0.1") ?
 composeEnhancers(applyMiddleware(sagaMiddleware)) : applyMiddleware(sagaMiddleware));
sagaMiddleware.run(rootSaga);

const persistor = persistStore(store);

ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={<div />} persistor={persistor}>
      <App />
    </PersistGate>
  </Provider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
