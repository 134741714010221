import { put, takeEvery, call, select, takeLatest, delay } from 'redux-saga/effects';
import {
  retrieveElementsRequest,
  retrieveUntaggedElementsRequest,
  addElementRequest,
  deleteElementRequest,
  getTagListRequest,
  updateElementRequest,
  getPresignedURL,
  submitUploadFileRequest,
  deleteMultipleElementsRequest
} from './../api/elements';
import { genericHandleResult } from './rootSaga';
import { showNotification } from '../actions/notification';
import { closeModal } from '../actions/navigation';
import {
  addElementSucceeded,
  // addElementHiddenByFilter,
  // deleteElementHiddenByFilterSucceeded,
  deleteElementSucceeded,
  updateElementSucceeded,
  // updateElementHiddenByFilterSucceeded,
  uploadFinished,
  addElementFailed,
  socketInsertLinkSucceeded,
  deleteMultipleElementsSucceeded,
  // deleteMultipleElementsHiddenByFilterSucceeded,
  updateElementRequested,
  removeUploadedFile,
  deleteElementDirectly,
  addRevertableAction,
  increaseNewIndicatorAmount,
  resetUploadedFiles,
  resetAddElementError
} from '../actions/element';
import { statusCodes } from './../api/config';
import {
  deleteBoardElementSucceeded,
  addBoardElementDirectly,
  updateBoardElementDirectly,
  socketInsertBoardLinkSucceeded,
  deleteMultipleBoardElementsSucceeded
} from '../actions/board';
import { tagsIncludedApplies, tagsExcludedApplies } from '../utils/filter';
import { getReference, ReferenceNames } from '../utils/reference';

const timelinePaths = [ 'timeline', 'untagged', 'query' ];

export function* watchAllElementActions() {
  yield takeLatest('RETRIEVE_ELEMENTS_REQUESTED', action => beginRetrieveElements(action, retrieveElementsRequest));
  yield takeLatest('RETRIEVE_UNTAGGED_ELEMENTS_REQUESTED', action =>
    beginRetrieveElements(action, retrieveUntaggedElementsRequest)
  );

  yield takeEvery('ADD_ELEMENT_REQUESTED', beginAddElement);
  yield takeEvery('ADD_ELEMENT_DIRECTLY', beginAddElementDirectly);

  yield takeEvery('REVERTABLE_DELETE_ELEMENT_REQUESTED', beginRevertableDeleteElement);
  yield takeEvery('DELETE_ELEMENT_REQUESTED', beginDeleteElement);
  yield takeEvery('DELETE_MULTIPLE_ELEMENTS_REQUESTED', beginDeleteMultipleElements);
  yield takeEvery('DELETE_ELEMENT_DIRECTLY', beginDeleteElementDirectly);
  yield takeEvery('DELETE_MULTIPLE_ELEMENTS_DIRECTLY', beginDeleteMultipleElementsDirectly);

  yield takeLatest('UPDATE_ELEMENT_REQUESTED', beginUpdateElement);
  yield takeEvery('UPDATE_ELEMENT_DIRECTLY', beginUpdateElementDirectly);

  yield takeEvery('TAG_LIST_REQUESTED', beginGetTagList);
  yield takeEvery('SOCKET_INSERT_LINK_DIRECTLY', beginSocketInsertLinkDirectly);
  yield takeEvery('UPLOAD_FILE_REQUESTED', beginUploadFile);
}

const getShowLoadingAnim = state => state.element.showLoadingAnim;
export function* beginRetrieveElements(action, apiEndpoint) {
  try {
    let showLoadingAnim = yield select(getShowLoadingAnim);
    if (!showLoadingAnim && !action.append) yield put({ type: 'SHOW_LOADING_ANIM' });

    // Delay to wait for following keystrokes
    yield delay(150);

    const result = yield call(apiEndpoint, action.metadata);
    if (action.setReqPendingDone) action.setReqPendingDone();

    yield genericHandleResult(
      result,
      {
        type: 'RETRIEVE_ELEMENTS_SUCCEEDED',
        elementData: result.elements,
        append: action.append
      },
      {
        type: 'RETRIEVE_ELEMENTS_FAILED',
        errorMsg: result.errorMsg
      }
    );
  } catch (e) {
    yield put({
      type: 'RETRIEVE_ELEMENTS_FAILED',
      errorMsg: e.message
    });
  }
}

export const readableErrorMessage = errorMsg => {
  if (errorMsg === '"title" must be a string') return "Bitte gib einen Titel für das Element an.";
  return errorMsg;
}

export function* beginAddElement(action) {
  try {
    const result = yield call(addElementRequest, action.metadata, action.elemData);

    if (action.metadata.setLoadingState)
      action.metadata.setLoadingState({ loading: false, success: result.statusCode === statusCodes.SUCCESS });

    yield genericHandleResult(
      result,
      undefined,
      action.metadata.boardView ? {
        type: "SHOW_NOTIFICATION_REQUESTED",
        notification: {
          type: "error", title: "Error adding element", desc: readableErrorMessage(result.errorMsg)
        }
      } : {
        type: 'ADD_ELEMENT_FAILED',
        error: { message: readableErrorMessage(result.errorMsg) }
      },
      [
        { type: 'CLOSE_MODAL' },
        {
          type: 'TAG_LIST_REQUESTED',
          metadata: action.metadata
        }
      ]
    );
  } catch (e) {
    yield put(addElementFailed({ message: e.message}));
  }
}

// Check if current filter applies to added element
const getFilter = state => state.filter;
const getActiveTimelineQuery = state => state.navigation.activeTimelineQuery;
const getUrlPathname = state => state.navigation.pathname;
export function* beginAddElementDirectly(action) {
  // BOARD-VIEW
  yield put(addBoardElementDirectly(action.element));
  // TIMELINE-VIEW
  // Add element in timeline view
  const filter = yield select(getFilter);
  const activeTimelineQuery = yield select(getActiveTimelineQuery);

  const { queryString, tagsIncluded, tagsExcluded } = filter;
  const { title, tags } = action.element;

  const elementTags = Object.keys(tags.data).map(tag => {
    return tags.data[tag].tagname.toLowerCase();
  });

  if (
    !(activeTimelineQuery === '/untagged' && tags.indexArray.length > 0) &&
    (tagsIncludedApplies(tagsIncluded, elementTags) &&
      tagsExcludedApplies(tagsExcluded, elementTags) &&
      (!queryString || (title && title.toLowerCase().includes(queryString.toLowerCase()))))
  ) {
    // Get scroll position before adding element
    const scrollPositionBottom = yield select(state => state.navigation.scrollPositionBottom);

    // Filter applies
    yield put(addElementSucceeded(action.element));

    if (scrollPositionBottom) {
      setTimeout(() => {
        const scrollView = getReference(ReferenceNames.ELEMENT_SCROLL_VIEW);
        const elementList = getReference(ReferenceNames.ELEMENT_LIST);
        if (scrollView && elementList) scrollView.scrollTop = elementList.scrollHeight;
      }, 10);
    } else {
      yield put(increaseNewIndicatorAmount());
    }
  } else {
    // Filter does not apply
    // yield put(addElementHiddenByFilter(action.element));

    // Reset the fields that would have automatically been reset if active filter matched
    yield put(resetAddElementError());
    yield put(resetUploadedFiles());
  }
}

export function* beginRevertableDeleteElement(action) {
  // TODO: Handle case: reload in boardview and then request delete -> causes error
  const elementData = yield select(state => state.element.elements[action.elementId]);
  yield put(addRevertableAction({
    ...action,
    type: "DELETE_ELEMENT_REQUESTED",
    reverseType: "ADD_ELEMENT_DIRECTLY",
    reverseData: { element: elementData }
  }));
  yield put(deleteElementDirectly(action.elementId));
  // yield delay(3000);
  // yield put({ type: "ADD_ELEMENT_DIRECTLY", element: elementData })
}

export function* beginDeleteElement(action) {
  try {
    const result = yield call(deleteElementRequest, action.metadata, action.elementId);

    yield genericHandleResult(
      result,
      undefined,
      showNotification({
        type: 'error',
        title: 'Could not delete element',
        desc: result.errorMsg
      })
    );
  } catch (e) {
    yield put(
      showNotification({
        type: 'error',
        title: 'Could not delete element',
        desc: e.message
      })
    );
  }
}

export function* beginDeleteMultipleElements(action) {
  try {
    const result = yield call(deleteMultipleElementsRequest, action.metadata, action.elementIds);

    yield put(closeModal());

    yield genericHandleResult(
      result,
      undefined,
      showNotification({
        type: 'error',
        title: 'Could not delete elements',
        desc: result.errorMsg
      })
    );
  } catch (e) {
    yield put(
      showNotification({
        type: 'error',
        title: 'Could not delete elements',
        desc: e.message
      })
    );
  }
}

// Check if element was hidden by filter and then remove from matching list
// const getHiddenByFilter = state => state.element.hiddenByFilter;
export function* beginDeleteElementDirectly(action) {
  // BOARD-VIEW
  yield put(deleteBoardElementSucceeded(action.elementId, null));

  // TIMELINE-VIEW
  // Delete element in listview
  // let hiddenByFilter = yield select(getHiddenByFilter);
  // if (hiddenByFilter[action.elementId]) {
  //   yield put(deleteElementHiddenByFilterSucceeded(action.elementId));
  // } else {
    yield put(deleteElementSucceeded(action.elementId));
  // }
}

export function* beginDeleteMultipleElementsDirectly(action) {
  // BOARD-VIEW
  yield put(deleteMultipleBoardElementsSucceeded(action.elementIds, null));

  // TIMELINE-VIEW
  // Separate hidden elements
  // let hiddenByFilter = yield select(getHiddenByFilter);
  // let hiddenByFilterList = [];
  // let regularList = [];

  // action.elementIds.forEach(id => {
    // hiddenByFilter[id] ? hiddenByFilterList.push(id) : regularList.push(id);
  // });

  // Delete elements in listview
  // if (hiddenByFilterList.length > 0) yield put(deleteMultipleElementsHiddenByFilterSucceeded(hiddenByFilterList));
  // if (regularList.length > 0) yield put(deleteMultipleElementsSucceeded(regularList));
  yield put(deleteMultipleElementsSucceeded(action.elementIds));
}

export function* beginUpdateElement(action) {
  try {
    const result = yield call(updateElementRequest, action.metadata, action.elemData);

    if (action.metadata.resultHandler) action.metadata.resultHandler(result.statusCode === statusCodes.SUCCESS);

    yield genericHandleResult(
      result,
      undefined,
      showNotification({
        type: 'error',
        title: 'Element update failed',
        desc: result.errorMsg
      }),
      [
        {
          type: 'TAG_LIST_REQUESTED',
          metadata: action.metadata
        }
      ]
    );
  } catch (e) {
    yield put(
      showNotification({
        type: 'error',
        title: 'Element update failed',
        desc: e.message
      })
    );
  }
}

export function* beginUpdateElementDirectly(action) {
  // BOARD-VIEW
  // Update element in board view
  yield put(updateBoardElementDirectly(action.element));

  // TIMELINE-VIEW
  // Update element in listview
  const filter = yield select(getFilter);
  const activeTimelineQuery = yield select(getActiveTimelineQuery);
  // const hiddenByFilter = yield select(getHiddenByFilter);

  const { queryString, tagsIncluded, tagsExcluded } = filter;
  const { title, tags } = action.element;

  const elementTags = Object.keys(tags.data).map(tag => {
    return tags.data[tag].tagname.toLowerCase();
  });

  if (
    !(activeTimelineQuery === '/untagged' && tags.indexArray.length > 0) &&
    (tagsIncludedApplies(tagsIncluded, elementTags) &&
      tagsExcludedApplies(tagsExcluded, elementTags) &&
      (!queryString || (title && title.toLowerCase().includes(queryString.toLowerCase()))))
  ) {
    // Filter applies
    yield put(updateElementSucceeded(action.element, false));

    // If element was hidden by filter before, remove it
    // if (hiddenByFilter[action.element.id]) {
    //   yield put(deleteElementHiddenByFilterSucceeded(action.element.id));
    // }
  } else {
    // Filter does not apply
    // yield put(updateElementHiddenByFilterSucceeded(action.element));

    // If element was displayed normally, remove it
    // if (!hiddenByFilter[action.element.id]) {
      // yield put(deleteElementSucceeded(action.element.id));
    // }

    yield put(updateElementSucceeded(action.element, true));
  }
}

export function* beginGetTagList(action) {
  try {
    const result = yield call(getTagListRequest, action.metadata);

    yield genericHandleResult(
      result,
      {
        type: 'GET_TAG_LIST_SUCCEEDED',
        tagList: result.result
      },
      {
        type: 'GET_TAG_LIST_FAILED',
        errorMsg: result.errorMsg
      }
    );
  } catch (e) {
    yield put({
      type: 'GET_TAG_LIST_FAILED',
      errorMsg: e.message
    });
  }
}

export function* beginSocketInsertLinkDirectly(action) {
  let pathname = yield select(getUrlPathname);
  if (timelinePaths.indexOf(pathname.split('/')[1]) === -1) {
    // Insert in board view
    yield put(socketInsertBoardLinkSucceeded(action.link));
  } else {
    // Insert in timeline view
    yield put(socketInsertLinkSucceeded(action.link));
  }
}

export function* beginUploadFile(action) {
  try {
    const signedResponse = yield call(getPresignedURL, action.metadata, action.file);

    if (signedResponse.statusCode === statusCodes.SUCCESS) {
      const result = yield call(submitUploadFileRequest, signedResponse.signedUrlResponse, action.file);
      if (result.status === 204) { // Success
        const elementId = action.file.assignedToElement;
        if (elementId) { // File was directly assigned to an element
          const { title, tags, links, files } = yield select(state => state.element.elements[elementId]);

          const oldTags = Object.keys(tags.data).map(t => tags.data[t].tagname);
          const oldLinks = Object.keys(links.data).map(l => links.data[l].url);
          const oldFiles = Object.keys(files.data).map(f => files.data[f].id);
          const finalData = {
            elementId,
            title,
            tags: oldTags,
            links: oldLinks,
            files: [...oldFiles, signedResponse.createdFileId]
          };
      
          yield put(updateElementRequested(action.metadata, finalData));
        } else {
          yield put(uploadFinished(action.metadata.id, signedResponse.createdFileId));
        }
      } else {
        yield put(addElementFailed({ message: result.body}));
      }
    } else {
      let pathname = yield select(getUrlPathname);
      const inBoardView = timelinePaths.indexOf(pathname.split('/')[1]) === -1;

      if (inBoardView || action.file.assignedToElement) {
        yield put(showNotification({ type: "error", title: "Error during file-upload", desc: signedResponse.errorMsg }));
      } else {
        // Unassigned element in timeline view
        yield put(addElementFailed({ message: signedResponse.errorMsg}));
      }
      yield put(removeUploadedFile(action.metadata.id));
    }
  } catch (e) {
    yield put(addElementFailed({ message: e.message}));
  }
}