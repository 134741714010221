import { convertDataFormat } from "../utils/conversions";

const initialState = {
  elements: null, // Needs to be null in order to be able to tell the difference between initial state and no elements returned
  allElements: [],
  selectedElements: [],
  // hiddenByFilter: {},
  lastId: null,
  bottomReached: false,
  editingElement: false,
  tagList: [],
  retrieveError: null,
  addError: null,
  tagListError: null,
  didAppend: false,
  showLoadingAnim: false,
  uploadedFiles: {},
  revertableActions: {},
  newIndicatorAmount: 0
};

export const element = (state = initialState, action) => {
  switch (action.type) {
    case "REFRESH_ELEMENT_REMINDER": {
      return {
        ...state,
        elements: {
          ...state.elements,
          [action.elemId]: {
            ...state.elements[action.elemId],
            reminders: {
              ...state.elements[action.elemId].reminders,
              data: {
                ...state.elements[action.elemId].reminders.data,
                [action.reminderId]: {
                  ...state.elements[action.elemId].reminders.data[action.reminderId],
                  gotReminder: true
                }
              }
            }
          }
        }
      }
    }
    case "INCREASE_NEW_INDICATOR_AMOUNT": {
      return {
        ...state,
        newIndicatorAmount: state.newIndicatorAmount + 1
      }
    }
    case "RESET_NEW_INDICATOR_AMOUNT": {
      return {
        ...state,
        newIndicatorAmount: 0
      }
    }
    case "REMOVE_REVERTABLE_ACTION": {
      const { [action.id]: _, ...rest } = state.revertableActions;
      return {
        ...state,
        revertableActions: rest
      }
    }
    case "ADD_REVERTABLE_ACTION": {
      return {
        ...state,
        revertableActions: {
          ...state.revertableActions,
          [action.payload.elementId]: {
            ...action.payload
          }
        }
      }
    }
    case "UNSELECT_ALL_ELEMENTS": {
      return {
        ...state,
        selectedElements: []
      }
    }
    case "UNSELECT_ELEMENT": {
      let elemIndex = state.selectedElements.indexOf(action.elementId);
      let newSelectedElements = state.selectedElements.slice(); // Slice to get rid of reference
      if (elemIndex > -1) {
        newSelectedElements.splice(elemIndex, 1);
      }
      return {
        ...state,
        selectedElements: newSelectedElements
      }
    }
    case "SELECT_ELEMENT": {
      return {
        ...state,
        selectedElements: [
          ...state.selectedElements,
          action.elementId
        ]
      }
    }
    case "REMOVE_UPLOADED_FILE": {
      let { [action.fileId]: _, ...rest } = state.uploadedFiles;
      return {
        ...state,
        uploadedFiles: rest
      }
    }
    case "RESET_UPLOADED_FILES": {
      return {
        ...state,
        uploadedFiles: {}
      }
    }
    case "UPLOAD_FINISHED": {
      return {
        ...state,
        uploadedFiles: {
          ...state.uploadedFiles,
          [action.fileId]: {
            ...state.uploadedFiles[action.fileId],
            uploadFinished: true,
            remoteFileId: action.remoteFileId 
          }
        }
      }
    }
    case "ADD_FILES_FOR_UPLOAD": {
      return {
        ...state,
        uploadedFiles: {
          ...state.uploadedFiles,
          ...action.files
        }
      }
    }
    case "SHOW_LOADING_ANIM": {
      return {
        ...state,
        showLoadingAnim: true
      }
    }
    case "UPDATE_ELEMENT_SUCCEEDED": {
      if (!state.elements) return state;

      // The existing link-data needs to be kept - but only if the link hasn't
      // been deleted. Newly added links need to be added
      let newLinkData = action.element.links.data;
      let newIndexArray = [];
      // let hiddenByFilter = action.hiddenByFilter;

      Object.keys(newLinkData).forEach(l => {
        let id = newLinkData[l].id;
        newIndexArray.push(id);
        // Get current data from either hidden or shown elements (on switch or persistent)
        // let currentData = state.elements[action.element.id] ? state.elements[action.element.id].links.data[id] : state.hiddenByFilter[action.element.id].links.data[id];
        let currentData = state.elements[action.element.id].links.data[id];
        if (currentData) newLinkData[l] = currentData;
      });

      // if (hiddenByFilter) {
      //   return {
      //     ...state,
      //     editingElement: false,
      //     hiddenByFilter: {
      //       ...state.hiddenByFilter,
      //       [action.element.id]: {
      //         ...action.element,
      //         links: {
      //           data: newLinkData,
      //           indexArray: newIndexArray
      //         }
      //       }
      //     },
      //     uploadedFiles: {}
      //   }
      // } else {
      let newState = {
          ...state,
          editingElement: false,
          elements: {
            ...state.elements,
            [action.element.id]: {
              ...action.element,
              links: {
                data: newLinkData,
                indexArray: newIndexArray
              }
            }
          },
          uploadedFiles: {}
        }
      // }
      if (action.filterNotMatched) newState.elements[action.element.id].filterNotMatched = true;
      return newState;
    }
    // case "RESET_HIDDEN_BY_FILTER": {
    //   return {
    //     ...state,
    //     hiddenByFilter: {}
    //   }
    // }
    case "RESET_LAST_ID": {
      return {
        ...state,
        lastId: null
      }
    }
    case "DELETE_MULTIPLE_ELEMENTS_SUCCEEDED": {
      let elems = Object.assign({}, state.elements);
      action.elementIds.forEach(id => {
        delete elems[id];
      });
      return {
        ...state,
        elements: elems,
        selectedElements: []
      }
    }
    // case "DELETE_MULTIPLE_ELEMENTS_HIDDEN_BY_FILTER_SUCCEEDED": {
    //   let elems = Object.assign({}, state.hiddenByFilter);
    //   action.elementIds.forEach(id => {
    //     delete elems[id];
    //   });
    //   return {
    //     ...state,
    //     hiddenByFilter: elems,
    //     selectedElements: []
    //   }
    // }
    case "DELETE_ELEMENT_SUCCEEDED": {
      if (!state.elements) return state; // When timeline-view has not been loaded but element is deleted in board-view

      const { [action.elementId]: _, ...rest } = state.elements;
      return {
        ...state,
        elements: rest
      }
    }
    // case "DELETE_ELEMENT_HIDDEN_BY_FILTER_SUCCEEDED": {
    //   if (!state.hiddenByFilter) return state;

    //   const { [action.elementId]: _, ...rest } = state.hiddenByFilter;
    //   return {
    //     ...state,
    //     hiddenByFilter: rest
    //   }
    // }
    case "RESET_ADD_ELEMENT_ERROR": {
      return {
        ...state,
        addError: null
      }
    }
    case "SOCKET_LINK_DATA_FAILED": {
      return {
        ...state,
        elements: {
          ...state.elements,
          [action.link.elementId]: {
            ...state.elements[action.link.elementId],
            links: {
              ...state.elements[action.link.elementId].links,
              data: {
                ...state.elements[action.link.elementId].links.data,
                [action.link.linkId]: {
                  ...state.elements[action.link.elementId].links.data[action.link.linkId],
                  loading: false
                }
              }
            }
          }
        }
      }
    }
    case "SOCKET_INSERT_LINK_SUCCEEDED": {
      // Element is visible with current filter active
      if (state.elements[action.link.elementId]) {
        return {
          ...state,
          elements: {
            ...state.elements,
            [action.link.elementId]: {
              ...state.elements[action.link.elementId],
              links: {
                data: {
                  ...state.elements[action.link.elementId].links.data,
                  [action.link.linkId]: {
                    id: action.link.linkId,
                    ...action.link
                  }
                },
                indexArray: state.elements[action.link.elementId].links.indexArray
              }
            }
          }
        }
      } else {
        return state;
        // return {
        //   ...state,
        //   hiddenByFilter: {
        //     ...state.hiddenByFilter,
        //     [action.link.elementId]: {
        //       ...state.hiddenByFilter[action.link.elementId],
        //       links: {
        //         data: {
        //           ...state.hiddenByFilter[action.link.elementId].links.data,
        //           [action.link.linkId]: {
        //             id: action.link.linkId,
        //             ...action.link
        //           }
        //         },
        //         indexArray: state.hiddenByFilter[action.link.elementId].links.indexArray
        //       }
        //     }
        //   }
        // }
      }
    }
    case "SET_EDIT_ELEMENT_STATUS": {
      return {
        ...state,
        editingElement: action.editing
      }
    }
    case "RETRIEVE_ELEMENTS_SUCCEEDED": {
      let actualData = action.elementData, bottomReached, lastId;

      if (action.elementData.length > 15) {
        bottomReached = false;
        lastId = actualData[actualData.length - 1].id;
      } else {
        bottomReached = true;
      }

      let convertedElemData = convertDataFormat(actualData);
      return {
        ...state,
        retrieveError: null,
        elements: action.append ?  {
          ...state.elements,
          ...convertedElemData.data
        } : convertedElemData.data,
        allElements: convertedElemData.indexArray,
        bottomReached: bottomReached,
        lastId: lastId ? lastId : null,
        didAppend: action.append,
        showLoadingAnim: false
      };
    }
    case "RETRIEVE_ELEMENTS_FAILED": {
      return {
        ...state,
        retrieveError: action.errorMsg
      };
    }
    case "ADD_ELEMENT_SUCCEEDED": {
      return {
        ...state,
        addError: null,
        elements: {
          ...state.elements,
          [action.element.id]: {
            ...action.element
          }
        },
        allElements: [...state.allElements, action.element.id],
        uploadedFiles: {}
      };
    }
    // case "ADD_ELEMENT_HIDDEN_BY_FILTER": {
    //   return {
    //     ...state,
    //     addError: null,
    //     hiddenByFilter: {
    //       ...state.hiddenByFilter,
    //       [action.element.id]: {
    //         ...action.element
    //       }
    //     }
    //   };
    // }
    case "ADD_ELEMENT_FAILED": {
      return {
        ...state,
        addError: action.error
      };
    }
    case "GET_TAG_LIST_SUCCEEDED": {
      return {
        ...state,
        tagList: action.tagList,
        tagListError: false
      }
    }
    case "GET_TAG_LIST_FAILED": {
      return {
        ...state,
        tagListError: action.errorMsg
      }
    }
    default:
      return state;
  }
};