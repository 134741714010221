import React, { Component } from 'react';
import './Notifications.scss';
import { connect } from 'react-redux';
import { removeNotification } from './../../actions/notification';
import { MaterialIcons } from 'react-web-vector-icons';

class Notification extends Component {
  constructor(props) {
    super(props);

    this.dismissed = false;
    this.state = { fadingOut: false };
  }

  componentDidMount() {
    setTimeout(() => {
      if (!this.dismissed) this._dismissNotification();
    }, 10000);
  }

  _dismissNotification() {
    this.dismissed = true;
    this.setState({ ...this.state, fadingOut: true }, () => {
      setTimeout(() => {
        this.props.removeNotification(this.props.id);
      }, 250);
    });
  }

  render() {
    const { type, title, desc } = this.props;
    const cn = this.state.fadingOut ? 'notification fadeOut ' + type : 'notification fadeIn ' + type;
    return (
      <div className={cn} style={{ bottom: 32 + this.props.index * 88 }}>
        <div className="close" onClick={() => this._dismissNotification()}>
          <MaterialIcons name="close" size={18} />
        </div>
        <div className="icon" />
        <div className="content">
          <h3>{title}</h3>
          <p>{desc}</p>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  removeNotification: id => dispatch(removeNotification(id))
});

export default connect(null, mapDispatchToProps)(Notification);
